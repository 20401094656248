<template>
  <div class="component-balance-account-report-twr-table">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2 v-if="isLoaded">{{ $t(translationPath + 'twr_heading', {time_weighted_return: (parseFloat(apiData.twr.time_weighted_return)*100).toFixed(2) + ' %', time_weighted_return_cagr: ( apiData.twr.time_weighted_return_cagr !== false ? (parseFloat(apiData.twr.time_weighted_return_cagr)*100).toFixed(2) + ' %' : '-- %' ) })}} </h2>
                </b-col>
                <b-col cols="4" class="text-right">

                </b-col>
              </b-row>
            </template>

            <div class="col-md-9 pl-0" v-html="$t(translationPath + 'twr_description')"></div>

            <b-table :class="'spirecta-simple-table performance-report-transaction-table mt-3 table_twr'" show-empty hover responsive striped no-footer-sorting
                     ref="twrTable"
                     stacked="lg"
                     :items="tableData"
                     :fields="tableColumns"
                     :busy="!isLoaded"
            >
              <!--loader-->
              <template v-slot:table-busy><loader/></template>
              <!--loader ends here-->
              <template v-slot:cell(value)="row">
                <span style="text-wrap: none;">{{ row.item.value }}</span>
              </template>
            </b-table>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'

export default {
  name: 'BalanceAccountReportTwrTable',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          id: '',
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports._common.distribution.',
      tableData: [],
      tableColumns: [],
      toggleColumnsOptions: {
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []
      if (!this.apiData) {
        return rows
      }

      if (this.apiData) {
        return [
          { title: this.$t(this.translationPath + 'twr_table.time_weighed_return'), value: (this.apiData.twr.time_weighted_return * 100).toFixed(2) + ' %' },
          { title: this.$t(this.translationPath + 'twr_table.time_weighed_return_cagr'), value: (this.apiData.twr.time_weighted_return_cagr * 100).toFixed(2) + ' %' },
          { title: this.$t(this.translationPath + 'twr_table.start_period'), value: this.apiData.twr.period.period_start },
          { title: this.$t(this.translationPath + 'twr_table.end_period'), value: this.apiData.twr.period.period_end },
          { title: this.$t(this.translationPath + 'twr_table.breakdown_period'), value: this.calcPeriodBreakdown(this.apiData.twr.period.full_period_breakdown) }
        ]
      }

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'title', label: this.$t(this.translationPath + 'twr_table.title') },
        { key: 'value', label: this.$t(this.translationPath + 'twr_table.value'), class: 'text-right' }
      ]

      return cols
    },
    calcPeriodBreakdown (period) {
      let sReturn = ''
      if (period.years !== '0') {
        sReturn = sReturn + period.years + ' ' + this.$t(this.translationPath + 'twr_table.years') + ', '
      }
      if (period.months !== '0' || (period.months === '0' && period.years !== '0')) {
        sReturn = sReturn + period.months + ' ' + this.$t(this.translationPath + 'twr_table.months') + ', '
      }
      if (period.days !== '0') {
        sReturn = sReturn + period.days + ' ' + this.$t(this.translationPath + 'twr_table.days')
      }

      return sReturn
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-balance-account-report-distribution-table{
  tfoot {
    th {
      border-top: 1px solid black;
      border-bottom: 2px solid black;
      padding: 6px 10px;
    }
  }
}
</style>
