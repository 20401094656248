<template>
  <div class="component-cashflow-quadrant-toggle-columns">
      <b-dropdown right
                  :text="$t('common.toggle_columns')"
                  variant='outline-secondary'
                  class="float-right text-regular mr-3 toggleColumnWrapper"
                  size="sm"
      >
        <b-dropdown-item @click="toggleColumns('amounts')" v-bind:class="{ active: options.amounts }">{{$t(translationPath + 'amounts')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('budget')" v-bind:class="{ active: options.budget }">{{$t(translationPath + 'budget')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('active_amounts')" v-bind:class="{ active: options.active_amounts }">{{$t(translationPath + 'active_amounts')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('passive_amounts')" v-bind:class="{ active: options.passive_amounts }">{{$t(translationPath + 'passive_amounts')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('budget_diff')" v-bind:class="{ active: options.budget_diff }">{{$t(translationPath + 'budget_diff')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('budget_diff_percentage')" v-bind:class="{ active: options.budget_diff_percentage }">{{$t(translationPath + 'budget_diff_percentage')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('amounts_acc')" v-bind:class="{ active: options.amounts_acc }">{{$t(translationPath + 'amounts_acc')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('budget_acc')" v-bind:class="{ active: options.budget_acc }">{{$t(translationPath + 'budget_acc')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('budget_diff_acc')" v-bind:class="{ active: options.budget_diff_acc }">{{$t(translationPath + 'budget_diff_acc')}}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('budget_diff_percentage_acc')" v-bind:class="{ active: options.budget_diff_percentage_acc }">{{$t(translationPath + 'budget_diff_percentage_acc')}}</b-dropdown-item>
      </b-dropdown>

  </div>
</template>

<script>
export default {
  name: 'PerformanceAccountReportV2ToggleColumns',
  props: {
    options: {
      type: Object,
      default: function () {
        return {
          amounts: true,
          budget: true,
          active_amounts: false,
          passive_amounts: false,
          budget_diff: true,
          budget_diff_percentage: true,
          amounts_acc: true,
          budget_acc: false,
          budget_diff_acc: false,
          budget_diff_percentage_acc: false
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.account.table.'
    }
  },
  methods: {
    toggleColumns (columnToDisplay) {
      this.$emit('column-to-toggle', columnToDisplay)
    }
  }
}
</script>

<style lang="scss">
.component-cashflow-quadrant-toggle-columns {
  li a { color: black; }
  li.active a:after {
    content: " ✓";
  }
  li.active a { font-weight: 900; }
}
</style>
