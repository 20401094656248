export default {
  methods: {
    getTimeLabelFromPeriodLabel (period, resolution) {
      let label = period // Default by year resolution. Also for other unexpected resolutions.
      if (resolution === 'month') {
        const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
        const ym = period.split('-')
        const month = Number(ym[1]) - 1
        label = this.$t('common.months.' + months[month])
        if (!month) { // add year if january
          label += ' ' + ym[0]
        }
      }
      return label
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
