<template>
  <b-dropdown right
    :text="$t(translationPath + 'toggle_budgets_dropdown') + ' | ' + budgetTitle"
    variant='outline-secondary'
    class="float-right text-regular mr-3 toggleColumnWrapper"
    size="sm"
  >
    <b-dropdown-item v-for="budget in budgets" :key="budget.id" :class="{ active: budgetId === budget.id }" @click="change(budget.id, budget.title)">{{ budget.title }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ToggleBudgetsDropdown',
  props: {
    budgets: {
      type: Array,
      default: () => { return [] }
    },
    initialBudgetId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.period-report.',
      budgetId: null,
      budgetTitle: ''
    }
  },
  methods: {
    change (budgetId, budgetTitle = '') {
      if (budgetId === this.busgetId) {
        return false
      }
      this.budgetId = budgetId
      this.budgetTitle = budgetTitle
      this.$emit('change', this.budgetId)
    },
    defineBudgetTitle () {
      for (const i in this.budgets) {
        if (this.budgets[i].id === this.budgetId) {
          this.budgetTitle = this.budgets[i].title
          break
        }
      }
    },
    $selectItem (budgetId) {
      if (budgetId !== this.busgetId) {
        this.budgetId = budgetId
        this.defineBudgetTitle()
      }
    }
  },
  created () {
    this.budgetId = this.initialBudgetId
    this.defineBudgetTitle()
  },
  watch: {
    initialBudgetId (newVal) {
      this.budgetId = newVal
      this.defineBudgetTitle()
    },
    budgets: {
      deep: true,
      handler () {
        this.defineBudgetTitle()
      }
    }
  }
}
</script>
