<template>
  <div class="component-performance-account-report-stats-view">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8" v-if="startDate !== null">
                  <h2>{{ $t(translationPath + 'title',{start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
              </b-row>

              <b-card no-body class="white-tabs-card px-0 mx-0 mt-4">
                <b-tabs class="white-tabs filter-tabs-wrapper" v-model="tabIndex">

                  <!-- Common transactions -->
                  <b-tab :title="$t(translationPath + 'tabs.common_transactions')" class="px-0">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'common_transactions', {period_from: startDate, period_end: endDate})"></div>

                    <performance-account-report-v2-stats-table-common-transactions
                      :isLoaded="isLoaded"
                      :api-data="apiData"
                      :start-date="startDate"
                      :end-date="endDate"
                      :account="account"
                      :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
                    />
                  </b-tab>

                  <!-- Offset accounts -->
                  <b-tab :title="$t(translationPath + 'tabs.offset_accounts')" class="px-0">
                    <div class="col-lg-8 pl-0 pt-3" v-html="$t(translationPath + 'description_offset_accounts', {period_from: startDate, period_end: endDate})"></div>

                    <performance-account-report-v2-stats-table-offset-accounts
                      :isLoaded="isLoaded"
                      :api-data="apiData"
                      :start-date="startDate"
                      :end-date="endDate"
                      :account="account"
                      :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
                    />
                  </b-tab>

                </b-tabs>
              </b-card>

            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>

import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import PerformanceAccountReportV2StatsTableCommonTransactions from './../tables/PerformanceAccountReportV2StatsTableCommonTransactions'
import PerformanceAccountReportV2StatsTableOffsetAccounts from './../tables/PerformanceAccountReportV2StatsTableOffsetAccounts'

export default {
  name: 'PerformanceAccountReportV2StatsView',
  mixins: [],
  components: {
    PerformanceAccountReportV2StatsTableCommonTransactions,
    PerformanceAccountReportV2StatsTableOffsetAccounts
  },
  filters: {
    formatAmount
  },
  props: {
    activeTab: { type: String, default: '' },
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.account.stats.',
      tabIndex: 0
    }
  },
  created () {

  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    onRouteChange (tab) {
      const { params, query } = this.$route
      switch (tab) {
        case 'common_transactions':
          this.tabIndex = 0
          break
        case 'offset_accounts':
          this.tabIndex = 1
          break
        default:
          this.$router.push({ params: Object.assign({}, params, { subtab: 'common_transactions' }), query })
      }
    }
  },
  watch: {
    tabIndex (newVal) {
      const { params, query } = this.$route
      switch (newVal) {
        case 0:
          this.$emit('chart-to-display', { from_tab: 'summary', chart: 'common_transactions' })
          if (this.$route.params.subtab !== 'common_transactions') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'common_transactions' }), query })
          }
          break
        case 1:
          this.$emit('chart-to-display', { from_tab: 'summary', chart: 'offset_accounts' })
          if (this.$route.params.subtab !== 'offset_accounts') {
            this.$router.push({ params: Object.assign({}, params, { subtab: 'offset_accounts' }), query })
          }
          break
      }
    },
    '$route.params.subtab': {
      handler: function (newVal) {
        this.onRouteChange(newVal)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-account-report-stats-view{
  .filter-tabs-wrapper ul li a{
    color:#d38786;
  }
}
</style>
