<template>
  <b-container fluid class="component-balance-account-report-closing-balance-vs-lifeplan-chart pb-5">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-balance-account-report-closing-balance-vs-lifeplan-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'BalanceAccountReportClosingBalanceVsLifeplanChart',
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  mixins: [chartOptions, SpirectaReportChartMixin],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.performance.account.chart.',
      chartData: {},
      currencySuffix: ''
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      immediate: true,
      deep: true
    }
  },
  methods: {
    fillChart () {
      if (!this.apiData || !Object.keys(this.apiData).length) {
        return false
      }

      if (Object.keys(this.apiData).length > 0) {
        this.chartData = {}
      }

      const lblBudget = this.$t(
        'reports.budget.period-report.period.table.budget_amount'
      )
      const lblActualAmount = this.$t(
        'reports.budget.period-report.period.table.actual_amount'
      )
      const suffix = ' ' + this.currencySuffix

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        switch (tooltipItem.datasetIndex) {
          case 0:
            return (lblActualAmount + ': ' + formatNumberToFull(tooltipItem.value) + suffix)
          case 1:
            return (
              lblBudget + ': ' + formatNumberToFull(tooltipItem.value) + suffix
            )
        }
      }

      this.chartOptions.scales.xAxes = [
        {
          stacked: true,
          display: true,
          id: 'bar-x-axis1'
        },
        {
          stacked: true,
          display: false,
          id: 'bar-x-axis2',
          type: 'category',
          offset: true
        }
      ]

      const labels = []
      const actualAmounts = []
      const budgetAmounts = []
      const apiPeriods = this.apiData.periods
      const actualAmountsBackgroundColor = []

      for (const period in this.apiData.meta.periods) {
        const sPeriod = this.apiData.meta.periods[period]
        labels.push(this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution))
        actualAmounts.push(parseFloat(apiPeriods.amounts.closing_balances[sPeriod]).toFixed(0))
        actualAmountsBackgroundColor.push(this.getGreen())
        budgetAmounts.push(parseFloat(apiPeriods.budget.closing_balances[sPeriod]).toFixed(0))
      }

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: lblActualAmount,
            data: actualAmounts,
            type: 'bar',
            backgroundColor: actualAmountsBackgroundColor,
            borderWidth: 1,
            barPercentage: 0.8,
            xAxisID: 'bar-x-axis2'
          },
          {
            label: lblBudget,
            data: budgetAmounts,
            type: 'bar',
            backgroundColor: this.getBudgetColor(0.5),
            borderWidth: 1,
            barPercentage: 1.0,
            xAxisID: 'bar-x-axis1'
          }
        ]
      }
    }
  }
}
</script>
