<template>
  <div class="component-performance-account-report-period-table">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col  cols="12" md="4" lg="5">
                  <h2>{{ $t(translationPath + 'title', {account: account.title, start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="12" md="8" lg="7" class="text-right">
                  <performance-account-report-v2-toggle-columns
                    :options="toggleColumnsOptions"
                    @column-to-toggle="onColumnToggle"
                  />

                  <!-- Toggle result charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 spirecta-toggle-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('period-sum-bar-chart')" v-bind:class="{ active: showChart === 'period-sum-bar-chart' }">{{$t( translationPath + 'charts.period-sum-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-sum-area-chart-acc')" v-bind:class="{ active: (showChart === 'period-sum-area-chart-acc') }">{{$t(translationPath + 'charts.period-sum-area-chart-acc')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-budget-bar-chart')" v-bind:class="{ active: (showChart === 'period-budget-bar-chart') }">{{$t(translationPath + 'charts.period-budget-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-budget-diff-bar-chart')" v-bind:class="{ active: (showChart === 'period-budget-diff-bar-chart') }">{{$t(translationPath + 'charts.period-budget-diff-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-budget-line-chart')" v-bind:class="{ active: (showChart === 'period-budget-line-chart') }">{{$t(translationPath + 'charts.period-budget-line-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-active-passive-bar-chart')" v-bind:class="{ active: (showChart === 'period-active-passive-bar-chart') }">{{$t(translationPath + 'charts.period-active-passive-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-active-passive-percentage-area-chart')" v-bind:class="{ active: (showChart === 'period-active-passive-percentage-area-chart') }">{{$t(translationPath + 'charts.period-active-passive-percentage-area-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-offset-accounts')" v-bind:class="{ active: showChart === 'period-offset-accounts' }">{{$t( translationPath + 'charts.period-offset-accounts')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>

            <div class="col-md-9 pl-0" v-html="$t(translationPath + 'description_period_table_total', {title: account.label})"></div>

            <b-table
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              foot-clone
              no-footer-sorting
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- period slot-->
              <template v-slot:cell(period)="row">
                <template v-if="resolution==='month' && row.item.row_type==='data'">
                  <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+row.item.period_start_date+'&end_date='+row.item.period_end_date">
                    {{ $t('common.months.month' + row.item.period_month + '_long')}}<span v-if="row.item.period_month === '1'">, {{row.item.period_year}}</span>
                  </b-link>
                </template>
                <template v-else-if="row.item.row_type==='total'">
                  {{$t('common.total')}}
                </template>
                <template v-else>
                  <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+row.item.period_start_date+'&end_date='+row.item.period_end_date">{{row.value}}</b-link>
                </template>
              </template>

              <!-- amounts slot-->
              <template v-slot:cell(amounts)="row">
                <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+row.item.period_start_date+'&end_date='+row.item.period_end_date">{{row.value}}</b-link>
              </template>

              <!-- budget slot-->
              <template v-slot:cell(budget)="row">
                <b-link :to="'/budget/account/'+account.id+'/'+row.item.period_year" >{{row.value}}</b-link>
              </template>

              <!-- budget_diff slot-->
              <template v-slot:cell(budget_diff)="row">
                  <span :class="row.item.budget_diff_positive ? 'text-green budget-green' : 'text-red budget-red'">{{row.value}}</span>
              </template>

              <!-- budget_diff_acc slot-->
              <template v-slot:cell(budget_diff_acc)="row">
                  <span :class="row.item.budget_diff_positive_acc ? 'text-green budget-green' : 'text-red budget-red'">{{row.value}}</span>
              </template>

              <!-- budget_diff_percentage slot-->
              <template v-slot:cell(budget_diff_percentage)="row">
                  <span :class="row.item.budget_diff_positive ? 'text-green budget-green' : 'text-red budget-red'">{{row.value}}</span>
              </template>

              <!-- budget_diff_percentage_acc slot-->
              <template v-slot:cell(budget_diff_percentage_acc)="row">
                  <span :class="row.item.budget_diff_positive_acc ? 'text-green budget-green' : 'text-red budget-red'">{{row.value}}</span>
              </template>

              <!-- END CUSTOM SLOTS -->
              <template v-slot:foot(period)>{{ $t('common.sum') }}</template>
              <template v-slot:foot(amounts) v-if="isLoaded">{{ amountFormatter(apiData.totals.amounts.sum) }}</template>
              <template v-slot:foot(budget) v-if="isLoaded">{{ amountFormatter(apiData.totals.budget.sum) }}</template>
              <template v-slot:foot(active_amounts) v-if="isLoaded">{{ amountFormatter(apiData.totals.active_amounts.sum) }}</template>
              <template v-slot:foot(passive_amounts) v-if="isLoaded">{{ amountFormatter(apiData.totals.passive_amounts.sum) }}</template>
              <template v-slot:foot(budget_diff) v-if="isLoaded">
                <span :class="apiData.totals.budget_diff_positive ? 'text-green budget-green' : 'text-red budget-red'">{{ amountFormatter(apiData.totals.budget_diff) }}</span>
              </template>
              <template v-slot:foot(budget_diff_percentage) v-if="isLoaded">
                <span :class="apiData.totals.budget_diff_positive ? 'text-green budget-green' : 'text-red budget-red'">{{ percentageFormatter(apiData.totals.budget_diff_percentage) }}</span>
              </template>
              <template v-slot:foot(amounts_acc) v-if="isLoaded">{{ amountFormatter(apiData.totals.amounts.sum) }}</template>
              <template v-slot:foot(budget_acc) v-if="isLoaded">{{ amountFormatter(apiData.totals.budget.sum) }}</template>
              <template v-slot:foot(budget_diff_acc) v-if="isLoaded">
                <span :class="apiData.totals.budget_diff_positive ? 'text-green budget-green' : 'text-red budget-red'">{{ amountFormatter(apiData.totals.budget_diff) }}</span>
              </template>
              <template v-slot:foot(budget_diff_percentage_acc) v-if="isLoaded">
                <span :class="apiData.totals.budget_diff_positive ? 'text-green budget-green' : 'text-red budget-red'">{{ percentageFormatter(apiData.totals.budget_diff_percentage) }}</span>
              </template>
            </b-table>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import PerformanceAccountReportV2ToggleColumns from './../inc/PerformanceAccountReportV2ToggleColumns'

export default {
  name: 'PerformanceAccountReportV2PeriodTable',
  components: { Loader, PerformanceAccountReportV2ToggleColumns },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          id: '',
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {
          totals: {
            amounts: { sum: 0 },
            budget: { sum: 0 },
            passive_amounts: { sum: 0 },
            budget_diff: 0,
            budget_diff_percentage: 0
          }
        }
      }
    }
  },
  data () {
    return {
      showChart: 'period-sum-bar-chart',
      translationPath: 'reports.performance.account.',
      tableData: [],
      tableColumns: [],
      toggleColumnsOptions: {
        amounts: true,
        budget: true,
        active_amounts: false,
        passive_amounts: false,
        budget_diff: true,
        budget_diff_percentage: true,
        amounts_acc: true,
        budget_acc: false,
        budget_diff_acc: false,
        budget_diff_percentage_acc: false
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []
      if (!this.apiData) {
        return rows
      }

      if (this.apiData) {
        const aData = JSON.parse(JSON.stringify(this.apiData.meta.periods))
        aData.reverse()

        aData.map(period => {
          const oRowTmp = {
            period: period,
            amounts: this.apiData.periods.amounts[period],
            amounts_acc: this.apiData.periods.amounts_acc[period],
            budget: this.apiData.periods.budget[period],
            budget_acc: this.apiData.periods.budget_acc[period],
            budget_diff: this.apiData.periods.budget_diff[period],
            budget_diff_acc: this.apiData.periods.budget_diff_acc[period],
            budget_diff_percentage: this.apiData.periods.budget_diff_percentage[period],
            budget_diff_percentage_acc: this.apiData.periods.budget_diff_percentage_acc[period],
            budget_diff_positive: this.apiData.periods.budget_diff_positive[period],
            budget_diff_positive_acc: this.apiData.periods.budget_diff_positive_acc[period],
            active_amounts: this.apiData.periods.active_amounts[period],
            passive_amounts: this.apiData.periods.passive_amounts[period],
            period_start_date: this.apiData.meta.periods_meta[period].start_date,
            period_end_date: this.apiData.meta.periods_meta[period].end_date,
            period_year: this.apiData.meta.periods_meta[period].year,
            period_month: this.apiData.meta.periods_meta[period].month,
            row_type: 'data'
          }

          if (this.resolution === 'month') {
            const ym = period.split('-')
            oRowTmp.meta = { year: Number(ym[0]), month: Number(ym[1] - 1) }
          }
          rows.push(oRowTmp)
        })
      }

      return rows
    },
    buildTableColumns () {
      const cols = [{ key: 'period', label: this.$t(this.translationPath + 'table.period'), sortable: true }]

      if (this.toggleColumnsOptions.amounts) {
        cols.push({ key: 'amounts', label: this.$t(this.translationPath + 'table.amounts'), class: 'text-right td-amounts', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.budget) {
        cols.push({ key: 'budget', label: this.$t(this.translationPath + 'table.budget'), class: 'text-right td-budget', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.budget_diff) {
        cols.push({ key: 'budget_diff', label: this.$t(this.translationPath + 'table.budget_diff'), class: 'text-right td-budget_diff', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.budget_diff_percentage) {
        cols.push({ key: 'budget_diff_percentage', label: this.$t(this.translationPath + 'table.budget_diff_percentage'), class: 'text-right td-budget_diff_percentage', formatter: this.percentageFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.active_amounts) {
        cols.push({ key: 'active_amounts', label: this.$t(this.translationPath + 'table.active_amounts'), class: 'text-right td-active_amounts', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.passive_amounts) {
        cols.push({ key: 'passive_amounts', label: this.$t(this.translationPath + 'table.passive_amounts'), class: 'text-right td-passive_amounts', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.amounts_acc) {
        cols.push({ key: 'amounts_acc', label: this.$t(this.translationPath + 'table.amounts_acc'), class: 'text-right td-amounts_acc', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.budget_acc) {
        cols.push({ key: 'budget_acc', label: this.$t(this.translationPath + 'table.budget_acc'), class: 'text-right td-budget_acc', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.budget_diff_acc) {
        cols.push({ key: 'budget_diff_acc', label: this.$t(this.translationPath + 'table.budget_diff_acc'), class: 'text-right td-budget_diff_acc', formatter: this.amountFormatter, sortable: true })
      }

      if (this.toggleColumnsOptions.budget_diff_percentage_acc) {
        cols.push({ key: 'budget_diff_percentage_acc', label: this.$t(this.translationPath + 'table.budget_diff_percentage_acc'), class: 'text-right td-budget_diff_percentage_acc', formatter: this.percentageFormatter, sortable: true })
      }

      return cols
    },
    onColumnToggle (column) {
      this.toggleColumnsOptions[column] = !this.toggleColumnsOptions[column]
      this.tableColumns = this.buildTableColumns()
    },
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : Math.ceil(value)
    },
    percentageFormatter (value) {
      if (value === null) { return '' }
      return parseFloat(value).toFixed(1).toString() + ' %'
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-account-report-period-table{
  .budget-green{
    &::before {content: "";margin-right: 0;}
  }
  .budget-red{
    &::before {content: "";margin-right: 0;}
  }

  .td-amounts_acc{
    border-left:1px solid black;
  }

  tfoot {
    th {
      border-top: 1px solid black;
      border-bottom: 2px solid black;
      padding: 6px 10px;
    }
    .of_which{
      font-weight: normal;
      font-size:90%;
    }
  }
}
</style>
