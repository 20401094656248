<template>
  <b-container fluid class="component-reports-balance-account-charts-stats-half-pie-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chartPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceAccountReportStatsHalfPieChart',
  components: { PieChart },
  mixins: [chartOptions, chartPalettes],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    chartType: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.account.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      const labels = []
      const dataPeriod = []
      const aColors = this.getChartPalette()
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        return chartData.labels[tooltipItem.index] + ': ' + formatNumberToFull(chartData.datasets[0].data[tooltipItem.index]) + ' ' + currencySymbol
      }

      /* Prepare data */
      if (this.apiData) {
        if (this.chartType === 'offset_accounts') {
          for (const sType in this.apiData.stats.offset_accounts) {
            for (const idx in this.apiData.stats.offset_accounts[sType]) {
              labels.push(this.apiData.stats.offset_accounts[sType][idx].title)
              dataPeriod.push(this.apiData.stats.offset_accounts[sType][idx].sum)
            }
          }
        }

        if (this.chartType === 'common_transactions') {
          for (const idx in this.apiData.stats.common_transactions) {
            labels.push(this.apiData.stats.common_transactions[idx].title)
            dataPeriod.push(this.apiData.stats.common_transactions[idx].sum)
          }
        }
      }

      this.chartData = {
        labels: labels,
        datasets: [{
          label: this.$t(this.translationPath + 'period'),
          data: dataPeriod,
          backgroundColor: aColors
        }]
      }
    }
  },
  watch: {
    data () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    },
    isLoaded () {
      this.fillChart()
    },
    chartType () {
      this.fillChart()
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
