import { mapState } from 'vuex'

export default {
  name: 'SpirectaReportMixin',
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    /**
     * Handle emitted data from period_selector
     */
    onPeriodChange (data) {
      this.startDate = data.sDateStart
      this.endDate = data.sDateEnd
      this.resolution = data.resolution

      // Needs to be defined in report and use report filter
      this.loadData()
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) !== -1) {
        this.$router.push({ params: { tab: newtab } })
      } else if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) === -1) {
        this.$router.push({ params: { tab: 'summary' } })
      }
    },
    buildFilterQueryStringForApiEndpoint (reportFilterData) {
      let sQueryParam = ''

      // Filter persons
      if (this.reportFilterData && Object.prototype.hasOwnProperty.call(reportFilterData, 'oFormSelectPerson')) {
        sQueryParam += '&filter_persons=' + reportFilterData.oFormSelectPerson.join(',')
      }

      // Filter income
      if (reportFilterData && Object.prototype.hasOwnProperty.call(reportFilterData, 'oFormSelectIncome')) {
        sQueryParam += '&filter_accounts_income=' + reportFilterData.oFormSelectIncome.join(',')
      }

      // Filter expense
      if (reportFilterData && Object.prototype.hasOwnProperty.call(reportFilterData, 'oFormSelectExpense')) {
        sQueryParam += '&filter_accounts_expense=' + reportFilterData.oFormSelectExpense.join(',')
      }

      // Filter assets
      if (reportFilterData && Object.prototype.hasOwnProperty.call(reportFilterData, 'oFormSelectAsset')) {
        sQueryParam += '&filter_accounts_asset=' + reportFilterData.oFormSelectAsset.join(',')
      }

      // Filter liabilities
      if (reportFilterData && Object.prototype.hasOwnProperty.call(reportFilterData, 'oFormSelectLiability')) {
        sQueryParam += '&filter_accounts_liability=' + reportFilterData.oFormSelectLiability.join(',')
      }

      return sQueryParam
    },
    onFilterApplied (eventData) {
      this.bIsFilterApplied = true
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    onFilterDeleted () {
      this.bIsFilterApplied = false
      this.loadData()
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    clearFilter () {
      this.bIsFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.oFormSelectAsset = []
        this.reportFilterData.oFormSelectLiability = []
        this.reportFilterData.oFormSelectPerson = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    }
  }
}
