<template>
  <b-modal
    id="confirmModal"
    centered
    ref="confirmModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ title }}
    </template>
    <p class="mb-0 font-weight-lighter">{{ message }}</p>
    <template slot="modal-footer" class="text-center">
      <b-button :variant="variant" @click="onOk">{{ $t('common.ok') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'InfoModal',
  props: {
    title: {
      type: String,
      default: 'Notice'
    },
    message: {
      type: String,
      default: 'Something happened.'
    },
    variant: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.confirmModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.confirmModal.hide()
      this.$emit('hide')
    },
    onOk () {
      this.$emit('ok')
      this.hide()
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
