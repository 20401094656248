<template>
  <div class="component-balance-account-report-stats-common-transactions-table">

    <b-container fluid class="px-0 mt-4 settings-tab">

      <b-table
        class="spirecta-simple-table mb-0"
        show-empty
        hover
        responsive
        striped
        stacked="md"
        :items="tableData"
        :fields="tableColumns"
        :busy="!isLoaded"
        :tbodyTrClass="trClass"
      >
        <template v-slot:table-busy>
          <loader/>
        </template>

        <!-- title slot-->
        <template v-slot:cell(title)="row">
          <template v-if="row.item.row_type==='transaction'">
            <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+startDate+'&end_date='+endDate+'&title='+row.item.title">{{row.value}}</b-link>
          </template>
          <template v-else>
            {{row.value}}
          </template>
        </template>

        <!-- title slot-->
        <template v-slot:cell(sum)="row">
          <template v-if="row.item.row_type==='transaction'">
            <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+startDate+'&end_date='+endDate+'&title='+row.item.title">{{row.value}}</b-link>
          </template>
          <template v-else>
            {{row.value}}
          </template>
        </template>

        <!-- END CUSTOM SLOTS -->
      </b-table>

    </b-container>

  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'

export default {
  name: 'BalanceAccountReportStatsTableCommonTransactions',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showChart: 'bar-chart',
      translationPath: 'reports.performance.account.',
      tableData: [],
      tableColumns: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []
      if (!this.apiData) {
        return rows
      }

      if (this.apiData) {
        this.apiData.stats.common_transactions.map(oTransaction => {
          const oRowTmp = {
            title: oTransaction.title,
            count: oTransaction.count,
            average: oTransaction.avg,
            median: oTransaction.median,
            sum: oTransaction.sum,
            row_type: 'transaction'
          }

          rows.push(oRowTmp)
        })

        rows.push({
          title: this.$t('reports.balance.common.period_change'),
          count: this.apiData.meta.transaction_count,
          sum: this.apiData.periods.amounts.totals.period_change,
          average: this.apiData.stats.transaction_stats.avg,
          median: this.apiData.stats.transaction_stats.median,
          row_type: 'total'
        })
      }

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'title', label: this.$t('common.title'), sortable: true },
        { key: 'count', label: this.$t('common.count'), class: 'text-right', sortable: true },
        { key: 'sum', label: this.$t('common.sum'), class: 'text-right', formatter: this.amountFormatter, sortable: true },
        { key: 'average', label: this.$t('common.average'), class: 'text-right', formatter: this.amountFormatter, sortable: true },
        { key: 'median', label: this.$t('common.median'), class: 'text-right', formatter: this.amountFormatter, sortable: true }
      ]

      return cols
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-balance-account-report-stats-common-transactions-table{

}
</style>
