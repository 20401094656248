<template>
  <div class="component-percentage-input">
    <custom-number-input
      v-model="val"
      :precision="precision"
      :min="min"
      :max="max"
      :step="step"
      :slider="slider"
      :slider-position="sliderPosition"
      :slider-md="sliderMd"
      :slider-xl="sliderXl"
      :input-class="inputClass"
      :input-id="inputId"
      :disabled="disabled"
      :tabindex="tabindex"
      :suffix="' %'"
      :state="state"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import CustomNumberInput from '@/components/common/CustomNumberInput'

export default {
  name: 'PercentageInput',
  components: { CustomNumberInput },
  props: {
    value: {
      type: [Number, String]
    },
    precision: {
      type: Number,
      default: 0
    },
    slider: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    inputClass: {
      type: [String, Object],
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    sliderPosition: {
      type: String,
      default: 'left'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    sliderXl: {
      type: Number,
      default: 10
    },
    sliderMd: {
      type: Number,
      default: 9
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: null
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      val: 0
    }
  },
  created () {
    this.val = Number(this.value)
  },
  methods: {
    onInput (val) {
      this.$emit('input', val)
    },
    onFocus () {
      this.$emit('focus')
    },
    onBlur () {
      this.$emit('blur')
    }
  },
  watch: {
    value () {
      this.val = Number(this.value)
    }
  }
}
</script>
