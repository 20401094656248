/**
 * Resources
 * https://projects.susielu.com/viz-palette
 */
export default {
  data () {
    return {}
  },
  methods: {
    getChartPalette () {
      return ['#FEC600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c', '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']
    },
    getGreen () {
      return 'rgba(140, 204, 173,0.8)'
    },
    getRed () {
      return 'rgba(225, 165, 160,0.8)'
    },
    getYellow () {
      return 'rgba(254, 198, 0,0.75)'
    },
    getPurple () {
      return 'rgba(204, 151, 182,0.8)'
    },
    getDarkPurple () {
      return 'rgba(82, 86, 112,0.8)'
    },
    getThreeColorPalette () {
      return ['#93caac', '#ca807c', '#525670']
    },
    getThreeColorPaletteYellow () {
      return ['#FEC600', '#f95d6a', '#525670']
    },
    getThreeColorPaletteYellowLight () {
      return ['#FFCE1F', '#fa7580', '#5F6481']
    },
    getIncomeGraphPalette (numberOfColors) {
      return this.getChartPalette()
    },
    getAssetGraphPalette (numberOfColors) {
      return this.getIncomeGraphPalette(numberOfColors)
    },
    getMultiHuePalette (numberOfColors = 20) { // for below average, average and above average graphs https://learnui.design/tools/data-color-picker.html#divergent
      switch (numberOfColors) {
        case 3:
          return ['#8b0000', '#ffffe0', '#008080']
        case 7:
          return ['#4d9a55', '#7abb6c', '#c0dd8e', '#fefbbb', '#f1c76d', '#e98736', '#dd3b30']
        case 13:
          return ['#8b0000', '#b61d39', '#d84765', '#ef738b', '#fea0ac', '#ffd1c9', '#ffffe0', '#c7f0ba', '#9edba4', '#7ac696', '#5aaf8c', '#399785', '#008080']
        case 20:
        default:
          return ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']
      }
    }
  }
}
