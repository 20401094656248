export default {
  methods: {
    rerenderCurrencyInputs (except = '', refPrefix = 'CurrencyInput') {
      let stack = Object.keys(this.$refs).filter(key => key.indexOf(refPrefix) === 0)
      stack = stack.filter((v, i, a) => a.indexOf(v) === i) // Unique
      stack = stack.filter(v => v !== except)
      stack = stack.filter(v => this.$refs[v])
      stack.map(key => {
        if (this.$refs[key].length) {
          this.$refs[key].map(ref => {
            rerenderIfNotFocused(ref)
          })
        } else {
          rerenderIfNotFocused(this.$refs[key])
        }
      })

      function rerenderIfNotFocused (ref) {
        if (!ref.isFocused && ref.$rerender) {
          ref.$rerender()
        }
      }
    }
  }
}
