<template>
  <div class="component-report-account">
    <balance-account-report v-if="isLoaded && bIsBalanceAccount"></balance-account-report>
    <performance-account-report-v2 v-if="isLoaded && bIsPerformanceAccount"></performance-account-report-v2>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import BalanceAccountReport from '@/views/reports/balance/Account/BalanceAccountReport'
import PerformanceAccountReportV2 from '@/views/reports/performance/AccountV2/PerformanceAccountReportV2'

export default {
  name: 'AccountReport',
  components: {
    BalanceAccountReport,
    PerformanceAccountReportV2
  },
  data () {
    return {
      isLoaded: false,
      bIsBalanceAccount: false,
      bIsPerformanceAccount: false
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    async loadData () {
      // If we get account_type by query, we don't need no API-request
      if (this.$route.query.account_type) {
        if (this.$route.query.account_type === 'balance' || this.$route.query.account_type === 'asset' || this.$route.query.account_type === 'liability') {
          this.bIsBalanceAccount = true
          this.bIsPerformanceAccount = false
        } else {
          this.bIsBalanceAccount = false
          this.bIsPerformanceAccount = true
        }
        this.isLoaded = true
        return true
      }

      return new Promise((resolve, reject) => {
        const sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/${this.$route.params.account_id}`

        axios.get(sApiEndpoint)
          .then((response) => {
            const apiData = response.data.data
            this.isLoaded = true

            this.bIsBalanceAccount = (apiData.type === 'asset' || apiData.type === 'liability')
            this.bIsPerformanceAccount = (apiData.type === 'income' || apiData.type === 'expense')
          })
          .catch((e) => {
            console.log(e)
          })
      })
    }
  },
  mounted () {
  }
}
</script>

<style>
.component-report-account{
  width: 100%;
}
</style>
