<template>
  <div class="component-performance-account-report">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ oAccount.label }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="isPeriodSelectorShown"
                default-period-type="rolling-3-months"
                :default-start-date="startDate ? startDate : null"
                :default-end-date="endDate ? endDate : null"
                :show-resolution="true"
                :default-resolution="resolution"
                :show-last-months="true"
                :allowed-resolutions="['week','month','year']"
                :show-period-option-full-period="true"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <performance-account-report-v2-submenu :active="activeTab" @tab-switched="onTabSwitched"></performance-account-report-v2-submenu>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter'}">
        <div class="pb-5" style="width:100%;height:500px;" v-if="activeTab !== 'about'">

          <account-report-period-sum-bar-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-sum-bar-chart' "
           :currency="currency"
           :currencySymbol="currencySymbol"
           :account="oAccount"
           :apiData="apiData"
           :isLoaded="!busyState"
           :resolution="resolution"
            />

          <account-report-period-sum-acc-area-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-sum-area-chart-acc' "
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :account="oAccount"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <account-report-period-budget-bar-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-budget-bar-chart' "
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :account="oAccount"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <account-report-period-budget-line-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-budget-line-chart' "
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :account="oAccount"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <account-report-period-budget-diff-bar-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-budget-diff-bar-chart' "
             :currency="currency"
             :currencySymbol="currencySymbol"
             :account="oAccount"
             :apiData="apiData"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <account-report-period-active-passive-bar-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-active-passive-bar-chart' "
             :currency="currency"
             :currencySymbol="currencySymbol"
             :account="oAccount"
             :apiData="apiData"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <account-report-period-active-passive-percentage-area-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-active-passive-percentage-area-chart'"
             :currency="currency"
             :currencySymbol="currencySymbol"
             :account="oAccount"
             :apiData="apiData"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <account-report-period-offset-accounts-bar-chart v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'period-offset-accounts'"
             :currency="currency"
             :currencySymbol="currencySymbol"
             :apiData="apiData"
             :account="oAccount"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <account-report-stats-half-pie-chart v-if="activeTab === 'stats' && statsChartToDisplay === 'common_transactions'"
            chartType="common_transactions"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :account="oAccount"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <account-report-stats-half-pie-chart v-if="activeTab === 'stats' && statsChartToDisplay === 'offset_accounts'"
             chartType="offset_accounts"
             :currency="currency"
             :currencySymbol="currencySymbol"
             :apiData="apiData"
             :account="oAccount"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <budget-simulation-chart
            v-if="activeTab === 'budget_simulation'"
            :chartType="simulationChartToDisplay"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :account="oAccount"
            :simulationData="simulationData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->
      <performance-account-report-v2-period-table v-if="activeTab === 'summary'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :account="oAccount"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />

      <performance-account-report-v2-transactions-table v-if="activeTab === 'transactions'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :account="oAccount"
        :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
        :active-chart-option="chartToDisplay"
        @account-changed="onAccountChanged"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />

      <performance-account-report-v2-stats-view v-if="activeTab === 'stats'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :account="oAccount"
        :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => statsChartToDisplay = data.chart"
      />

      <performance-account-report-v2-budget-simulation-income-view v-if="activeTab === 'budget_simulation' && oAccount.type === 'income'"
        ref="BudgetSimulationIncomeView"
        :isLoaded="!busyState"
        :account="oAccount"
        :incomingBalance="0"
        :meta="{ avgAmount: apiData ? apiData.totals.amounts.average : 0 }"
        @simulation-data="(simulation) => this.simulationData = simulation.data"
        @simulation-chart-to-display="(data) => this.simulationChartToDisplay = data.chart"
        @delete-all-amounts="deleteAllAmounts"
      />

      <performance-account-report-v2-budget-simulation-expense-view v-if="activeTab === 'budget_simulation' && oAccount.type === 'expense'"
        ref="BudgetSimulationExpenseView"
        :isLoaded="!busyState"
        :account="oAccount"
        :incomingBalance="0"
        :meta="{ avgAmount: apiData ? apiData.totals.amounts.average : 0 }"
        @simulation-data="(simulation) => this.simulationData = simulation.data"
        @simulation-chart-to-display="(data) => this.simulationChartToDisplay = data.chart"
        @delete-all-amounts="deleteAllAmounts"
      />

      <performance-account-report-v2-about-view v-if="activeTab === 'about'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :account="oAccount"
        :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
      />

      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        :disabled="['income', 'expense']"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />

    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import PerformanceAccountReportV2Submenu from './inc/PerformanceAccountReportV2Submenu'
import PerformanceAccountReportV2TransactionsTable from './tables/PerformanceAccountReportV2TransactionsTable'
import PerformanceAccountReportV2PeriodTable from './tables/PerformanceAccountReportV2PeriodTable'
import AccountReportPeriodSumBarChart from './charts/AccountReportPeriodSumBarChart'
import AccountReportPeriodSumAccAreaChart from './charts/AccountReportPeriodSumAccAreaChart'
import AccountReportPeriodBudgetBarChart from './charts/AccountReportPeriodBudgetBarChart'
import PerformanceAccountReportV2StatsView from './view/PerformanceAccountReportV2StatsView'
import AccountReportStatsHalfPieChart from './charts/AccountReportStatsHalfPieChart'
import AccountReportPeriodBudgetLineChart from './charts/AccountReportPeriodBudgetLineChart'
import AccountReportPeriodBudgetDiffBarChart from './charts/AccountReportPeriodBudgetDiffBarChart'
import PerformanceAccountReportV2AboutView from './view/PerformanceAccountReportV2AboutView'
import PerformanceAccountReportV2BudgetSimulationIncomeView from './view/PerformanceAccountReportV2BudgetSimulationIncomeView'
import PerformanceAccountReportV2BudgetSimulationExpenseView from './view/PerformanceAccountReportV2BudgetSimulationExpenseView'
import AccountReportPeriodActivePassiveBarChart from './charts/AccountReportPeriodActivePassiveBarChart'
import AccountReportPeriodActivePassivePercentageAreaChart from './charts/AccountReportPeriodActivePassivePercentageAreaChart'
import AccountReportPeriodOffsetAccountsBarChart from './charts/AccountReportPeriodOffsetAccountsBarChart'
import BudgetSimulationChart from '@/components/charts/BudgetSimulationChart'
import ReportFilter from '@/components/common/ReportFilter.vue'
import _ from 'lodash'

export default {
  name: 'PerformanceAccountReportV2',
  mixins: [titleMixins],
  components: {
    PeriodSelector,
    PerformanceAccountReportV2Submenu,
    PerformanceAccountReportV2TransactionsTable,
    PerformanceAccountReportV2PeriodTable,
    AccountReportPeriodSumBarChart,
    AccountReportPeriodSumAccAreaChart,
    AccountReportPeriodBudgetBarChart,
    PerformanceAccountReportV2StatsView,
    AccountReportStatsHalfPieChart,
    AccountReportPeriodBudgetLineChart,
    AccountReportPeriodBudgetDiffBarChart,
    PerformanceAccountReportV2AboutView,
    PerformanceAccountReportV2BudgetSimulationIncomeView,
    PerformanceAccountReportV2BudgetSimulationExpenseView,
    AccountReportPeriodActivePassiveBarChart,
    AccountReportPeriodActivePassivePercentageAreaChart,
    ReportFilter,
    AccountReportPeriodOffsetAccountsBarChart,
    BudgetSimulationChart
  },
  data () {
    return {
      isPeriodSelectorShown: false,
      validRouteParams: ['summary', 'transactions', 'stats', 'budget_simulation', 'about', 'filter'],
      activeTab: 'summary',
      apiData: null,
      bHasAPIDataBeenFetchedOnce: false,
      translationPath: 'reports.performance.account.',
      busyState: true,
      startDate: null,
      endDate: null,
      resolution: 'month',
      chartToDisplay: 'period-sum-bar-chart',
      statsChartToDisplay: 'common_transactions',
      oAccount: {
        type: 'income',
        label: '',
        group_label: ''
      },
      reportFilterData: null,
      bIsFilterApplied: false,
      simulationData: null,
      simulationChartToDisplay: 'simulation-performance-total-amount'
    }
  },
  created () {
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      const aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/performance' },
        { text: this.$t('reports._common.performance_reports'), to: '/reports/performance' },
        { text: this.$t('incomes_expenses.submenu.' + this.oAccount.type + '_categories'), to: '/reports/other/account-plan-v2/' + this.oAccount.type + 's' },
        { text: this.oAccount.group_label, to: '/reports/performance/account-groups/' + this.oAccount.group_id },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    /**
     * Handle emitted data from period_selector
     */
    onPeriodChange (data) {
      this.startDate = data.sDateStart
      this.endDate = data.sDateEnd
      this.resolution = data.resolution
      if (this.startDate !== this.$route.query.start_date || this.endDate !== this.$route.query.end_date) {
        this.$router.push({ query: this.buildRouteQueryFromFilterParams() })
      }
      this.loadData()
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        const subtab = newtab === 'stats' ? 'common_transactions' : null
        this.$router.push({ params: { tab: newtab, subtab: subtab }, query: this.buildRouteQueryFromFilterParams() })
      }
    },
    onAccountChanged (accountData) {
      if (accountData.account_id.toString() === this.$route.params.account_id) {
        return false
      }

      const route = {
        params: _.cloneDeep(this.$route.params),
        query: _.cloneDeep(this.$route.query)
      }
      route.params.account_id = accountData.account_id
      route.query.account_type = accountData.account_type

      this.$router.push(route)
      this.$router.go()

      return true
    },
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/performance/accounts/${this.$route.params.account_id}/view?start_date=${this.startDate}&end_date=${this.endDate}&include_transactions=1&resolution=${this.resolution}`

        // Filter persons
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectPerson')) {
          sApiEndpoint += '&filter_persons=' + this.reportFilterData.oFormSelectPerson.join(',')
        }

        // Filter assets
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectAsset')) {
          sApiEndpoint += '&filter_accounts_asset=' + this.reportFilterData.oFormSelectAsset.join(',')
        }

        // Filter liabilities
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectLiability')) {
          sApiEndpoint += '&filter_accounts_liability=' + this.reportFilterData.oFormSelectLiability.join(',')
        }

        axios.get(sApiEndpoint)
          .then(response => {
            this.apiData = response.data.data
            this.oAccount = this.apiData.account
            this.busyState = false
            this.bHasAPIDataBeenFetchedOnce = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    buildRouteQueryFromFilterParams () {
      const oQuery = _.cloneDeep(this.$route.query)
      oQuery.start_date = this.startDate
      oQuery.end_date = this.endDate
      return oQuery
    },
    onFilterApplied (eventData) {
      this.bIsFilterApplied = true
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    onFilterDeleted () {
      this.bIsFilterApplied = false
      this.loadData()
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    clearFilter () {
      this.bIsFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.oFormSelectAsset = []
        this.reportFilterData.oFormSelectLiability = []
        this.reportFilterData.oFormSelectPerson = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    deleteAllAmounts (data) {
      const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/delete-for-account/${data.budget_id}/${data.account_id}`
      axios.delete(apiEndpoint)
        .then(response => {
          if (this.$refs.BudgetSimulationIncomeView) {
            this.$refs.BudgetSimulationIncomeView.clearTable()
          }
          if (this.$refs.BudgetSimulationExpenseView) {
            this.$refs.BudgetSimulationExpenseView.clearTable()
          }

          this.$bvToast.toast(this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_success', { account_title: this.oAccount.title }).toString(), {
            title: this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_title').toString(),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_fail', { account_title: this.oAccount.title }).toString(), {
            title: this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_title').toString(),
            variant: 'danger',
            solid: true
          })
        })
    }
  },
  mounted () {
    if (this.$route.query.start_date) {
      this.startDate = this.$route.query.start_date
    }
    if (this.$route.query.end_date) {
      this.endDate = this.$route.query.end_date
    }
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }
    this.isPeriodSelectorShown = true
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style>
.component-performance-account-report{
  width: 100%;
}
</style>
