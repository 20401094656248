<template>
  <b-container fluid class="component-reports-balance-account-change-distribution-bar-chart pb-5">

    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-balance-account-change-distribution-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>

  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceReportTimeWeightedReturnChart',
  props: {
    apiData: { type: Object },
    account: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  mixins: [chartOptions],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports.balance.account.twr.graph.',
      chartData: {}
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      immediate: true
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }

      const amountLabel = this.$t('common.amount')
      const percentageLabel = this.$t(this.translationPath + 'tick_label_twr_return')
      const suffix = ' ' + this.currency

      this.chartOptions.legend.display = false

      this.chartOptions.scales = {
        yAxes: [{
          display: true,
          gridLines: { display: false },
          id: 'id2',
          position: 'left',
          ticks: {
            fontColor: '#231f20',
            beginAtZero: true,
            fontFamily: 'Nunito',
            callback: function (value, index, values) {
              return formatNumberToFull(value) + ' %'
            }
          }
        }]
      }

      /* Modify Y-mouse over */
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toFixed(1) + ' %'
      }

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        if (tooltipItem.datasetIndex === 1) {
          return amountLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
        }
        return percentageLabel + formatNumberToFull(tooltipItem.yLabel) + ' %'
      }

      const labelData = []
      const aReturnRateData = []

      for (var index in this.apiData.twr.steps) {
        aReturnRateData.push((this.apiData.twr.steps[index].return_rn_acc * 100).toFixed(2))
        labelData.push(this.apiData.twr.steps[index].date)
      }

      const aReturnRateDataset = {
        label: this.$t('common.percentage'),
        data: aReturnRateData,
        borderColor: '#8cccad',
        type: 'line',
        steppedLine: 'middle'
      }

      this.chartData = {
        labels: labelData,
        datasets: [aReturnRateDataset]
      }
    }
  }
}
</script>

<style lang="scss">
</style>
