<template>
  <b-container fluid class="component-balance-account-report-closing-balance-value-change-offset-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'balance-account-report-closing-balance-value-change-offset-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceAccountReportClosingBalanceValueChangeOffsetBarChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.account.chart.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = [[this.$t('reports.balance.common.opening_balance'), this.apiData.meta.start_date]]
      this.apiData.meta.periods.map(period => {
        labels.push(this.getTimeLabelFromPeriodLabel(period, this.resolution))
      })

      // const sAccountTitle = this.account.label
      const suffix = ' ' + this.currencySymbol
      const changeLabel = this.$t('reports.balance.common.period_change')
      const balanceInLabel = this.$t('reports.balance.common.opening_balance')
      const balanceOutLabel = this.$t('reports.balance.common.closing_balance')
      const self = this
      this.chartOptions.legend.display = false

      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        let lblRowBalanceIn = ''
        let lblRowBalanceOut = ''
        let lblRowBalanceChange = ''
        let aTooltip = []

        // Offset makes yLabel a string that looks like an array
        if (tooltipItem.yLabel[0] === '[') {
          const tmpArray = JSON.parse(tooltipItem.yLabel)

          lblRowBalanceIn = balanceInLabel + ': ' + formatNumberToFull(tmpArray[0]) + suffix
          lblRowBalanceOut = balanceOutLabel + ': ' + formatNumberToFull(tmpArray[1]) + suffix
          lblRowBalanceChange = changeLabel + ': ' + formatNumberToFull(tmpArray[1] - tmpArray[0]) + suffix

          aTooltip = [lblRowBalanceIn, lblRowBalanceChange, lblRowBalanceOut]
        } else {
          aTooltip = [self.$t('common.amount') + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix]
        }

        return aTooltip
      }

      const aBackgroundColors = []
      const dataset = { backgroundColor: null, type: 'bar', data: [] }
      let iOffset = this.apiData.periods.amounts.totals.opening_balance
      let yMinValue = Number.MAX_SAFE_INTEGER
      let iValue = null

      // Opening balance
      dataset.data.push(iOffset)
      aBackgroundColors.push(iOffset >= 0 ? this.getGreen() : this.getRed())
      yMinValue = iOffset < yMinValue ? iOffset : yMinValue

      // Periods
      for (const period in this.apiData.meta.periods) {
        // Use offset by setting value by [start_position, end_position]
        iValue = this.apiData.periods.amounts.closing_balances[this.apiData.meta.periods[period]]
        dataset.data.push([iOffset, iValue])
        iOffset = iValue

        yMinValue = iOffset < yMinValue ? iOffset : yMinValue

        // If positive change, green otherwise red
        aBackgroundColors.push(this.apiData.periods.amounts.period_changes[this.apiData.meta.periods[period]] >= 0 ? this.getGreen() : this.getRed())
      }

      // "Zoom in" by not startin y-scale at 0
      if (yMinValue > 0) {
        this.chartOptions.scales.yAxes[0].ticks.min = (yMinValue * 0.75).toFixed(0)
      }

      // Closing balance
      labels.push([this.$t('reports.balance.common.closing_balance'), this.apiData.meta.end_date])
      dataset.data.push(this.apiData.periods.amounts.totals.closing_balance)
      aBackgroundColors.push(this.apiData.periods.amounts.totals.closing_balance >= 0 ? this.getGreen() : this.getRed())

      dataset.backgroundColor = aBackgroundColors

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    }
  }
}
</script>
