<template>
  <div class="component-balance-account-report-budget-simulation-view">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="12" md="3" lg="4" xl="6">
                  <h2>{{ $t(translationPath + 'simulation_settings',{account_name: account.title }) }}</h2>
                </b-col>
                <b-col class="text-right" cols="12" md="9" lg="8" xl="6">
                  <!-- Toggle simulation charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 mb-2 spirecta-toggle-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('simulation-amortization')" v-bind:class="{ active: showChart === 'simulation-amortization' }">{{$t( translationPath + 'charts.simulation-amortization')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('simulation-interest')" v-bind:class="{ active: showChart === 'simulation-interest' }">{{$t( translationPath + 'charts.simulation-interest')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('simulation-outgoing-balance')" v-bind:class="{ active: showChart === 'simulation-outgoing-balance' }">{{$t( translationPath + 'charts.simulation-outgoing-balance')}}</b-dropdown-item>
                  </b-dropdown>
                  <toggle-budgets-dropdown ref="BudgetDropdown" :budgets="budgets" :initial-budget-id="budgetId" @change="onBudgetChange" />
                </b-col>
              </b-row>
            </template>

            <b-row no-gutters>
              <b-col cols="8">
                <div v-html="$t(translationPath + 'simulation_settings_description', { account_name: account.title })" class="pt-2 pb-3"></div>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col cols="12">
                <b-card no-body class="white-tabs-card">
                  <b-tabs class="white-tabs" v-model="tabIndexSimulation">
                    <b-tab :title="$t(translationPath + 'tab_simulate')">

                      <!-- START: SIMULATE FORM -->
                      <b-form class="pt-4">
                        <!-- starting_amount -->
                        <b-form-group
                          label-for="set_values_for"
                          label-cols-lg="3"
                          content-cols-lg="3"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.starting_amount')"
                          :invalid-feedback="startingAmountInvalidFeedback"
                          :description="$t(translationPath + 'params.starting_amount_description', {date: oSimulationParams.rows[0].start_yearmonth+'-01'})"
                        >
                          <currency-input
                            ref="CurrencyInputStartingAmount"
                            id="starting_amount"
                            v-model="oSimulationParams.startingAmount"
                            :input-class="inputValidationClasses.startingAmount"
                            :precision="0"
                          />
                        </b-form-group>

                        <div class="mt-2 mb-5">
                          <b-row no-gutters>
                            <b-col cols="12" lg="4" class="pr-lg-2">
                              <strong>{{ $t(translationPath + 'params.start_yearmonth') }}</strong>
                            </b-col>
                            <b-col cols="12" lg="4" class="px-lg-2">
                              <strong>{{ $t(translationPath + 'params.amortization_per_month') }}</strong>
                            </b-col>
                            <b-col cols="12" lg="4" class="pl-lg-2">
                              <strong>{{ $t(translationPath + 'params.interest') }}</strong>
                            </b-col>
                          </b-row>
                          <b-row no-gutters v-for="(row, index) in oSimulationParams.rows" :key="index">
                            <b-col cols="12" lg="4" class="pr-lg-2">
                              <b-form-group
                                :label-for="'start_yearmonth_' + index"
                                :description="$t(translationPath + 'params.start_yearmonth_description', {start_period: startOfMonthFromYearMonthString(oSimulationParams.rows[index].start_yearmonth), end_period: oSimulationParams.rows[index + 1] ? subtractOneMonthFromYearMonthString(oSimulationParams.rows[index + 1].start_yearmonth) : endOfMonthFromYearMonthString(oSimulationParams.end_yearmonth)})"
                              >
                                <b-form-input
                                  :id="'start_yearmonth_' + index"
                                  v-model="oSimulationParams.rows[index].start_yearmonth"
                                  class="form-control text-left"
                                  :state="$v.oSimulationParams.rows.$each[index] && $v.oSimulationParams.rows.$each[index].start_yearmonth.$dirty ? !$v.oSimulationParams.rows.$each[index].start_yearmonth.$invalid : null"
                                />
                              </b-form-group>
                            </b-col>

                            <b-col cols="12" lg="4" class="px-lg-2">
                              <b-form-group
                                :label-for="'amortization_per_month_' + index"
                                :description="$t(translationPath+'params.recalc_to_annual_amount', {amount: oSimulationParams.rows[index].amortizationPerMonth * 12})"
                              >
                                <currency-input
                                  :ref="'CurrencyInputAmortizationPerMonth' + index"
                                  :id="'amortization_per_month_' + index"
                                  v-model="oSimulationParams.rows[index].amortizationPerMonth"
                                  :input-class="validationCssClass($v.oSimulationParams.rows.$each[index] ? $v.oSimulationParams.rows.$each[index].amortizationPerMonth : null)"
                                  :precision="0"
                                />
                              </b-form-group>
                            </b-col>

                            <b-col cols="12" lg="4" class="pl-lg-2">
                              <b-row no-gutters>
                                <b-col cols="11">
                                  <b-form-group
                                    :label-for="'interest_' + index"
                                  >
                                    <percentage-input
                                      :id="'interest_' + index"
                                      v-model="oSimulationParams.rows[index].interest"
                                      :input-class="validationCssClass($v.oSimulationParams.rows.$each[index] ? $v.oSimulationParams.rows.$each[index].interest : null)"
                                      :precision="2"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col cols="1">
                                  <b-button
                                    v-if="oSimulationParams.rows.length > 1"
                                    :title="$t('common.delete')"
                                    class="plain-btn action-button mt-0 pt-0"
                                    @click="onDeleteRowClick(index)"
                                  >
                                    <i class="flaticon solid trash-3 text-danger"></i>
                                  </b-button>
                                  <b-button
                                      :title="$t(translationPath + 'add_row_below')"
                                      class="plain-btn action-button"
                                      :class="{ 'mt-0 pt-0': oSimulationParams.rows.length > 1, 'mt-1 pt-2': oSimulationParams.rows.length === 1 }"
                                      @click="onAddRowClick(index)"
                                  >
                                    <i class="flaticon solid plus-2 text-primary"></i>
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>

                          <b-row no-gutters>
                            <b-col cols="12">
                              <button class="btn btn-xs btn-outline-secondary mt-2" @click.prevent="onAddRowClick">{{ $t(translationPath + 'add_row' )}}</button>
                            </b-col>
                          </b-row>
                        </div>

                        <!-- End period -->
                        <b-form-group
                          label-for="end_yearmonth"
                          label-cols-lg="3"
                          content-cols-lg="3"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.end_yearmonth')"
                          :description="$t(translationPath + 'params.period_years_description', {end_date: endOfMonthFromYearMonthString(oSimulationParams.end_yearmonth)})"
                          :invalid-feedback="endYearmonthInvalidFeedback"
                        >
                          <b-form-input
                            id="start_year_phase1"
                            v-model="oSimulationParams.end_yearmonth"
                            class="form-control text-left text-md-right"
                            :state="$v.oSimulationParams.end_yearmonth.$dirty ? !$v.oSimulationParams.end_yearmonth.$invalid : null"
                          />
                        </b-form-group>

                        <!-- PRESETS -->
                        <b-form-group
                          label-for=""
                          label-cols-lg="3"
                          content-cols-lg="9"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.presets')"
                        >
                          <b-row no-gutters>
                            <b-col cols="12" lg="4" class="pr-1">
                              <b-form-select
                                id="presets"
                                class="form-control text-left"
                                v-model="selectedPresetId"
                                :options="presetOptions"
                                @change="selectPreset"
                              />
                            </b-col>
                            <b-col cols="12" lg="8" v-if="Number.isInteger(selectedPresetId) && selectedPresetId !== 0">
                              <button class="btn btn-sm btn-primary mt-2 ml-lg-2" @click.prevent="savePreset">{{ $t('common.save' )}}</button>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <template v-if="selectedPresetId === 'new'">
                          <b-form-group
                            label-for="preset_title"
                            label-cols-lg="3"
                            content-cols-lg="9"
                            label-class="pr-4 text-black px-2"
                            :label="$t(translationPath + 'lbl_save_preset_as')"
                            :invalid-feedback="presetTitleInvalidFeedback"
                          >
                            <b-row no-gutters>
                              <b-col cols="12" lg="4" class="pr-1">
                                <b-form-input
                                  id="preset_title"
                                  class="form-control"
                                  v-model="presetTitle"
                                  :state="$v.presetTitle.$dirty ? !$v.presetTitle.$invalid : null"
                                />
                              </b-col>
                              <b-col cols="12" lg="8">
                                <button class="btn btn-sm btn-primary mt-2 ml-lg-2" @click.prevent="savePreset">{{ $t('common.save' )}}</button>
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </template>

                        <!-- SIMULATE AND SAVE DATA BTNs -->
                        <b-row no-gutters>
                          <b-col cols="12" class="text-left pt-2">
                            <button :class="'btn btn-sm ' + (isNewSimulationDone ? 'btn-outline-secondary' : 'btn-primary')" @click.prevent="simulateLiability">{{$t(translationPath + 'params.action_simulate')}}</button>
                            <button v-if="budgets.length && isNewSimulationDone" class="btn btn-sm btn-primary ml-2" @click.prevent="saveMonthlyData">{{$t(translationPath + 'params.action_save_monthly_data')}}</button>
                          </b-col>
                        </b-row>

                      </b-form>
                      <!-- END: SIMULATE FORM -->
                    </b-tab>
                    <b-tab :title="$t(translationPath + 'tab_upload_csv')">

                      <b-row no-gutters>
                        <b-col cols="8">
                          <div v-html="$t(translationPath + 'tab_upload_csv_description')" class="pt-2"></div>
                        </b-col>
                      </b-row>

                      <b-form>
                        <b-form-group
                          label-for="import_textarea"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.import_textarea')"
                          :invalid-feedback="importTextareaInvalidFeedback"
                        >
                          <b-form-textarea
                            id="import_textarea"
                            v-model="oImportData.textarea"
                            class="form-control text-left"
                            :class="oImportData.textAreaValidationClass"
                            :placeholder="$t(translationPath + 'params.import_liability_textarea_placeholder')"
                            :state="$v.oImportData.textarea.$dirty ? !$v.oImportData.textarea.$invalid : null"
                            @input="oImportData.textAreaValidationClass = ''"
                            >
                          </b-form-textarea>
                        </b-form-group>
                      </b-form>

                      <!-- PARSE BTN -->
                      <b-row no-gutters>
                        <b-col class="text-left">
                          <button :class="'btn btn-sm ' + (isNewSimulationDone ? 'btn-outline-secondary' : 'btn-primary')" @click.prevent="parsePastedValues" prev>{{$t(translationPath + 'params.action_import')}}</button>
                          <button v-if="budgets.length && isNewSimulationDone" class="btn btn-sm btn-primary ml-2" @click.prevent="saveMonthlyData">{{$t(translationPath + 'params.action_save_monthly_data')}}</button>
                        </b-col>
                      </b-row>

                    </b-tab>
                    <b-tab :title="$t(translationPath + 'tab_notes')">
                      <b-row no-gutters>
                        <b-col cols="8">
                          <div v-html="$t(translationPath + 'tab_notes_description')" class="pt-2 mb-3"></div>
                        </b-col>
                      </b-row>

                      <b-form>
                        <b-form-group
                          label-for="import_textarea"
                          required
                          label-class="pr-4 text-black px-2"
                          :label="$t(translationPath + 'params.notes')"
                        >
                          <b-form-textarea
                            id="import_textarea"
                            v-model="notes"
                            class="form-control text-left"
                          />
                        </b-form-group>
                      </b-form>

                      <b-row no-gutters>
                        <b-col cols="3" class="text-left">
                          <button class="btn btn-sm btn-primary" @click.prevent="updateNotes" prev>{{$t(translationPath + 'params.action_update_notes')}}</button>
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>

          </b-card>

          <b-card class="main-gradient-content-card borderlight mt-3 pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'simulation_values', { account_name: account.title }) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <b-button v-if="aOutputData.monthly_data.length" variant="btn btn-sm btn-outline-secondary" @click="onDeleteAllAmounts">{{ $t('reports.budget.lifeplan.create_values_modal.delete_values') }}</b-button>
                  <button class="btn btn-sm btn-outline-secondary ml-2" @click.prevent="exportMonthlyValues()">{{$t(translationPath + 'export_transactions' )}}</button>
                </b-col>
              </b-row>
              <b-row  no-gutters v-show="isNewSimulationDone">
                <b-col class="col-lg-8 col-xl-8">
                  <b-alert variant="warning" class="mt-2" show v-html="$t(translationPath + 'preview_numbers')" />
                </b-col>
              </b-row>

              <b-row no-gutters>
                <b-col cols="8">
                  <div v-html="$t(translationPath + 'simulation_values_description')" class="pt-2"></div>
                </b-col>
              </b-row>

              <b-row no-gutters>
                <b-col cols="12">
                  <b-card no-body class="white-tabs-card">
                    <b-tabs class="white-tabs" v-model="tabIndexData">
                      <b-tab :title="$t(translationPath + 'annual_data')">
                        <!-- ANNUAL DATA TABLE -->
                        <b-table
                          class="spirecta-simple-table simulation-result-table mb-0"
                          show-empty
                          hover
                          responsive
                          striped
                          stacked="md"
                          :items="aOutputData.annual_data"
                          :fields="aTableColumns('annual')"
                          :busy="!isLoaded || isLoadingTable"
                        >
                          <template v-slot:table-busy>
                            <loader />
                          </template>
                        </b-table>
                      </b-tab>
                      <b-tab :title="$t(translationPath + 'monthly_data')">
                        <!-- MONTHLY DATA TABLE -->
                        <b-table
                          class="spirecta-simple-table simulation-result-table mb-0"
                          show-empty
                          hover
                          responsive
                          striped
                          stacked="md"
                          :items="aOutputData.monthly_data"
                          :fields="aTableColumns('monthly')"
                          :busy="!isLoaded || isLoadingTable"
                          :per-page="oPaginationOptions.perMonthTable.iRowsPerPage"
                          :current-page="oPaginationOptions.perMonthTable.iCurrentPage"
                          :key="renderKey"
                        >
                          <template v-slot:table-busy>
                            <loader />
                          </template>

                          <template v-slot:cell(total_value_change)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputTotalValueChange' + row.item.id"
                                id="total_value_change"
                                v-model="row.item.total_value_change"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(added_amount)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputAddedAmount' + row.item.id"
                                id="added_amount"
                                v-model="row.item.added_amount"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(value_before_tax)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputValueBeforeTax' + row.item.id"
                                id="value_before_tax"
                                v-model="row.item.value_before_tax"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(tax_amount)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputTaxAmount' + row.item.id"
                                id="tax_amount"
                                v-model="row.item.tax_amount"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(outgoing_balance)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputOutgoingBalance' + row.item.id"
                                id="outgoing_balance"
                                v-model="row.item.outgoing_balance"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(incoming_balance)="row">
                            <template v-if="!row.item.edit_mode">
                              {{ row.value }}
                            </template>
                            <template v-else>
                              <currency-input
                                :ref="'CurrencyInputIncomingBalance' + row.item.id"
                                id="incoming_balance"
                                v-model="row.item.incoming_balance"
                                :precision="0"
                              />
                            </template>
                          </template>

                          <template v-slot:cell(actions)="row">
                            <template v-if="!row.item.edit_mode">
                              <b-button variant="outline-danger" class="btn-sm" @click="onMonthValueEditClick(row.item)">{{ $t('common.edit') }}</b-button>
                            </template>
                            <template v-else>
                              <b-button variant="outline-primary" class="btn-sm mt-2" @click="onMonthValueSaveClick(row.item)">{{ $t('common.save') }}</b-button>
                            </template>
                          </template>
                        </b-table>
                        <b-pagination
                          v-model="oPaginationOptions.perMonthTable.iCurrentPage"
                          :total-rows="aOutputData.monthly_data.length"
                          :per-page="oPaginationOptions.perMonthTable.iRowsPerPage"
                          aria-controls="my-table"
                        />
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>

            </template>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <excel-data-error-modal ref="ExcelParseErrorModal" :errors="aLastExcelParseErrors" />

    <info-modal
      ref="NoSavedDataModal"
      :title="$t(translationPath + 'no_saved_data_modal_title')"
      :message="$t(translationPath + 'no_saved_data_modal_text', { account_title: account.title, budget_title: budgetTitle })"
      variant="warning"
    />

    <delete-modal
      ref="DeleteAmountsModal"
      :heading="$t('reports.budget.lifeplan.create_values_modal.confirm_delete_amounts_title')"
      :message="$t('reports.budget.lifeplan.create_values_modal.confirm_delete_amounts_text', { account_title: account.title })"
      @on-delete-confirm="confirmDeleteAllAmounts"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CurrencyInput from '@/components/common/CurrencyInput'
import PercentageInput from '@/components/common/PercentageInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import ExcelDataErrorModal from '../inc/ExcelDataErrorModal'
import InfoModal from '@/components/modals/InfoModal'
import DeleteModal from '@/components/modals/DeleteModal'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'
import BudgetSimulationMixin from '@/mixins/BudgetSimulationMixin'
import _ from 'lodash'

const isDateValid = getter => function (value) {
  if (!/\d{4}-\d{2}/.test(value)) {
    return false
  }
  const month = Number(value.substr(5))
  if (month > 12 || month === 0) {
    return false
  }
  return true
}

const isDateLessThanEndDate = getter => function (value) {
  if (value >= this.oSimulationParams.end_yearmonth) {
    return false
  }
  return true
}

const isDateRangeAscending = getter => function (value) {
  let isValid = true
  const lastIndex = parseInt(this.oSimulationParams.rows.length) - 1

  for (const i in this.oSimulationParams.rows) {
    if (parseInt(i) === lastIndex) {
      break
    }

    if (this.oSimulationParams.rows[i].start_yearmonth >= this.oSimulationParams.rows[parseInt(i) + 1].start_yearmonth) {
      isValid = false
      break
    }
  }

  return isValid
}

export default {
  name: 'BalanceAccountReportBudgetSimulationLiabilityView',
  mixins: [BudgetSimulationMixin],
  components: {
    CurrencyInput,
    PercentageInput,
    ExcelDataErrorModal,
    InfoModal,
    DeleteModal,
    ToggleBudgetsDropdown
  },
  props: {
    activeTab: { type: String, default: '' },
    isLoaded: { type: Boolean, default: false },
    incomingBalance: { type: Number, default: 10000 },
    account: {
      type: Object,
      default: () => {
        return {
          title: ''
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.account.budget_simulation.',
      isNewSimulationDone: false,
      oSimulationParams: {
        rows: [
          {
            start_yearmonth: moment().format('YYYY-MM'),
            amortizationPerMonth: 0,
            interest: 0
          }
        ],
        startingAmount: 0,
        end_yearmonth: null,
        taxType: null
      }
    }
  },
  validations: {
    oSimulationParams: {
      startingAmount: { required },
      end_yearmonth: { required, isDateValid: isDateValid() },
      rows: {
        required,
        minLength: minLength(1),
        $each: {
          amortizationPerMonth: { required },
          start_yearmonth: {
            required,
            isDateValid: isDateValid(),
            isDateLessThanEndDate: isDateLessThanEndDate(),
            isDateRangeAscending: isDateRangeAscending()
          },
          interest: { required }
        }
      }
    },
    presetTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(100)
    },
    oImportData: {
      textarea: { required }
    }
  },
  created () {
    if (this.$route.query.budget) {
      this.budgetId = Number(this.$route.query.budget)
    } else {
      this.budgetId = this.currentCOA.default_budget_id
    }

    this.setupFormDefaultsOnce()
    this.loadBudgets()
    this.loadPresets()
    this.loadNotes()
    this.oSimulationParams.startingAmount = this.incomingBalance
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    inputValidationClasses () {
      const classes = {
        startingAmount: {
          'is-invalid': this.$v.oSimulationParams.startingAmount.$dirty && this.$v.oSimulationParams.startingAmount.$error,
          'is-valid': this.$v.oSimulationParams.startingAmount.$dirty && !this.$v.oSimulationParams.startingAmount.$error
        }
      }

      return classes
    },
    startingAmountInvalidFeedback () {
      if (this.$v.oSimulationParams.startingAmount.required === false) return this.$t(this.translationPath + 'errors.required')
      return ''
    }
  },
  methods: {
    onMonthValueEditClick (item) {
      item.edit_mode = true
      this.renderKey++
    },
    onMonthValueSaveClick (item) {
      item.edit_mode = false
      this.renderKey++
    },
    onAddRowClick (index = null) {
      if (index === null) {
        index = this.oSimulationParams.rows.length - 1
      }

      let startYearMonth = moment(this.oSimulationParams.rows[this.oSimulationParams.rows.length - 1].start_yearmonth + '-01')
      startYearMonth = startYearMonth.add(10, 'years').format('YYYY-MM')
      if (startYearMonth >= this.oSimulationParams.end_yearmonth) {
        startYearMonth = moment(this.oSimulationParams.end_yearmonth + '-01').subtract(1, 'months').format('YYYY-MM')
      }

      const insertingObject = {
        start_yearmonth: startYearMonth,
        amortizationPerMonth: 0,
        interest: this.account.expected_annual_return ? parseFloat(this.account.expected_annual_return) : 0
      }

      if (this.oSimulationParams.rows.length > 1 && index < this.oSimulationParams.rows.length - 1) {
        const copiedRows = _.cloneDeep(this.oSimulationParams.rows)
        this.oSimulationParams.rows = []
        for (const i in copiedRows) {
          this.oSimulationParams.rows.push(Object.assign({}, copiedRows[i]))
          if (parseInt(i) === index) {
            this.oSimulationParams.rows.push(insertingObject)
          }
        }
      } else {
        this.oSimulationParams.rows.push(insertingObject)
      }

      process.nextTick(() => {
        this.renderKey++
        this.rerenderCurrencyInputs()
      })
    },
    onDeleteRowClick (index) {
      this.oSimulationParams.rows = this.oSimulationParams.rows.filter((row, rowIndex) => rowIndex !== index)
      this.rerenderCurrencyInputs()
      this.renderKey++
    },
    aTableColumns (sTablePeriod) {
      let aColumns = [{ key: 'year', label: this.$t(this.translationPath + 'table.year'), sortable: false, class: 'text-left td-year' }]

      if (sTablePeriod === 'monthly') {
        aColumns.push({ key: 'month', label: this.$t(this.translationPath + 'table.month'), sortable: false, class: 'text-left td-month' })
      }
      aColumns = aColumns.concat([
        { key: 'incoming_balance', label: this.$t(this.translationPath + 'table.incoming_balance'), sortable: false, class: 'text-right td-incoming_balance', formatter: this.amountFormatter },
        { key: 'amortization', label: this.$t(this.translationPath + 'table.amortization'), sortable: false, class: 'text-right td-amortization', formatter: this.amountFormatter },
        { key: 'outgoing_balance', label: this.$t(this.translationPath + 'table.outgoing_balance'), sortable: false, class: 'text-right td-outgoing_balance', formatter: this.amountFormatter },
        { key: 'interest', label: this.$t(this.translationPath + 'table.interest'), sortable: false, class: 'text-right td-amortization', formatter: this.amountFormatter },
        { key: 'actions', label: '' }
      ])

      return aColumns
    },
    simulateLiability () {
      this.$v.oSimulationParams.$touch()
      if (this.$v.oSimulationParams.$invalid) {
        return false
      }
      this.isNewSimulationDone = true
      const oPeriodTemplateData = {
        incoming_balance: 0,
        amortization: 0,
        interest: 0,
        outgoing_balance: 0
      }
      let iIncomingBalance = this.oSimulationParams.startingAmount
      this.aOutputData = { annual_data: [], monthly_data: [] }

      this.oSimulationParams.rows.map((phase, index, elements) => {
        const start = moment(phase.start_yearmonth + '-15', 'YYYY-MM-DD')
        let end = moment(this.oSimulationParams.end_yearmonth + '-15', 'YYYY-MM-DD')
        if (elements[index + 1]) {
          end = moment(elements[index + 1].start_yearmonth + '-15', 'YYYY-MM-DD').subtract(1, 'months')
        }

        let month = start
        while (month <= end) {
          const oMonthPeriod = { ...oPeriodTemplateData }
          oMonthPeriod.year = Number(month.format('YYYY'))
          oMonthPeriod.month = Number(month.format('M'))
          oMonthPeriod.incoming_balance = iIncomingBalance
          oMonthPeriod.interest = iIncomingBalance * (Math.pow(1 + phase.interest / 100, (1 / 12)) - 1)

          if (oMonthPeriod.incoming_balance - oMonthPeriod.amortization > 0) {
            oMonthPeriod.amortization = phase.amortizationPerMonth
            oMonthPeriod.outgoing_balance = oMonthPeriod.incoming_balance - oMonthPeriod.amortization
          } else {
            oMonthPeriod.amortization = phase.incoming_balance
            oMonthPeriod.outgoing_balance = 0
          }

          if (oMonthPeriod.outgoing_balance !== 0 || oMonthPeriod.incoming_balance !== 0) {
            this.aOutputData.monthly_data.push(oMonthPeriod)
          }

          iIncomingBalance = oMonthPeriod.outgoing_balance

          month = month.add(1, 'months')
        }
      })

      let oYearPeriod = {}
      let row = {}
      for (let index = 0; index < this.aOutputData.monthly_data.length; index++) {
        row = this.aOutputData.monthly_data[index]
        if (index !== 0 && row.month === 1) {
          // On new year if it is not a first element: put previous year to result
          this.aOutputData.annual_data.push(oYearPeriod)
        }

        if (index === 0 || row.month === 1) {
          // First element or new year: create year object
          oYearPeriod = { ...oPeriodTemplateData }
          oYearPeriod.year = row.year
          oYearPeriod.months = []
          oYearPeriod.incoming_balance = row.incoming_balance
        }

        oYearPeriod.months[row.month - 1] = row
        oYearPeriod.amortization += row.amortization
        oYearPeriod.interest += row.interest
        oYearPeriod.outgoing_balance = row.outgoing_balance
      }
      this.aOutputData.annual_data.push(oYearPeriod)

      this.$emit('simulation-data', { data: this.aOutputData })
      console.log(this.aOutputData)
    },
    exportMonthlyValues () {
      let sData = this.$t(this.translationPath + 'params.export_csv_column_names_liability') + '\n'
      let oMonthData = null

      for (const idx in this.aOutputData.monthly_data) {
        oMonthData = this.aOutputData.monthly_data[idx]

        sData += oMonthData.year + ';'
        sData += oMonthData.month + ';'
        sData += oMonthData.incoming_balance + ';'
        sData += oMonthData.amortization + ';'
        sData += oMonthData.outgoing_balance + ';'
        sData += Math.round(oMonthData.interest)
        sData += '\n'
      }

      const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-simulation-liability-' + this.account.id + '.csv')
      document.body.appendChild(link)
      link.click()
    },
    validationCssClass (field = null) {
      if (field === null) {
        return {}
      }

      return {
        'is-invalid': field.$dirty && field.$error,
        'is-valid': field.$dirty && !field.$error
      }
    }
  }
}
</script>

<style lang="scss">
.component-balance-account-report-budget-simulation-view {
  .simulation-result-table {
    th {
      border-top: none;
    }
    td {
      font-size: 95%;
    }
  }
  #import_textarea{
    height: 400px;
  }
  .budgets-select {
    width: auto;
  }
}
</style>
