<template>
  <div class="component-performance-account-report-transactions-table">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title', {account: account.title, start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <b-link to="/transactions/create/single" class="btn btn-sm btn-outline-secondary mr-3">
                    <i class="flaticon solid plus-1 text-secondary"></i>{{ $t('common.header.header_menu.transactions.add_single')}}
                  </b-link>
                  <b-link to="/transactions/import/all" class="btn btn-sm btn-outline-secondary mr-3">
                    <i class="flaticon solid plus-1 text-secondary"></i>{{ $t(translationPath + 'import_transactions')}}
                  </b-link>
                </b-col>
              </b-row>
            </template>

            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_period_table_total', {transaction_count: this.apiData.meta.transaction_count, start_date: startDate, end_date: endDate})"></div>

            <b-table
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              foot-clone
              no-footer-sorting
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :emptyFilteredText ="$t('common.no_filter_result')"
              :emptyText="$t(translationPath + 'misc.no_data')"
              :filter="sTableFilter"
              :filter-included-fields="['date','title']"
              :tbodyTrClass="trClass"
              :per-page="iPaginationPerPage"
              :current-page="iPaginationCurrentPage"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- FILTER -->
              <template slot="top-row">
                <td :colspan="this.tableColumns.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <template v-slot:head(actions)>
                <b-link
                  class="text-red"
                  :title="$t(translationPath+'edit_multiple_description')"
                  :to="'/transactions/view?account_id='+account.id+'&start_date='+startDate+'&end_date='+endDate">{{$t(translationPath + 'edit_multiple')}}</b-link>
              </template>

              <!-- date slot-->
              <template v-slot:cell(date)="row">
                  <span class="text-nowrap">{{row.value}}</span>
              </template>

              <!-- title slot-->
              <template v-slot:cell(title)="row">
                <template v-if="row.item.row_type==='transaction'">
                  <b-link :href="'/transactions/view/transaction/'+row.item.id" @click.prevent="navigateToTransaction(row.item.id)">{{row.value}}</b-link>
                </template>
                <template v-else>
                  {{row.value}}
                </template>
              </template>

              <!-- amount slot-->
              <template v-slot:cell(amount)="row">
                <template v-if="row.item.row_type==='transaction'">
                  <span :class="'transaction_' + row.item.transaction_subtype" class="text-nowrap">{{row.value}}</span>
                </template>
                <template v-else>
                  {{row.value}}
                </template>
              </template>

              <!-- subtype_description slot-->
              <template v-slot:cell(subtype_description)="row">
                <template v-if="row.item.row_type==='transaction'">
                  <i18n :path="'common.transaction_subtypes.' +row.item.transaction_subtype" tag="span" class="text-gray" style="font-size: 90%">
                    <template v-slot:cr_account v-if="Object.prototype.hasOwnProperty.call(row.item,'cr_entry') && row.item.cr_entry">
                      <b-link :href="'/reports/performance/accounts/' +row.item.cr_entry.account_id +'/view'" class="subtype_description_link" @click.native="(event) => onAccountLinkClick(row.item.cr_entry, event)">{{ currentCOA.prefix_account_title_with_code ? row.item.cr_entry.account_code + ' - ' + row.item.cr_entry.account_title : row.item.cr_entry.account_title }}</b-link>
                    </template>
                    <template v-slot:dr_account v-if="Object.prototype.hasOwnProperty.call(row.item,'dr_entry') && row.item.dr_entry">
                      <b-link :href="'/reports/performance/accounts/' +row.item.dr_entry.account_id +'/view'" class="subtype_description_link" @click.native="(event) => onAccountLinkClick(row.item.dr_entry, event)">{{ currentCOA.prefix_account_title_with_code ? row.item.dr_entry.account_code + ' - ' + row.item.dr_entry.account_title : row.item.dr_entry.account_title }}</b-link>
                    </template>
                  </i18n>
                </template>
              </template>

              <!-- actions -->
              <template v-slot:cell(actions)="row">
                <template v-if="row.item.row_type==='transaction'">
                  <div class="text-right">
                    <b-link href="#" @click="deleteTransaction(row.item)" class="mr-3 delete-link" :title="$t('reports.balance.account.transactions.delete_transaction')">
                      <i class="flaticon stroke trash-3"></i>
                    </b-link>
                    <b-link :href="'/transactions/view/transaction/'+row.item.id" @click.prevent="navigateToTransaction(row.item.id)" class="mr-2 open-link" :title="$t('reports.balance.account.transactions.open_transaction')">
                      <i class="flaticon solid right-circle-2 "></i>
                    </b-link>
                  </div>
                </template>
              </template>

              <!-- END CUSTOM SLOTS -->

              <!-- TFOOT -->
              <template v-slot:foot(date)>&nbsp;</template>
              <template v-slot:foot(title)>
                {{ $t('common.sum') }}
              </template>
              <template v-slot:foot(amount)>
                <span class="text-nowrap" v-if="isLoaded">{{ amountFormatter(apiData.totals.amounts.sum) }}</span>
              </template>
              <template v-slot:foot(subtype_description)><span class="of_which" v-if="isLoaded">{{$t(translationPath + 'detailed_sums',{income_sum: amountFormatter(apiData.totals.amounts.sum_income), expense_sum: amountFormatter(apiData.totals.amounts.sum_expense), other_sum: amountFormatter(apiData.totals.amounts.sum_other)})}}.</span></template>
              <template v-slot:foot(actions)>&nbsp;</template>
            </b-table>

            <!-- PAGINATION -->
            <div class="pagination-wrapper" v-if="this.isLoaded">
              <div class="d-flex  flex-column flex-md-row justify-content-between">
                <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
                  <div v-if="iPaginationTotalRows > 0" class="d-flex align-items-center justify-content-center show-per-page-wrapper">
                    <span class="text-gray">{{ $t('common.pagination.showing')}} {{(iPaginationCurrentPage - 1) * iPaginationPerPage + 1}} {{ $t('common.pagination.to')}} {{ paginationToNumber() }} {{ $t('common.pagination.of')}} {{iPaginationTotalRows}}</span>
                  </div>
                  <div v-if="iPaginationTotalRows > 0" class="space-divider"></div>
                  <div class="d-flex align-items-center justify-content-center show-per-page-wrapper">
                    <span class="text-gray">{{ $t('common.pagination.show_per_page')}}: </span> <b-form-select :options="aPaginationShowPerPageOptions" v-model="iPaginationPerPage" class="selectPerPage" />
                  </div>
                  <div class="space-divider"></div>
                  <div class="d-flex align-items-center justify-content-center show-per-page-wrapper">
                    <b-link href="#" @click="exportTransactions()" class="text-gray">{{$t('reports.balance.account.transactions.export_transactions', {transaction_count: this.apiData.meta.transaction_count})}}</b-link>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center pagination-pages-wrapper">
                  <b-pagination v-if="iPaginationTotalRows > 0" :total-rows="iPaginationTotalRows" :per-page="( iPaginationPerPage)" :prev-text="$t('common.pagination.prev')" :next-text="$t('common.pagination.next')" v-model="iPaginationCurrentPage" class="my-0"/>
                </div>
              </div>
            </div>
            <!-- END PAGINATION -->

          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <delete-transaction-modal
      ref="DeleteTransactionModal"
      :itemName="transactionToDelete ? transactionToDelete.title : ''"
      @on-delete-cancel="transactionToDelete = null"
      @on-delete-confirm="doDeleteTransaction"
    />
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import axios from 'axios'
import { mapState } from 'vuex'
import DeleteTransactionModal from '@/components/modals/DeleteTransactionModal'
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'

export default {
  name: 'PerformanceAccountReportV2TransactionsTable',
  components: { Loader, DeleteTransactionModal },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showChart: 'bar-chart',
      translationPath: 'reports.performance.account.transactions.',
      tableData: [],
      tableColumns: [],
      sTableFilter: '',
      transactionToDelete: null,
      iPaginationCurrentPage: 1,
      iPaginationPerPage: 50,
      iPaginationTotalRows: 0,
      aPaginationShowPerPageOptions: [25, 50, 100, 250, 500]
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []

      if (!this.apiData) {
        return rows
      }

      if (this.apiData) {
        this.apiData.transactions.map(oTransaction => {
          const oRowTmp = {
            id: oTransaction.id,
            date: oTransaction.date,
            title: oTransaction.title,
            amount: oTransaction.amount,
            amount_acc: oTransaction.amount_acc,
            dr_entry: oTransaction.dr_entries[0],
            cr_entry: oTransaction.cr_entries[0],
            description: oTransaction.description,
            class: 'text-right td-amount',
            transaction_subtype: oTransaction.transaction_subtype,
            row_type: 'transaction'
          }

          rows.push(oRowTmp)
        })
      }

      this.iPaginationTotalRows = this.apiData.meta.transaction_count + 2

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'date', label: this.$t(this.translationPath + 'table.date'), sortable: true },
        { key: 'title', label: this.$t(this.translationPath + 'table.title'), sortable: true },
        { key: 'amount', label: this.$t(this.translationPath + 'table.amount'), sortable: true, formatter: this.amountFormatter, class: 'text-right' },
        { key: 'amount_acc', label: this.$t(this.translationPath + 'table.amount_acc'), sortable: true, formatter: this.amountFormatter, class: 'text-right' },
        { key: 'subtype_description', label: this.$t(this.translationPath + 'table.subtype_description') },
        { key: 'actions', label: '', class: 'text-right' }
      ]

      return cols
    },
    exportTransactions () {
      let sData = 'Date;Title;Amount;TransactionType;CreditAccount;DebitAccount' + '\n'
      let t = null

      for (const idx in this.apiData.transactions) {
        t = this.apiData.transactions[idx]
        sData += t.date + ';"' + t.title + '";' + t.amount + ';' + t.transaction_subtype + ';"' + t.cr_entries[0].account_title + '";"' + t.dr_entries[0].account_title + '"' + '\n'
      }

      const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-transactions.csv')
      document.body.appendChild(link)
      link.click()
    },
    // Calculate X for label "showing 1 to X"
    paginationToNumber () {
      let iToNumber = this.iPaginationCurrentPage * this.iPaginationPerPage
      if (iToNumber > this.iPaginationTotalRows) {
        iToNumber = this.iPaginationTotalRows
      }
      return iToNumber
    },
    deleteTransaction (transaction) {
      this.transactionToDelete = transaction
      this.$refs.DeleteTransactionModal.show()
    },
    doDeleteTransaction () {
      this.$refs.DeleteTransactionModal.hide()
      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/${this.transactionToDelete.id}`)
        .then(response => {
          this.tableData = this.tableData.filter(t => t.id !== this.transactionToDelete.id)
          this.transactionToDelete = null
          this.$bvToast.toast(this.$t(this.translationPath + 'toasts.delete_transaction_success').toString(), {
            title: this.$t(this.translationPath + 'toasts.delete_transaction_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toasts.delete_transaction_fail').toString(), {
            title: this.$t(this.translationPath + 'toasts.delete_transaction_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    navigateToTransaction (id) {
      const params = Object.assign({}, {
        transaction_id: id,
        back_url: '/reports/performance/accounts/' + this.$route.params.account_id + '/view/transactions/?start_date=' + this.startDate + '&end_date=' + this.endDate,
        back_url_title: this.$t('reports.balance.account.redirect_back_to', { account_name: this.account.title })
      })
      this.$router.push({ name: 'transactions-create-single', params })
    },
    onAccountLinkClick (accountData, event) {
      event.preventDefault()
      this.$emit('account-changed', accountData)
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-account-report-transactions-table{
  .open-link{color:#74788D}
  .open-link:hover{text-decoration: none;color:#8cccad}

  .delete-link{color:#74788D}
  .delete-link:hover{text-decoration: none;color:#dda3a2}

  .subtype_description_link{
    color:#84888F;
    text-decoration: underline;
  }
  .subtype_description_link:hover{
    color:#d38786;
  }

  .pagination-wrapper{
    .show-per-page-wrapper{font-size:90%}
  }

  tfoot {
    th {
      border-top: 1px solid black;
      border-bottom: 2px solid black;
      padding: 6px 10px;
    }
    .of_which{
      font-weight: normal;
      font-size:90%;
    }
  }
}
</style>
