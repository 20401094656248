<template>
  <b-container fluid class="component-balance-account-report-closing-balance-full-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'balance-account-report-closing-balance-full-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceAccountReportClosingBalanceFullBarChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.account.chart'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []
      this.apiData.meta.periods.map(period => {
        labels.push(this.getTimeLabelFromPeriodLabel(period, this.resolution))
      })

      const sAccountTitle = this.account.label
      const suffix = ' ' + this.currencySymbol

      this.chartOptions.legend.display = false
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return sAccountTitle + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const sBackgroundColor = this.getYellow() // this.account.type === 'income' ? this.getGreen() : this.getRed()
      const dataset = { backgroundColor: sBackgroundColor, type: 'bar', data: [] }

      for (const period in this.apiData.meta.periods) {
        dataset.data.push(this.apiData.periods.amounts.closing_balances[this.apiData.meta.periods[period]])
      }

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    }
  }
}
</script>
