<template>
  <b-modal
    id="excel-data-error-modal"
    centered
    scrollable
    size="xl"
    ref="ExcelDataErrorModal"
    modal-class="spirecta-modal"
    body-text-variant="danger"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">{{ $t(translationPath + 'excel_errors_modal_title') }}</template>
    <p v-for="(errorMsg, index) in errors" :key="index" class="mb-0 font-weight-lighter"><strong>{{ errorMsg }}</strong></p>
    <template slot="modal-footer" class="text-center">
      <b-button variant="danger" @click="onOk">{{ $t('common.ok') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ExcelDataErrorModal',
  props: {
    errors: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.account.budget_simulation.'
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.ExcelDataErrorModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.ExcelDataErrorModal.hide()
      this.$emit('hide')
    },
    onOk () {
      this.$emit('ok')
      this.hide()
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
