<template>
  <b-container fluid class="component-balance-account-report-change-distribution-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'balance-account-report-change-distribution-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'BalanceAccountReportChangeDistributionBarChart',
  props: {
    apiData: { type: Object },
    account: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  mixins: [chartOptions, defaultChartColorPalettes],
  components: {
    BarChart
  },
  data () {
    return {
      translationPath: 'reports._common.distribution.',
      chartData: {}
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }

      const cangeLabel = this.$t(this.translationPath + 'change')
      const balanceInLabel = this.$t(this.translationPath + 'balance_in')
      const balanceOutLabel = this.$t(this.translationPath + 'balance_out')
      const suffix = ' ' + this.currencySymbol

      this.chartOptions.legend.display = false
      /* Modify Y-mouse over */
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      /* Modify the tooltip onmouseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        let lblRowBalanceIn = ''
        let lblRowBalanceOut = ''
        let lblRowBalanceChange = ''
        let aTooltip = []

        // Offset makes yLabel a string that looks like an array
        if (tooltipItem.yLabel[0] === '[') {
          const tmpArray = JSON.parse(tooltipItem.yLabel)

          lblRowBalanceIn = balanceInLabel + ': ' + formatNumberToFull(tmpArray[1]) + suffix
          lblRowBalanceOut = balanceOutLabel + ': ' + formatNumberToFull(tmpArray[0]) + suffix
          lblRowBalanceChange = cangeLabel + ': ' + formatNumberToFull(tmpArray[0] - tmpArray[1]) + suffix

          aTooltip = [lblRowBalanceIn, lblRowBalanceChange, lblRowBalanceOut]
        } else {
          aTooltip = [balanceInLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix]
        }

        return aTooltip
      }

      const labelData = []
      const transactionData = []
      const colorData = []
      const isAsset = this.apiData.account.type === 'asset'
      let yMinValue = Number.MAX_SAFE_INTEGER

      const positiveColor = isAsset ? this.getGreen() : this.getRed()
      const negativeColor = isAsset ? this.getRed() : this.getGreen()

      // Incoming balance
      transactionData.push(Math.round(this.apiData.distribution.balance.incoming))
      labelData.push([this.$t(this.translationPath + 'incoming_balance_abbr'), this.apiData.meta.start_date])
      colorData.push(this.getYellow())

      // Calc offset
      var offset = Math.round(this.apiData.distribution.balance.incoming)

      yMinValue = offset < yMinValue ? offset : yMinValue

      for (var index in this.apiData.distribution.change) {
        transactionData.push([Math.round(this.apiData.distribution.change[index].sum) + offset, offset])
        offset += Math.round(this.apiData.distribution.change[index].sum)

        yMinValue = offset < yMinValue ? offset : yMinValue

        colorData.push(Math.round(this.apiData.distribution.change[index].sum) >= 0 ? positiveColor : negativeColor)
        labelData.push(this.$t(this.translationPath + 'tbl_transactions.' + index + '_abbr'))
      }

      transactionData.push(Math.round(this.apiData.distribution.balance.outgoing))
      labelData.push([this.$t(this.translationPath + 'outgoing_balance_abbr'), this.apiData.distribution.period.end_date])
      colorData.push(this.getYellow())

      // "Zoom in" by not startin y-scale at 0
      if (yMinValue > 0) {
        this.chartOptions.scales.yAxes[0].ticks.min = (yMinValue * 0.75).toFixed(0)
      }

      const dataTransactionValues = {
        label: this.$t('common.amount'),
        data: transactionData,
        borderColor: '#8cccad',
        backgroundColor: colorData,
        hoverBackgroundColor: '#ACACAC'
      }

      this.chartData = {
        labels: labelData,
        datasets: [dataTransactionValues]
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
</style>
