<template>
  <b-container fluid class="component-reports-performance-accountv2-active-passive-percentage-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-accountv2-active-passive-percentage-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'AccountReportPeriodActivePassivePercentageAreaChart',
  components: { LineChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.account.charts.active_passive.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []
      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))
      })

      const aIncomeColors = this.getGreenTwoColors()
      const aExpenseColors = this.getRedTwoColors()
      const suffix = ' %'
      const self = this

      this.chartOptions.legend.display = true
      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.yAxes[0].ticks = {
        callback: function (value, index, values) {
          return formatNumberToFull(value) + ' %'
        }
      }
      this.chartOptions.scales.xAxes[0].stacked = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        const sLabel = tooltipItem.datasetIndex === 0 ? self.$t(self.translationPath + 'active_percentage') : self.$t(self.translationPath + 'passive_percentage')
        return sLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const datasetActive = { backgroundColor: this.account.type === 'income' ? aIncomeColors[0] : aExpenseColors[0], data: [], stacked: true, label: this.$t(this.translationPath + 'active_percentage') }
      const datasetPassive = { backgroundColor: this.account.type === 'income' ? aIncomeColors[1] : aExpenseColors[1], data: [], stacked: true, label: this.$t(this.translationPath + 'passive_percentage') }
      let iTotal = 0
      let fPercentage = 0

      for (const period in this.apiData.periods.amounts) {
        iTotal = parseInt(this.apiData.periods.active_amounts[period]) + parseInt(this.apiData.periods.passive_amounts[period])
        fPercentage = iTotal !== 0 ? 100 * (this.apiData.periods.active_amounts[period].toFixed(0) / iTotal) : 0

        datasetActive.data.push(fPercentage.toFixed(0))
        datasetPassive.data.push((100 - fPercentage).toFixed(0))
      }

      this.chartData = {
        labels: labels,
        datasets: [datasetActive, datasetPassive]
      }
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
