<template>
  <div class="component-balance-account-report-distribution-table">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title', {account: account.title, start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <template v-if="isLoaded && account.allow_update">
                    <b-link class='btn btn-sm icon-btn text-regular btn-outline-primary actionButtons float-right ml-3' :to="'/assets-liabilities/update-value/'+account.type+'/'+account.id">
                      <i class="flaticon solid text-primary refresh"></i>
                      {{ $t('reports.other.account_plan.update_value')}}
                    </b-link>
                  </template>
                  <template v-if="isLoaded && apiData.meta.transaction_count === 0">
                    <b-link class='btn btn-sm icon-btn text-regular btn-outline-primary actionButtons float-right ml-3' :to="'/assets-liabilities/update-value/'+account.type+'/'+account.id">
                      <i class="flaticon solid text-primary money"></i>
                      {{ $t('reports.other.account_plan.set_starting_value')}}
                    </b-link>
                  </template>
                </b-col>
              </b-row>
            </template>

            <div class="col-md-9 pl-0" v-html="$t(translationPath + 'description_distribution_table_total', {title: account.label})"></div>

            <b-table
              class="spirecta-simple-table mb-3"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              foot-clone
              no-footer-sorting
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- amounts slot-->
              <template v-slot:cell(period_change)="row">
                <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+startDate+'&end_date='+endDate+'&transaction_subtype='+row.item.transaction_subtype"><span :class="row.unformatted < 0 ? 'text-red': ''">{{row.value}}</span></b-link>
              </template>

              <!-- amounts slot-->
              <template v-slot:cell(count_transaction)="row">
                <b-link :to="'/transactions/view?account_id='+account.id+'&start_date='+startDate+'&end_date='+endDate+'&transaction_subtype='+row.item.transaction_subtype">{{row.value}}</b-link>
              </template>

              <!-- END CUSTOM SLOTS -->

              <!-- TFOOT -->
              <template v-slot:foot(title)><span class="pl-2">{{ $t('reports.balance.common.period_change') }}</span></template>
              <template v-slot:foot(period_change) v-if="isLoaded">{{ amountFormatter(apiData.distribution.totals.difference) }}</template>
              <template v-slot:foot(count_transaction) v-if="isLoaded">{{ apiData.distribution.totals.transaction_count }}</template>

            </b-table>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'

export default {
  name: 'BalanceAccountReportDistributionTable',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          id: '',
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showChart: 'closing-balance-full-bar-chart',
      translationPath: 'reports.balance.account.',
      tableData: [],
      tableColumns: [],
      toggleColumnsOptions: {
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []
      if (!this.apiData) {
        return rows
      }

      if (this.apiData) {
        for (const sType in this.apiData.distribution.change) {
          const oRowTmp = {
            title: this.$t('reports._common.distribution.tbl_transactions.' + sType),
            period_change: this.apiData.distribution.change[sType].sum,
            count_transaction: this.apiData.distribution.change[sType].count,
            transaction_subtype: sType,
            row_type: 'data'
          }

          rows.push(oRowTmp)
        }
      }

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'title', label: this.$t(this.translationPath + 'table.period') },
        { key: 'count_transaction', label: this.$t(this.translationPath + 'count_transactions'), class: 'text-right' },
        { key: 'period_change', label: this.$t('reports.balance.common.period_change'), class: 'text-right', formatter: this.amountFormatter }
      ]

      return cols
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-balance-account-report-distribution-table{
  tfoot {
    th {
      border-top: 1px solid black;
      border-bottom: 2px solid black;
      padding: 6px 10px;
    }
  }
}
</style>
