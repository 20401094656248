<template>
  <b-container fluid class="component-account-report-period-sum-acc-area-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'account-report-period-sum-acc-area-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'AccountReportPeriodSumAccAreaChart',
  components: { LineChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.account.chart.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const labels = []
      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))
      })

      const sAccountTitle = this.account.label + ' (' + this.$t('common.acc_abbr') + ')'
      this.chartOptions.legend.display = false
      const suffix = ' ' + this.currencySymbol
      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return sAccountTitle + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const sBackgroundColor = this.account.type === 'income' ? this.getGreen() : this.getRed()
      const dataset = { backgroundColor: sBackgroundColor, data: [] }

      let iSum = 0
      for (const period in this.apiData.periods.amounts) {
        iSum += this.apiData.periods.amounts[period]
        dataset.data.push(iSum)
      }

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
