// const _ = require('lodash')
const getSymbolFromCurrency = require('currency-symbol-map')
export default function formatAmount (amount, locale, currency, currencySymbol, showCurrencyLabel, decimalPlaces) {
  // return if locale and/or currency is not set, ie. either null or undefined
  if (!locale || !currency) return false
  const defaultDecimalPlaces = typeof decimalPlaces !== 'undefined' && typeof decimalPlaces === 'number' ? { maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces } : { minimumFractionDigits: 2 }
  const displayCurrency = typeof showCurrencyLabel !== 'boolean' ? true : showCurrencyLabel
  const currencyNotation = currencySymbol ? getSymbolFromCurrency(currencySymbol) : 'kr'
  if (isFinite(amount)) {
    if (currencyNotation && displayCurrency) {
      return Number(amount).toLocaleString(locale, defaultDecimalPlaces) + ' ' + currencyNotation
    } else {
      return Number(amount).toLocaleString(locale, defaultDecimalPlaces)
    }
  }
  return amount
}
