<template>
  <b-container fluid class="component-reports-performance-accountv2-budget-diff-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-accountv2-budget-diff-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'AccountReportPeriodBudgetDiffBarChart',
  components: { BarChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.account.chart'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []
      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))
      })

      const sAccountTitle = this.account.label
      const suffix = ' ' + this.currencySymbol
      this.chartOptions.legend.display = false
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return sAccountTitle + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const dataset = { type: 'bar', data: [] }
      const aBackgrounColors = []

      for (const period in this.apiData.periods.budget_diff) {
        if (this.apiData.periods.budget_diff_positive[period]) {
          dataset.data.push(this.apiData.periods.budget_diff[period])
          aBackgrounColors.push(this.getGreen())
        } else {
          dataset.data.push(this.apiData.periods.budget_diff[period])
          aBackgrounColors.push(this.getRed())
        }
      }
      dataset.backgroundColor = aBackgrounColors

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      immediate: true,
      deep: true
    }
  }
}
</script>
