<template>
  <b-container fluid class="component-reports-performance-accountv2-offset-accounts-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-accountv2-offset-accounts-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
// import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'AccountReportPeriodOffsetAccountsBarChart',
  components: { BarChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.account.charts.active_passive.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []
      const aDatasets = []
      const aColorPalette = this.getChartPalette()
      const suffix = ' ' + this.currencySymbol

      this.chartOptions.legend.display = true
      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.xAxes[0].stacked = true

      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))
      })

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        console.log(tooltipItem)
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      for (const iOffsetAccountIdx in this.apiData.stats.offset_accounts) {
        const oAcct = this.apiData.stats.offset_accounts[iOffsetAccountIdx]
        const oTmpDataset = { backgroundColor: aColorPalette.shift(), data: [], stacked: true, label: oAcct.title }

        for (const period in this.apiData.periods.amounts) {
          oTmpDataset.data.push(oAcct.periods[period])
        }

        aDatasets.push(oTmpDataset)
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
