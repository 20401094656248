const setPageTitle = (pageTitle) => {
  const sPrefix = process.env.VUE_APP_TITLE === 'Dev' ? process.env.VUE_APP_TITLE + ' | ' : ''
  window.document.title = sPrefix + pageTitle + ' | Spirecta'
}

export default {
  methods: {
    setPageTitle: setPageTitle
  }
}
