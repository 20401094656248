<template>
  <div class="report-filter">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <h2>{{ $t(translationPath + 'common.title') }}</h2>
              <div class="col-lg-8 px-0 mt-3 text-regular" v-html="$t(translationPath + 'common.description')"></div>
            </template>

            <b-card no-body class="white-tabs-card px-0 mx-0">
              <b-tabs class="white-tabs filter-tabs-wrapper" v-model="tabIndex">
                <!-- SAVED FILTERS TAB -->
                <b-tab :title="$t(translationPath + 'common.tab_saved_filters')" class="px-3">
                  <b-row class="pt-3 mb-5">
                    <b-col class="col-lg-8">

                      <b-form-group
                        id="filter_saved_filters"
                        class="text-regular"
                        :label="this.$t(translationPath + 'common.select_saved_filter_label')"
                      >
                        <b-select id="saved_filters" :options="aFilters" :disabled="!isLoaded" v-model="iFormSelectFilter"></b-select>
                      </b-form-group>

                      <b-button class="btn px-4 mb-5" variant="primary" :disabled="!iFormSelectFilter" @click="emitFilter(false)">{{ $t(translationPath + 'common.btn_reload_report') }}</b-button>

                    </b-col>
                  </b-row>
                </b-tab>

                <!-- CREATE FILTER TAB -->
                <b-tab :title="$t(translationPath + 'common.tab_settings')" class="px-3">
                  <b-row class="pt-3">
                    <b-col class="col-lg-8">
                      <div class="" v-html="$t(translationPath + 'common.create_description')"></div>
                    </b-col>
                  </b-row>

                  <b-row class="py-4">
                    <!-- INCOME -->
                    <b-col class="col-md-6">
                      <h3>{{ $t(translationPath + 'income.title') }}</h3>

                      <b-row class="">
                        <b-col cols="9">
                          <div class="" v-html="$t(translationPath + 'income.description_'+typeOfReport)"></div>
                        </b-col>
                        <b-col class="text-small text-right" align-self="end">
                          <b-link @click="selectAllList('income')">{{$t(translationPath+'common.select_all')}}</b-link> |
                          <b-link @click="clearAllList('income')">{{$t(translationPath+'common.select_none')}}</b-link>
                        </b-col>
                      </b-row>

                      <b-form-select ref="oFormSelectIncome" v-model="oFormSelectIncome" :options="oFormAccounts.income" :select-size="20" multiple class="filter_select" :disabled="disabled.indexOf('income') !== -1"></b-form-select>

                    </b-col>
                    <!-- EXPENSES -->
                    <b-col class="col-md-6">
                      <h3>{{ $t(translationPath + 'expense.title') }}</h3>

                      <b-row class="">
                        <b-col cols="9">
                          <div class="" v-html="$t(translationPath + 'expense.description_'+typeOfReport)"></div>
                        </b-col>
                        <b-col class="text-small text-right" align-self="end">
                          <b-link @click="selectAllList('expense')">{{$t(translationPath+'common.select_all')}}</b-link> |
                          <b-link @click="clearAllList('expense')">{{$t(translationPath+'common.select_none')}}</b-link>
                        </b-col>
                      </b-row>

                      <b-form-select ref="oFormSelectExpense" v-model="oFormSelectExpense" :options="oFormAccounts.expense" :select-size="20" multiple class="filter_select" :disabled="disabled.indexOf('expense') !== -1"></b-form-select>
                    </b-col>
                  </b-row>

                  <b-row class="py-4">
                    <!-- ASSET -->
                    <b-col class="col-md-6">
                      <h3>{{ $t(translationPath + 'asset.title') }}</h3>

                      <b-row class="">
                        <b-col cols="9">
                          <div class="" v-html="$t(translationPath + 'asset.description_'+typeOfReport)"></div>
                        </b-col>
                        <b-col class="text-small text-right" align-self="end">
                          <b-link @click="selectAllList('asset')">{{$t(translationPath+'common.select_all')}}</b-link> |
                          <b-link @click="clearAllList('asset')">{{$t(translationPath+'common.select_none')}}</b-link>
                        </b-col>
                      </b-row>

                      <b-form-select ref="oFormSelectAsset" v-model="oFormSelectAsset" :options="oFormAccounts.asset" :select-size="20" multiple class="filter_select" :disabled="disabled.indexOf('asset') !== -1"></b-form-select>

                    </b-col>
                    <!-- LIABILITY -->
                    <b-col class="col-md-6">
                      <h3>{{ $t(translationPath + 'liability.title') }}</h3>

                      <b-row class="">
                        <b-col cols="9">
                          <div v-html="$t(translationPath + 'liability.description_'+typeOfReport)"></div>
                        </b-col>
                        <b-col class="text-small text-right" align-self="end">
                          <b-link @click="selectAllList('liability')">{{$t(translationPath+'common.select_all')}}</b-link> |
                          <b-link @click="clearAllList('liability')">{{$t(translationPath+'common.select_none')}}</b-link>
                        </b-col>
                      </b-row>

                      <b-form-select ref="oFormSelectLiability" v-model="oFormSelectLiability" :options="oFormAccounts.liability" :select-size="20" multiple class="filter_select" :disabled="disabled.indexOf('liability') !== -1"></b-form-select>

                    </b-col>
                  </b-row>

                  <!-- PERSON -->
                  <b-row class="py-4 mb-3">
                    <b-col class="col-md-6" v-if="aPersons.length > 1">
                      <h3>{{ $t(translationPath + 'person.title') }}</h3>

                      <b-row class="">
                        <b-col cols="9">
                          <div class="" v-html="$t(translationPath + 'person.description')"></div>
                        </b-col>
                        <b-col class="text-small text-right" align-self="end">
                          <b-link @click="selectAllList('person')">{{$t(translationPath+'common.select_all')}}</b-link> |
                          <b-link @click="clearAllList('person')">{{$t(translationPath+'common.select_none')}}</b-link>
                        </b-col>
                      </b-row>

                      <b-form-select ref="oFormSelectPerson" v-model="oFormSelectPerson" :options="aPersons" :select-size="20" multiple class="filter_select_persons" :disabled="disabled.indexOf('person') !== -1"></b-form-select>

                    </b-col>

                    <!-- SAVE FILTER -->
                    <b-col class="col-md-6">
                      <h3>{{ $t(translationPath + 'common.save_filter_title') }}</h3>
                      <div class="mb-3" v-html="$t(translationPath + 'common.save_filter_description')"></div>

                      <b-form-group
                        id="filter_title"
                        :label="this.$t(translationPath + 'common.filter_title_label')"
                        :invalid-feedback="invalidFilterTitleFeedback"
                      >
                        <b-form-input
                          v-model="oFormFilterTitle"
                          :state="$v.oFormFilterTitle.$dirty ? !$v.oFormFilterTitle.$error : null"
                        />
                      </b-form-group>

                      <b-form-group
                        id="filter_description"
                        :label="this.$t(translationPath + 'common.filter_description_label')"
                      >
                        <b-form-textarea v-model="oFormFilterDescription" rows="3" max-rows="6"></b-form-textarea>
                      </b-form-group>

                      <b-button class="btn px-4 mb-5 float-right ml-3" variant="outline-primary" @click="saveFilter">{{ $t(translationPath + 'common.btn_save_filter') }}</b-button>
                      <b-button v-if="oFilterLoaded" class="btn px-4 mb-5 float-right" variant="outline-danger" @click="onDeleteFilter">{{ $t(translationPath + 'common.btn_delete_filter') }}</b-button>

                    </b-col>
                  </b-row>

                  <b-button class="btn px-4 mb-5" variant="primary" :disabled="!isLoaded" @click="emitFilter(true)">{{ $t(translationPath + 'common.btn_reload_report') }}</b-button>

                </b-tab>
              </b-tabs>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <delete-modal
      ref="DeleteFilterModal"
      :heading="$t(translationPath + 'delete_modal.heading').toString()"
      :message="$t(translationPath + 'delete_modal.text', { name: this.oFormFilterTitle }).toString()"
      @on-delete-confirm="deleteFilter"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import DeleteModal from '@/components/modals/DeleteModal'

const isFilterTitleUnique = getter => function () {
  const titles = []
  this.aFilters.map(filter => {
    if (this.oFilterLoaded && filter.title !== this.oFilterLoaded.title) {
      titles.push(filter.title)
    }
  })

  return titles.indexOf(this.oFormFilterTitle) === -1
}

/**
 * Report Filter component.
 * Shows form with accounts lists and persons list. User can select different items and apply filter.
 * On appying, filter emits data to parent component. This data can be used in parent component (report).
 * Filter provides ability to save/load selections. User can name filter, add description and save it to
 * database.
 *
 * When component is created it requests API to load accounts, persons and saved filters. Take a note that
 * component provide a posibility to do it only ones. When component state is loaded or changed it emits data
 * in filter-data-updated event. This data can be stored in parent component and passed back to component
 * as filter-data property. In created hook if this proiperty is no null - data will be copied from property
 * instead of API. It is useful when filter is used as tab. When user change tab component is being destroyed.
 * But when user returns to tab with filter it is not good to load all data again. We can restore them from
 * saved state.
 */
export default {
  name: 'ReportFilter',
  components: { DeleteModal },
  props: {
    /**
     * Saved filter state - all loaded lists, titles, description and applied user selections.
     */
    filterData: {
      type: Object
    },
    /**
     * Disable it if report use own toast message.
     */
    showToastOnFilterApply: {
      type: Boolean,
      default: true
    },
    /**
     * Will control some translation strings since the text should be different if it's included in
     * a PerformanceReport or BalanceReport.
     */
    sInWhatTypeOfReportIsTheFilterIncludedIn: {
      type: String,
      default: 'PerformanceReport'
    },

    cacheState: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      translationPath: 'reports._common.filter.',
      isLoaded: false,
      oFormAccounts: { income: [], expense: [], asset: [], liability: [] },
      aPersons: [],
      aFilters: [{ text: '', value: 0 }],
      tabIndex: 1,
      iFormSelectFilter: 0,
      oFilterLoaded: null,
      oFormSelectIncome: [],
      oFormSelectExpense: [],
      oFormSelectAsset: [],
      oFormSelectLiability: [],
      oFormSelectPerson: [],
      oFormFilterTitle: '',
      oFormFilterDescription: ''
    }
  },
  validations: {
    oFormFilterTitle: { required, minLength: minLength(2), maxLength: maxLength(191), isFilterTitleUnique: isFilterTitleUnique() }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    invalidFilterTitleFeedback () {
      if (this.$v.oFormFilterTitle.required === false) return this.$t(this.translationPath + 'errors.filter_title_required')
      if (this.$v.oFormFilterTitle.minLength === false) return this.$t(this.translationPath + 'errors.filter_title_minlength')
      if (this.$v.oFormFilterTitle.maxLength === false) return this.$t(this.translationPath + 'errors.filter_title_maxlength')
      if (this.$v.oFormFilterTitle.isFilterTitleUnique === false) return this.$t(this.translationPath + 'errors.filter_title_not_unique')
      return ''
    },
    typeOfReport () {
      let type = ''
      switch (this.sInWhatTypeOfReportIsTheFilterIncludedIn) {
        case 'PerformanceReport':
          type = 'performance_report'
          break
        case 'BalanceReport':
          type = 'balance_report'
          break
        case 'ProductiveAssetsBalanceReport':
          type = 'productive_assets_balance_report'
          break
        default:
          type = 'balance_report'
      }
      return type
    }
  },
  methods: {
    /**
     * Restore user selections from saved state (from this.filterData prop)
     */
    restoreFilterData () {
      if (!this.filterData) {
        return null
      }
      for (const optName in this.filterData) {
        this[optName] = this.filterData[optName]
      }
    },
    /**
     * Send current selections to parent component
     */
    async emitFilter (bResetLoadedFilter = false) {
      if (bResetLoadedFilter) {
        this.iFormSelectFilter = 0
        this.oFilterLoaded = null
      }

      if (this.tabIndex === 0) {
        await this.loadFilter(this.iFormSelectFilter)
      } else {
        // this.iFormSelectFilter = 0
        // this.oFilterLoaded = null
      }

      const filterData = {
        oFormSelectIncome: this.oFormSelectIncome,
        oFormSelectExpense: this.oFormSelectExpense,
        oFormSelectAsset: this.oFormSelectAsset,
        oFormSelectLiability: this.oFormSelectLiability,
        oFormSelectPerson: this.oFormSelectPerson,
        oFormFilterTitle: this.oFormFilterTitle,
        oFormFilterDescription: this.oFormFilterDescription,
        iFormSelectFilter: this.iFormSelectFilter,
        oFilterLoaded: this.oFilterLoaded
      }
      const oSavedState = JSON.parse(window.localStorage.ReportFilterData)
      oSavedState[this.$route.name] = filterData
      window.localStorage.ReportFilterData = JSON.stringify(oSavedState)
      this.$emit('filter-data-updated', filterData)

      const sRequestString = '&filter_accounts_income=' + this.oFormSelectIncome.join() +
        '&filter_accounts_expense=' + this.oFormSelectExpense.join() +
        '&filter_accounts_asset=' + this.oFormSelectAsset.join() +
        '&filter_accounts_liability=' + this.oFormSelectLiability.join() +
        '&filter_persons=' + this.oFormSelectPerson.join()
      this.$emit('filter-applied', sRequestString)

      if (this.showToastOnFilterApply) {
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.filter_applied_description').toString(), {
          title: this.$t(this.translationPath + 'toast.filter_applied_title').toString(),
          variant: 'success',
          solid: true,
          'auto-hide-delay': 3000
        })
      }
    },
    /**
     * Load all saved filters, except their selections (selections are saved in
     * filter_json field which can be loaded in loadFilter() method).
     */
    async loadFiltersList () {
      return new Promise((resolve, reject) => {
        this.aFilters = [{ text: '', value: 0 }]
        axios.get(`${process.env.VUE_APP_ROOT_API}/reports/accounts/filter`)
          .then(response => {
            const oApiDataFilters = response.data.data
            for (const iFilterIndex in oApiDataFilters) {
              this.aFilters.push({
                text: oApiDataFilters[iFilterIndex].title + (oApiDataFilters[iFilterIndex].description !== null ? ' - ' + oApiDataFilters[iFilterIndex].description : ''),
                value: oApiDataFilters[iFilterIndex].id
              })
            }
            this.$emit('filter-data-updated', { aFilters: this.aFilters })
            resolve(this.aFilters)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    /**
     * Load specified filter data (full).
     */
    async loadFilter (iFilterId) {
      return new Promise((resolve, reject) => {
        this.oFilterLoaded = null
        axios.get(`${process.env.VUE_APP_ROOT_API}/reports/accounts/filter/${iFilterId}`)
          .then(response => {
            this.oFilterLoaded = response.data.data
            this.oFormFilterTitle = response.data.data.title
            this.oFormFilterDescription = response.data.data.description
            const filter = JSON.parse(response.data.data.filter_json)
            const optNames = ['income', 'expense', 'asset', 'liability', 'person']
            optNames.map(optName => {
              const ucOptName = optName.substr(0, 1).toUpperCase() + optName.substr(1)
              if (!filter[optName]) {
                this['oFormSelect' + ucOptName] = []
              } else {
                this['oFormSelect' + ucOptName] = filter[optName].split(',').map(Number)
              }
            })
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    /**
     * Load data for filter lists and dropdowns.
     */
    async loadFilterData () {
      this.isLoaded = false
      return new Promise((resolve, reject) => {
        Promise.all([
          this.loadFilterDataAccounts(),
          this.loadFilterDataPersons(),
          this.loadFiltersList()
        ])
          .then(() => {
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /**
     * Load all accounts titles and ids.
     */
    async loadFilterDataAccounts () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?include_networth_account=1`)
          .then(response => {
            const oApiData = response.data.data
            let oTmpGroup = {}

            // Iterate groups
            for (const iGroupIndex in oApiData) {
              oTmpGroup = { label: '', options: [] }
              oTmpGroup.label = this.currentCOA.prefix_account_title_with_code ? oApiData[iGroupIndex].title_with_code : oApiData[iGroupIndex].title

              // Iterate accounts in groups s
              for (const iAccountIndex in oApiData[iGroupIndex].accounts) {
                oTmpGroup.options.push({
                  value: oApiData[iGroupIndex].accounts[iAccountIndex].id,
                  text: this.currentCOA.prefix_account_title_with_code ? oApiData[iGroupIndex].accounts[iAccountIndex].title_with_code : oApiData[iGroupIndex].accounts[iAccountIndex].title
                })
              }
              this.oFormAccounts[oApiData[iGroupIndex].type].push({ ...oTmpGroup })
            }
            this.$emit('filter-data-updated', { oFormAccounts: this.oFormAccounts })
            resolve(this.oFormAccounts)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    /**
     * Load all persons.
     */
    async loadFilterDataPersons () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/persons`)
          .then(response => {
            const oApiDataPersons = response.data.data
            for (const iPersonIndex in oApiDataPersons) {
              this.aPersons.push({
                value: oApiDataPersons[iPersonIndex].id,
                text: oApiDataPersons[iPersonIndex].firstname + ' ' + oApiDataPersons[iPersonIndex].lastname
              })
            }
            this.$emit('filter-data-updated', { aPersons: this.aPersons })
            resolve(this.aPersons)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    /**
     * Save current selections to database.
     * If some saved filter are already loaded - it will be updated. New row in the
     * database will be added instead.
     */
    saveFilter () {
      this.$v.oFormFilterTitle.$touch()
      if (this.$v.oFormFilterTitle.$invalid) {
        return false
      }
      if (!this.oFormSelectIncome.length && !this.oFormSelectExpense.length && !this.oFormSelectAsset.length && !this.oFormSelectLiability.length) {
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.filter_selections_required_text').toString(), {
          title: this.$t(this.translationPath + 'toast.filter_selections_required_title').toString(),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        return false
      }

      let sApiMethod = 'post'
      let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/accounts/filter`
      if (this.oFilterLoaded) {
        sApiMethod = 'put'
        sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/accounts/filter/${this.iFormSelectFilter}`
      }
      const aFilterPostData = {
        title: this.oFormFilterTitle,
        description: this.oFormFilterDescription,
        filter_income: this.oFormSelectIncome.join(),
        filter_expense: this.oFormSelectExpense.join(),
        filter_asset: this.oFormSelectAsset.join(),
        filter_liability: this.oFormSelectLiability.join(),
        filter_person: this.oFormSelectPerson.join()
      }
      axios({ method: sApiMethod, url: sApiEndpoint, data: aFilterPostData })
        .then((response) => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.filter_created_description', { title: this.oFormFilterTitle }).toString(), {
            title: this.$t(this.translationPath + 'toast.filter_created_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.iFormSelectFilter = response.data.data.id
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.filter_created_error_description').toString(), {
            title: this.$t(this.translationPath + 'toast.filter_created_error_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
          console.error(error)
        })
        .then(() => {
          this.loadFiltersList().then(() => {
            this.loadFilter(this.iFormSelectFilter).then(() => {
              this.emitFilter()
            })
          })
        })
    },
    /**
     * Show confirm delete modal.
     */
    onDeleteFilter () {
      this.$refs.DeleteFilterModal.show()
    },
    /**
     * Delete loaded filter from database.
     */
    deleteFilter () {
      axios.delete(`${process.env.VUE_APP_ROOT_API}/reports/accounts/filter/${this.iFormSelectFilter}`)
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.filter_deleted_text', { name: this.oFormFilterTitle }).toString(), {
            title: this.$t(this.translationPath + 'toast.filter_deleted_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })

          this.loadFiltersList()
          this.reset()
          this.$emit('filter-data-updated', { aFilters: this.aFilters })
          this.$emit('current-filter-deleted')
        })
        .catch(err => {
          console.error(err)
        })
    },
    /**
     * Reset all selections and inputs.
     */
    reset () {
      const oSavedState = JSON.parse(window.localStorage.ReportFilterData)
      delete oSavedState[this.$route.name]
      window.localStorage.ReportFilterData = JSON.stringify(oSavedState)

      this.oFormSelectIncome = []
      this.oFormSelectExpense = []
      this.oFormSelectAsset = []
      this.oFormSelectLiability = []
      this.oFormSelectPerson = []
      this.oFilterLoaded = null
      this.iFormSelectFilter = null
      this.oFormFilterTitle = ''
      this.oFormFilterDescription = ''
      this.$emit('filter-data-updated', {
        oFormSelectIncome: [],
        oFormSelectExpense: [],
        oFormSelectAsset: [],
        oFormSelectLiability: [],
        oFormSelectPerson: [],
        oFilterLoaded: null,
        iFormSelectFilter: null,
        oFormFilterTitle: '',
        oFormFilterDescription: ''
      })
    },
    /**
     * Select all items in specified sType list.
     */
    selectAllList (sType) {
      const linkName = 'oFormSelect' + sType.substr(0, 1).toUpperCase() + sType.substr(1)
      this[linkName] = []
      if (sType === 'person') {
        this.aPersons.map(option => {
          this[linkName].push(option.value)
        })
      } else {
        this.oFormAccounts[sType].map(group => {
          group.options.map(option => {
            this[linkName].push(option.value)
          })
        })
      }
      this.$refs[linkName].$el.focus()
      return null
    },
    /**
     * Unselect all items in specified sType list.
     */
    clearAllList (sType) {
      this['oFormSelect' + sType.substr(0, 1).toUpperCase() + sType.substr(1)] = []
    }
  },
  /**
   * In created hook we check if filter has previously saved state in parent component.
   * These state should be passed to filter-data property.
   * If no data we load them by calling API.
   */
  async created () {
    if (!window.localStorage.ReportFilterData) {
      window.localStorage.ReportFilterData = JSON.stringify({})
    }

    if (!this.filterData) {
      await this.loadFilterData()
      if (this.cacheState) {
        const oSavedState = JSON.parse(window.localStorage.ReportFilterData)
        if (oSavedState[this.$route.name] && Object.keys(oSavedState[this.$route.name]).length) {
          this.iFormSelectFilter = oSavedState[this.$route.name].iFormSelectFilter
          this.oFormFilterTitle = oSavedState[this.$route.name].oFormFilterTitle
          this.oFormFilterDescription = oSavedState[this.$route.name].oFormFilterDescription
          this.oFilterLoaded = oSavedState[this.$route.name].oFilterLoaded
          this.oFormSelectIncome = oSavedState[this.$route.name].oFormSelectIncome
          this.oFormSelectExpense = oSavedState[this.$route.name].oFormSelectExpense
          this.oFormSelectAsset = oSavedState[this.$route.name].oFormSelectAsset
          this.oFormSelectLiability = oSavedState[this.$route.name].oFormSelectLiability
          this.oFormSelectPerson = oSavedState[this.$route.name].oFormSelectPerson
          this.emitFilter()
        }
      }
    } else {
      this.restoreFilterData()
    }
    this.isLoaded = true
    this.$emit('ready')
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/report-filter.scss'
</style>
