<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object

    /* Hide scales by
    * this.chartOptions.scales.yAxes[0].display = false
    *  this.chartOptions.scales.xAxes[0].display = false
    **/

    this.renderChart(this.chartData, this.options)
  }
}
</script>
