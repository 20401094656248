<template>
  <div class="component-balance-account-report-stats-offset-accounts-table">

    <b-container fluid class="px-0 mt-4 settings-tab">

      <b-table
        class="spirecta-simple-table mb-0"
        show-empty
        hover
        responsive
        striped
        stacked="md"
        :items="tableData"
        :fields="tableColumns"
        :filter="sTableFilter"
        :filter-included-fields="['title','group_title']"
        :busy="!isLoaded"
        :tbodyTrClass="trClass"
      >
        <template v-slot:table-busy>
          <loader/>
        </template>

        <!-- FILTER -->
        <template slot="top-row">
          <td :colspan="this.tableColumns.length">
            <div class="d-flex align-items-center">
              <i class="fa fa-search text-secondary"></i>
              <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
            </div>
          </td>
        </template>

        <!-- title slot-->
        <template v-slot:cell(title)="row">
          <template v-if="row.item.row_type==='transaction'">
            <b-link :to="'/transactions/view?source_account_id='+row.item.offset_account_id+'&start_date='+startDate+'&end_date='+endDate+'&account_id='+account.id">{{row.value}}</b-link>
          </template>
          <template v-else>
            {{row.value}}
          </template>
        </template>

        <!-- group_title slot-->
        <template v-slot:cell(group_title)="row">
            <b-link :to="'/reports/performance/account-groups/'+row.item.group_id">{{row.value}}</b-link>
        </template>

        <!-- sum slot-->
        <template v-slot:cell(sum)="row">
          <template v-if="row.item.row_type==='transaction'">
            <b-link :to="'/transactions/view?source_account_id='+row.item.offset_account_id+'&start_date='+startDate+'&end_date='+endDate+'&account_id='+account.id">{{row.value}}</b-link>
          </template>
          <template v-else-if="row.item.row_type==='total' || row.item.row_type==='subtotal'">
            {{row.value}}
          </template>
        </template>

        <!-- average slot-->
        <template v-slot:cell(average)="row">
          <template v-if="row.item.row_type==='transaction'">
            <b-link :to="'/transactions/view?source_account_id='+row.item.offset_account_id+'&start_date='+startDate+'&end_date='+endDate+'&account_id='+account.id">{{row.value}}</b-link>
          </template>
          <template v-else-if="row.item.row_type==='total'">
            {{row.value}}
          </template>
        </template>

        <!-- average slot-->
        <template v-slot:cell(median)="row">
          <template v-if="row.item.row_type==='transaction'">
            <b-link :to="'/transactions/view?source_account_id='+row.item.offset_account_id+'&start_date='+startDate+'&end_date='+endDate+'&account_id='+account.id">{{row.value}}</b-link>
          </template>
          <template v-else-if="row.item.row_type==='total'">
            {{row.value}}
          </template>
        </template>

        <!-- END CUSTOM SLOTS -->
      </b-table>

    </b-container>

  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'

export default {
  name: 'BalanceAccountReportStatsTableOffsetAccounts',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showChart: 'bar-chart',
      translationPath: 'reports.performance.account.',
      tableData: [],
      tableColumns: [],
      sTableFilter: ''
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const rows = []
      if (!this.apiData) {
        return rows
      }

      if (this.apiData) {
        for (const sType in { income: '', expense: '', asset: '', liability: '' }) {
          if (this.apiData.stats.offset_accounts[sType]) {
            rows.push({
              title: this.$t('common.' + sType + 's'),
              row_type: 'account_type'
            })

            for (const idx in this.apiData.stats.offset_accounts[sType]) {
              const oData = this.apiData.stats.offset_accounts[sType][idx]
              const oRowTmp = {
                title: oData.title,
                count: oData.count,
                average: oData.avg,
                median: oData.median,
                account_type: this.$t('common.' + oData.account_type),
                sum: oData.sum,
                offset_account_id: oData.id,
                group_title: oData.account_group_title,
                group_title_with_code: oData.account_group_code + ' - ' + oData.account_group_title,
                group_id: oData.account_group_id,
                row_type: 'transaction'
              }
              rows.push(oRowTmp)
            }

            rows.push({
              title: this.$t('common.partsum') + ' (' + this.$t('common.' + sType + 's').toString().toLowerCase() + '): ',
              sum: this.apiData.stats.offset_accounts_stats[sType].sum,
              count: this.apiData.stats.offset_accounts_stats[sType].count,
              row_type: 'subtotal'
            })
          }
        }
      }

      rows.push({
        row_type: 'empty'
      })

      rows.push({
        title: this.$t('reports.balance.common.period_change'),
        count: this.apiData.meta.transaction_count,
        sum: this.apiData.periods.amounts.totals.period_change,
        average: this.apiData.stats.transaction_stats.avg,
        median: this.apiData.stats.transaction_stats.median,
        row_type: 'total'
      })

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'title', label: this.$t('common.title'), sortable: false },
        { key: 'group_title', label: this.$t('common.category_group'), sortable: false },
        // { key: 'account_type', label: this.$t('common.account_type'), sortable: true },
        { key: 'count', label: this.$t('common.count'), class: 'text-right', sortable: false },
        { key: 'sum', label: this.$t('common.sum'), class: 'text-right', formatter: this.amountFormatter, sortable: false }
        // { key: 'average', label: this.$t('common.average'), class: 'text-right', formatter: this.amountFormatter, sortable: true },
        // { key: 'median', label: this.$t('common.median'), class: 'text-right', formatter: this.amountFormatter, sortable: true }
      ]

      return cols
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-balance-account-report-stats-offset-accounts-table{
  tr.tr-account-type td{
    background: white;
    padding-top:20px;
    font-weight: bold;
  }
  tr.tr-subtotal td{
    font-weight: bold;
  }
}
</style>
