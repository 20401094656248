import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'SpirectaReportTableMixin',
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    amountFormatter (value, bCurrencyLabel = true, decimals = 2) {
      const fValue = Object.is(value, -0) ? 0 : value
      return this.currentCOA.locale ? formatAmount(fValue, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, bCurrencyLabel, decimals) : Math.ceil(value)
    },
    percentageFormatter (value) {
      return parseFloat(value).toFixed(1).toString() + '%'
    },
    translatePeriodColumnTitleIntoNiceName (sValue, sResolution) {
      const iYear = sValue.toString().substring(0, 4)
      let sReturnLabel = null

      if (sResolution === 'month') {
        const iMonth = parseInt(sValue.substring(5))
        sReturnLabel = this.$t('common.months.month' + iMonth + '_short')
        if (iMonth === 1) {
          sReturnLabel = sReturnLabel + ' ' + iYear
        }
      } else {
        sReturnLabel = iYear.toString()
      }

      return sReturnLabel
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    },
    gomycell (key) {
      return `cell(${key})`
    },
    gomyhead (key) {
      return `head(${key})`
    }
  }
}
