<template>
    <div class="component-common-loader my-2" :class="'text-' + align">
        <b-spinner class="align-middle customColor"></b-spinner>
        <strong class="textthemeBasic">{{ loadingText !== null ? loadingText : $t('common.loading') }}</strong>
    </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loadingText: {
      type: String,
      default: null
    },
    align: {
      type: String,
      default: 'center'
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
.component-common-loader
{
    .customColor {
        color: #3bb37d;
        margin-right: 10px;
    }
    .textthemeBasic {
        color: #3bb37d;
    }
}
</style>
