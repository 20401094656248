<template>
  <b-container fluid class="component-balance-account-report-simulation-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-balance-account-report-simulation-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import _ from 'lodash'

export default {
  name: 'BudgetSimulationChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    simulationData: { type: Object },
    account: { type: Object },
    currencySymbol: { type: String },
    chartType: { type: String, default: 'simulation-outgoing-balance' },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.account.budget_simulation.charts.'
    }
  },
  methods: {
    fillChart () {
      if (!this.simulationData) {
        return false
      }

      switch (this.chartType) {
        case 'simulation-outgoing-balance':
          this.createSimulationChart('outgoing_balance', 'simulation-outgoing-balance')
          break
        case 'simulation-withdrawal-per-year':
          this.createSimulationChart('withdrawal', 'simulation-withdrawal-per-year')
          break
        case 'simulation-withdrawal-acc':
          this.createSimulationChartAccumulated('withdrawal', 'simulation-withdrawal-acc')
          break
        case 'simulation-added-amounts':
          this.createSimulationChart('added_amount', 'simulation-added-amounts')
          break
        case 'simulation-added-amounts-acc':
          this.createSimulationChartAccumulated('added_amount', 'simulation-added-amounts-acc')
          break
        case 'simulation-performance-total-amount': {
          const color = this.account.type === 'income' ? 'green' : 'red'
          this.createSimulationChart('total', 'simulation-performance-total-amount', color)
          break
        }
        case 'simulation-amortization':
          this.createSimulationChart('amortization', 'simulation-amortization', 'green')
          break
        case 'simulation-interest':
          this.createSimulationChart('interest', 'simulation-interest', 'yellow')
          break
      }
    },
    createSimulationChart (sParam, sChartLabel, sColor = 'green') {
      const sLabel = this.$t(this.translationPath + sChartLabel)
      const dataset = { type: 'bar', data: [], label: sLabel }
      const labels = []
      const aBackgroundColors = []
      const suffix = ' ' + this.currencySymbol

      const data = this.fillGapsWithPeriods(this.simulationData.annual_data)

      for (const period in data) {
        const oPeriod = data[period]
        labels.push(oPeriod.year)
        dataset.data.push(oPeriod[sParam])
        if (sColor === 'green') {
          aBackgroundColors.push(oPeriod[sParam] < 0 ? this.getRed() : this.getGreen())
        } else if (sColor === 'yellow') {
          aBackgroundColors.push(this.getYellow())
        } else if (sColor === 'red') {
          aBackgroundColors.push(this.getRed())
        }
      }

      this.chartOptions.legend.display = true
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return sLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      dataset.backgroundColor = aBackgroundColors

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    },
    createSimulationChartAccumulated (sParam, sChartLabel) {
      const sLabel = this.$t(this.translationPath + sChartLabel)
      const dataset = { type: 'bar', data: [], label: sLabel }
      const labels = []
      const aBackgroundColors = []
      const suffix = ' ' + this.currencySymbol
      let iAccumulatedSum = 0

      for (const period in this.simulationData.annual_data) {
        const oPeriod = this.simulationData.annual_data[period]
        labels.push(oPeriod.year)
        iAccumulatedSum += oPeriod[sParam]
        dataset.data.push(iAccumulatedSum)
        aBackgroundColors.push(oPeriod[sParam] < 0 ? this.getRed() : this.getGreen())
      }

      this.chartOptions.legend.display = true
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return sLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      dataset.backgroundColor = aBackgroundColors

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    },
    fillGapsWithPeriods (data) {
      if (data.length <= 1) {
        return data
      }
      const tempMap = {}
      const existedYears = []
      data.map(period => {
        tempMap[period.year] = period
        existedYears.push(period.year)
      })
      existedYears.sort()

      const startYear = existedYears[0]
      const endYear = existedYears[existedYears.length - 1]

      const modifiedData = []
      const template = {
        added_amount: 0,
        amortization: 0,
        amount: 0,
        incoming_balance: 0,
        interest: 0,
        increase: 0,
        outgoing_balance: 0,
        tax: 0,
        tax_amount: 0,
        total: 0,
        total_value_change: 0,
        value_before_tax: 0,
        withdrawal: 0
      }
      for (let year = startYear; year <= endYear; year++) {
        if (Object.prototype.hasOwnProperty.call(tempMap, year)) {
          modifiedData.push(tempMap[year])
          continue
        }

        const period = _.cloneDeep(template)
        period.year = year
        period.months = []
        for (let month = 1; month <= 12; month++) {
          const monthPeriod = _.cloneDeep(template)
          monthPeriod.month = month
          monthPeriod.year = year
          period.months.push(monthPeriod)
        }
        modifiedData.push(period)
      }

      return modifiedData
    }
  },
  watch: {
    simulationData: {
      handler () {
        this.fillChart()
      },
      immediate: true,
      deep: true
    },
    chartType: {
      handler () {
        this.fillChart()
      },
      immediate: true
    }
  }
}
</script>
