<template>
  <b-container fluid class="component-reports-performance-accountv2-line-chart pb-5">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-accountv2-line-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'AccountReportPeriodBudgetLineChart',
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  mixins: [chartOptions, SpirectaReportChartMixin],
  components: {
    LineChart
  },
  data () {
    return {
      translationPath: 'reports.performance.account.chart.',
      chartData: {},
      currencySuffix: ''
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      if (Object.keys(this.apiData).length > 0) {
        this.chartData = {}
      }

      const lblBudget = this.$t(
        'reports.budget.period-report.period.table.budget_amount'
      )
      const lblActualAmount = this.$t(
        'reports.budget.period-report.period.table.actual_amount'
      )
      const suffix = ' ' + this.currencySuffix

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        switch (tooltipItem.datasetIndex) {
          case 0:
            return (lblActualAmount + ': ' + formatNumberToFull(tooltipItem.value) + suffix)
          case 1:
            return (
              lblBudget + ': ' + formatNumberToFull(tooltipItem.value) + suffix
            )
        }
      }

      const labels = []
      const actualAmounts = []
      const budgetAmounts = []
      const apiPeriods = this.apiData.periods

      let iBudgetAcc = 0
      let iActualAcc = 0
      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))

        iBudgetAcc += parseInt(apiPeriods.budget[period])
        iActualAcc += parseInt(apiPeriods.amounts[period])

        actualAmounts.push(iActualAcc)
        budgetAmounts.push(iBudgetAcc)
      })

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: lblActualAmount,
            data: actualAmounts,
            fill: false,
            borderColor: this.account.type === 'income' ? this.getGreen() : this.getRed()
          },
          {
            label: lblBudget,
            data: budgetAmounts,
            fill: false,
            borderColor: this.getBudgetColor()
          }
        ]
      }
    }
  }
}
</script>
