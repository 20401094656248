<template>
  <div class="period-selector" :class="{ 'period-selector-expanded': isBodyShown ? true : false, 'button': variant === 'button','print-hide': true }" v-click-outside="onClickOutside">
    <div v-if="currentCOA.id" class="period-selector-header text-left" @click="onHeaderClick">
      <template v-if="variant === 'button'">
        <i class="flaticon stroke calendar mr-2"></i>
      </template>
      <span v-if="periodType === 'custom'">
        {{ dateRangeString }}
      </span>
      <span v-else-if="periodType === 'selected-month'">
        {{ $t('common.months.' + monthStrings[selectedMonth.substr(4)]) }} {{ selectedMonth.substr(0, 4) }}
      </span>
      <span v-else-if="periodType === 'selected-year'">
        {{ selectedYear }}
      </span>
      <span v-else-if="periodType === 'full-period'">
        {{ $t(translationPath + 'predefined_period_types.full_period') }}: {{ dateRangeString }}
      </span>
      <span v-else-if="periodType === 'rolling-3-months-excl'">
        {{ $t(translationPath + 'labels.rolling-3-month-excl') }}: {{ dateRangeString }}
      </span>
      <span v-else-if="periodType === 'rolling-6-months-excl'">
        {{ $t(translationPath + 'labels.rolling-6-month-excl') }}: {{ dateRangeString }}
      </span>
      <span v-else-if="periodType === 'rolling-12-months-excl'">
        {{ $t(translationPath + 'labels.rolling-12-month-excl') }}: {{ dateRangeString }}
      </span>
      <span v-else>
        {{ $t(translationPath + 'predefined_period_types.' + periodType.split('-').join('_')) }}: {{ dateRangeString }}
      </span>
    </div>
    <div v-else class="period-selector-header text-left">
      <loader loading-text="" />
    </div>
    <transition name="slide">
      <div class="period-selector-body pb-3" v-if="isBodyShown">
        <template v-if="showPredefinedPeriods">
          <div class="period-selector-body-options-wrap period-selector-body-predefined-periods">
            <b-row>
              <b-col class="px-0"><h4>{{ $t(translationPath + 'labels.select_predefined_period') }}</h4></b-col>
            </b-row>
            <b-row>
              <b-col class="px-0">
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'this-month' }" @click="selectPredefinedPeriod('this-month')">{{ $t(translationPath + 'predefined_period_types.this_month') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button inline-block" :class="{ 'is-active': periodType === 'rolling-3-months' }" @click="selectPredefinedPeriod('rolling-3-months')">{{ $t(translationPath + 'predefined_period_types.rolling_3_months') }}</span>&nbsp;
                <span class="period-selector-body-btn-link predefined-period-button inline-block" :class="{ 'is-active': periodType === 'rolling-3-months-excl' }" @click="selectPredefinedPeriod('rolling-3-months-excl')">{{ $t(translationPath + 'predefined_period_types.rolling_3_months_excl') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'year-to-date' }" @click="selectPredefinedPeriod('year-to-date')">{{ $t(translationPath + 'predefined_period_types.year_to_date') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'this-quarter' }" @click="selectPredefinedPeriod('this-quarter')">{{ $t(translationPath + 'predefined_period_types.this_quarter') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'last-year' }" @click="selectPredefinedPeriod('last-year')">{{ $t(translationPath + 'predefined_period_types.last_year') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-year' }" @click="selectPredefinedPeriod('next-year')">{{ $t(translationPath + 'predefined_period_types.next_year') }}</span>
              </b-col>
              <b-col class="px-0">
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'last-month' }" @click="selectPredefinedPeriod('last-month')">{{ $t(translationPath + 'predefined_period_types.last_month') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button inline-block" :class="{ 'is-active': periodType === 'rolling-6-months' }" @click="selectPredefinedPeriod('rolling-6-months')">{{ $t(translationPath + 'predefined_period_types.rolling_6_months') }}</span>&nbsp;
                <span class="period-selector-body-btn-link predefined-period-button inline-block" :class="{ 'is-active': periodType === 'rolling-6-months-excl' }" @click="selectPredefinedPeriod('rolling-6-months-excl')">{{ $t(translationPath + 'predefined_period_types.rolling_6_months_excl') }}</span><div></div>
                <span class="period-selector-body-btn-link predefined-period-button inline-block" :class="{ 'is-active': periodType === 'rolling-12-months' }" @click="selectPredefinedPeriod('rolling-12-months')">{{ $t(translationPath + 'predefined_period_types.rolling_12_months') }}</span>&nbsp;
                <span class="period-selector-body-btn-link predefined-period-button inline-block" :class="{ 'is-active': periodType === 'rolling-12-months-excl' }" @click="selectPredefinedPeriod('rolling-12-months-excl')">{{ $t(translationPath + 'predefined_period_types.rolling_12_months_excl') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'last-quarter' }" @click="selectPredefinedPeriod('last-quarter')">{{ $t(translationPath + 'predefined_period_types.last_quarter') }}</span>
                <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'this-full-year' }" @click="selectPredefinedPeriod('this-full-year')">{{ $t(translationPath + 'predefined_period_types.this_full_year') }}</span>
                <span v-if="showPeriodOptionFullPeriod" class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'full-period' }" @click="selectPredefinedPeriod('full-period')">{{ $t(translationPath + 'predefined_period_types.full_period') }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="showPredefinedLongTermPeriods">
          <b-row class="period-selector-body-options-wrap period-selector-body-predefined-periods">
            <b-col class="px-0">
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-year' }" @click="selectPredefinedPeriod('next-year')">{{ $t(translationPath + 'predefined_period_types.next_year') }}</span>
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-10-years' }" @click="selectPredefinedPeriod('next-10-years')">{{ $t(translationPath + 'predefined_period_types.next_10_years') }}</span>
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-30-years' }" @click="selectPredefinedPeriod('next-30-years')">{{ $t(translationPath + 'predefined_period_types.next_30_years') }}</span>
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-50-years' }" @click="selectPredefinedPeriod('next-50-years')">{{ $t(translationPath + 'predefined_period_types.next_50_years') }}</span>
            </b-col>
            <b-col class="px-0">
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-5-years' }" @click="selectPredefinedPeriod('next-5-years')">{{ $t(translationPath + 'predefined_period_types.next_5_years') }}</span>
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-20-years' }" @click="selectPredefinedPeriod('next-20-years')">{{ $t(translationPath + 'predefined_period_types.next_20_years') }}</span>
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-40-years' }" @click="selectPredefinedPeriod('next-40-years')">{{ $t(translationPath + 'predefined_period_types.next_40_years') }}</span>
              <span class="period-selector-body-btn-link predefined-period-button" :class="{ 'is-active': periodType === 'next-60-years' }" @click="selectPredefinedPeriod('next-60-years')">{{ $t(translationPath + 'predefined_period_types.next_60_years') }}</span>
            </b-col>
          </b-row>
        </template>
        <template v-if="showLastMonths">
          <div class="period-selector-body-options-wrap period-selector-body-last-12-months mt-3">
            <b-row>
              <b-col class="px-0"><h4>{{ $t(translationPath + 'labels.select_last_12_months') }}</h4></b-col>
            </b-row>
            <b-row>
              <b-col class="px-0">

                <span v-for="item in last12Months" :key="item.date.getTime()"
                      class="period-selector-body-btn-link month-button mr-2"
                      :class="{ 'is-active': String(item.date.getFullYear())+item.date.getMonth() === selectedMonth && periodType === 'selected-month' }"
                      @click="selectPredefinedMonth(item.date)" :title="$t(item.monthLongString)+ ', ' + item.date.getFullYear()">
                  {{ $t(item.monthString)}}
                  <span v-if="item.date.getMonth() === 0"> {{ item.date.getFullYear() }}</span>
                </span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="showLastYears">
          <div class="period-selector-body-options-wrap period-selector-body-last-years mt-3">
            <b-row>
              <b-col class="px-0"><h4>{{ $t(translationPath + 'labels.select_last_years') }}</h4></b-col>
            </b-row>
            <b-row>
              <b-col class="px-0">
                <span v-for="year in lastYears" :key="year" class="period-selector-body-btn-link year-button mr-2" :class="{ 'is-active': year === selectedYear && periodType === 'selected-year' }" @click="selectPredefinedYear(year)">{{ year }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="showResolution">
          <div class="period-selector-body-options-wrap period-selector-body-resolution mt-3">
            <b-row>
              <b-col class="px-0">
                <h4>{{ $t(translationPath + 'labels.select_resolution') }}</h4>
                <p class="mb-0">
                  <span v-for="item in allowedResolutionTypes" :key="item" class="period-selector-body-btn-link user-resolution-button mr-2" :class="{ 'is-active': resolution === item }" @click="selectResolution(item)">{{ $t(translationPath + 'resolutions.' + item) }}</span>
                </p>
              </b-col>
            </b-row>
          </div>
        </template>
        <div v-if="showPredefinedPeriods" class="mt-3" style="padding: 0px 20px;">
          <b-row>
            <b-col class="px-0"><h4>{{ $t(translationPath + 'labels.make_custom_selection') }}</h4></b-col>
          </b-row>
        </div>
        <div class="period-selector-body-options-wrap period-selector-body-detepicker">
          <template v-if="!showComparisonPeriod">
            <div class="period-selector-body-detepicker-single">
              <b-form-group
                label-for="datepicker-date-start"
                label-cols="12"
                label-align="left"
              >
                <template slot="label">{{ $t(translationPath + 'labels.start_date') }}</template>
                <datepicker
                  id="datepicker-date-start"
                  v-model="dateStart"
                  :input-class="'form-control' + (!isDateRangeOk ? ' is-invalid' : '')"
                  :typeable="true"
                  :format="'yyyy-MM-dd'"
                  :monday-first="startWeekByMonday"
                  :language="datePickerLanguage"
                  @selected="periodType = 'custom'"
                />
                <b-row no-gutters v-if="!isDateRangeOk">
                  <b-col>
                    <div class="invalid-feedback">{{ $t(translationPath + 'errors.invalid_date_range') }}</div>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                label-for="datepicker-date-end"
                label-cols="12"
                label-align="left"
              >
                <template slot="label">{{ $t(translationPath + 'labels.end_date') }}</template>
                <datepicker
                  id="datepicker-date-end"
                  v-model="dateEnd"
                  :input-class="'form-control' + (!isDateRangeOk ? ' is-invalid' : '')"
                  :typeable="true"
                  :format="'yyyy-MM-dd'"
                  :monday-first="startWeekByMonday"
                  :language="datePickerLanguage"
                  @selected="periodType = 'custom'"
                />
              </b-form-group>
            </div>
          </template>
          <template v-else>
            <div class="period-selector-body-detepicker-comparison">
              <b-row class="mx-0">
                <b-col>{{ $t(translationPath + 'labels.period') }}</b-col>
              </b-row>
              <b-row align-v="center">
                <b-col cols="5">
                  <datepicker
                    id="datepicker-date-start"
                    v-model="dateStart"
                    :input-class="'form-control' + (!isDateRangeOk ? ' is-invalid' : '')"
                    :typeable="true"
                    :format="'yyyy-MM-dd'"
                    :monday-first="startWeekByMonday"
                    :language="datePickerLanguage"
                    @selected="periodType = 'custom'"
                  />
                </b-col>
                <b-col cols="2" class="text-center">{{ $t(translationPath + 'labels.to') }}</b-col>
                <b-col cols="5">
                  <datepicker
                    id="datepicker-date-end"
                    v-model="dateEnd"
                    :input-class="'form-control' + (!isDateRangeOk ? ' is-invalid' : '')"
                    :typeable="true"
                    :format="'yyyy-MM-dd'"
                    :monday-first="startWeekByMonday"
                    :language="datePickerLanguage"
                    @selected="periodType = 'custom'"
                  />
                </b-col>
              </b-row>
              <b-row no-gutters v-if="!isDateRangeOk">
                <b-col>
                  <div class="invalid-feedback">{{ $t(translationPath + 'errors.invalid_date_range') }}</div>
                </b-col>
              </b-row>

              <b-row class="mx-0 mt-2">
                <b-col>{{ $t(translationPath + 'labels.comparison_period') }}</b-col>
              </b-row>
              <b-row align-v="center">
                <b-col cols="5">
                  <datepicker
                    id="datepicker-comparison-date-start"
                    v-model="comparisonDateStart"
                    :input-class="'form-control' + (!isComparisonDateRangeOk ? ' is-invalid' : '')"
                    :typeable="true"
                    :format="'yyyy-MM-dd'"
                    :monday-first="startWeekByMonday"
                    :language="datePickerLanguage"
                    @selected="periodType = 'custom'"
                  />
                </b-col>
                <b-col cols="2" class="text-center">{{ $t(translationPath + 'labels.to') }}</b-col>
                <b-col cols="5">
                  <datepicker
                    id="datepicker-comparison-date-end"
                    v-model="comparisonDateEnd"
                    :input-class="'form-control' + (!isComparisonDateRangeOk ? ' is-invalid' : '')"
                    :typeable="true"
                    :format="'yyyy-MM-dd'"
                    :monday-first="startWeekByMonday"
                    :language="datePickerLanguage"
                    @selected="periodType = 'custom'"
                  />
                </b-col>
              </b-row>
              <b-row no-gutters v-if="!isComparisonDateRangeOk">
                <b-col>
                  <div class="invalid-feedback">{{ $t(translationPath + 'errors.invalid_comparison_date_range') }}</div>
                </b-col>
              </b-row>
            </div>
          </template>
        </div>
        <div class="period-selector-body-options-wrap period-selector-body-buttons mt-3">
          <b-row>
            <b-col class="text-right">
              <button class="btn btn-outline text-center py-2 mb-1" @click="onCancelPeriod">{{ $t('common.cancel') }}</button>
              <button class="btn btn-primary text-center py-2 mb-1" @click="onSubmitPeriod">{{ $t(translationPath + 'labels.show_data_for_period') }}</button>
            </b-col>
          </b-row>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * Period selector.
 * Component that provides control item for selecting date range.
 * It allows to selact both an arbitrary period defined by user as well
 * as one of predevined periods, defined in component, like this month,
 * perevious month, etc.
 * Also component provides option to select additional date range (called
 * comparison period) along with main date range.
 */

import { mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { sv, en, da } from 'vuejs-datepicker/dist/locale'
import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/common/Loader'

export default {
  name: 'PeriodSelector',
  components: { Datepicker, Loader },
  props: {
    variant: {
      type: String,
      default: ''
    },

    /**
     * PeriodSelector saves its state to client's browser local storage.
     * This saved state is one for all period selectors in the entire application.
     * But based on user's COA id.
     * If this property is set to true, when user opens page, the state will be
     * restored from LocalStorage. Pay attention that in this case property
     * defaultPeriodType will be ignored.
     * Properties defaultStartDate and defaultEndDate have more priotity, because
     * they can be set be url params.
     */
    loadDefaultStateFromStorage: {
      type: Boolean,
      default: true
    },

    /**
     * Used to save state in LocalStorage in different scope, so saved state to scope
     * has no effect to states from other scopes. It can be useful, for exapmle,
     * for reports, where we use future dates so we do not affect other report when
     * we select future dates.
     */
    scope: {
      type: String,
      default: ''
    },

    /**
     * Default predefined period which is selected.
     * Pay attention that defaultStartDate and defaultEndDate overlaps
     * this property if they are passed.
     */
    defaultPeriodType: {
      type: String,
      default: 'this-month'
    },

    /**
     * This prop is used if defaultPeriodType is selected-year or selected-month.
     * If omitted current year and month will be set, but if passed we change
     * selectedYear and selectedMonth props.
     */
    dateForSelectedType: {
      type: String,
      default: (new Date()).toISOString().substr(0, 10)
    },

    /**
     * Predefined date range. This props overlaps defaultPeriodType.
     */
    defaultStartDate: {
      type: String,
      default: null
    },
    defaultEndDate: {
      type: String,
      default: null
    },

    /**
     * Show fields to select comparison period.
     */
    showComparisonPeriod: {
      type: Boolean,
      default: false
    },

    /**
     * Show predefined periods, like this month, previous year, etc.
     */
    showPredefinedPeriods: {
      type: Boolean,
      default: true
    },

    /**
     * Show predefined periods, for simulations and long term budget (lifeplan)
     */
    showPredefinedLongTermPeriods: {
      type: Boolean,
      default: false
    },

    /**
     * Show last years which includes data. This years are loaded from api.
     * Selecting year means period from 1st January to 31th Decemper of selected year.
     */
    showLastYears: {
      type: Boolean,
      default: false
    },

    /**
     * Show last 12 month. Selecting month means period from start to end of month.
     */
    showLastMonths: {
      type: Boolean,
      default: false
    },

    /**
     * Show selections with resolution.
     */
    showResolution: {
      type: Boolean,
      default: false
    },

    /**
     * Resolution selected by default.
     */
    defaultResolution: {
      type: String,
      default: 'month'
    },

    /**
     * Allowed resolutions. They are shown as selections.
     */
    allowedResolutions: {
      type: Array,
      default: () => ['day', 'week', 'month', 'year']
    },

    /**
     * Optional predefined period. If selected - it call api to define start date - the date
     * where first transaction where made.
     */
    showPeriodOptionFullPeriod: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      translationPath: 'common.period_selector.',
      isBodyShown: false,
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      allowedPeriodTypes: ['this-week', 'this-month', 'this-quarter', 'year-to-date', 'last-week', 'last-month', 'last-quarter', 'last-year', 'rolling-30-days', 'rolling-3-months', 'rolling-3-months-excl', 'rolling-6-months', 'rolling-6-months-excl', 'rolling-12-months', 'rolling-12-months-excl', 'custom', 'next-year', 'next-5-years', 'next-10-years', 'next-20-years', 'next-30-years', 'next-40-years', 'next-50-years', 'next-60-years', 'selected-month', 'this-full-year', 'full-period', 'selected-year'],
      periodType: 'this-month',
      selectedMonth: null, // Format: YYYYMM where MM is the month_number 0 - 11 to match the javascript month indexing
      selectedYear: null,
      allowedResolutionTypes: [],
      monthStrings: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
      resolution: 'day',
      dateStart: null,
      dateEnd: null,
      comparisonDateStart: null,
      comparisonDateEnd: null,
      apiYears: null,
      periodTemplates: {},
      previousState: {
        periodType: 'this-month',
        selectedMonth: null,
        resolution: 'day',
        dateStart: null,
        dateEnd: null,
        comparisonDateStart: null,
        comparisonDateEnd: null
      }
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    datePickerLanguage () {
      let lang = sv
      switch (this.currentUser.default_lang) {
        case 'en':
          lang = en
          break
        case 'sv':
          lang = sv
          break
        case 'da':
          lang = da
          break
        default:
          lang = sv
          break
      }
      return lang
    },

    /**
     * Stringified date range. It is used as label for component in its header.
     */
    dateRangeString () {
      if (this.periodType === 'full-period' && !this.dateStart) {
        return ' ... '
      }

      if (!this.dateStart || !this.dateEnd) {
        return 'Not defined date range'
      }

      return this.dateStart.toISOString().substr(2, 8).split('-').join('') + ' - ' + this.dateEnd.toISOString().substr(2, 8).split('-').join('')
    },

    /**
     * Defines array with data for last 12 months.
     */
    last12Months () {
      const data = []
      const dt = moment().startOf('month')
      for (let i = 1; i <= 13; i++) {
        data.push({
          date: dt.toDate(),
          monthString: 'common.months.' + this.monthStrings[dt.month()],
          monthLongString: 'common.months.month' + (dt.month() + 1) + '_long'
        })
        dt.subtract(1, 'month').startOf('month')
      }
      return data
    },

    /**
     * Defines array with data for last years.
     */
    lastYears () {
      let includesCurrentYear = false
      const currentYear = new Date().getFullYear()
      const years = []
      if (this.apiYears) {
        this.apiYears.years_with_actual_transaction_values.map(y => {
          years.push(y)
          if (y === currentYear) {
            includesCurrentYear = true
          }
        })
      }
      if (!includesCurrentYear) {
        years.unshift(currentYear)
      }
      years.sort((a, b) => a < b ? 1 : -1)
      return years
    },

    isDateRangeOk () {
      return this.dateString(this.dateStart) < this.dateString(this.dateEnd)
    },
    isComparisonDateRangeOk () {
      return this.dateString(this.comparisonDateStart) < this.dateString(this.comparisonDateEnd)
    },
    isRangeOk () {
      return this.isDateRangeOk && this.isComparisonDateRangeOk
    }
  },
  methods: {
    async selectPredefinedPeriod (val, emit = true) {
      this.isBodyShown = false

      if (this.allowedPeriodTypes.indexOf(val) !== -1) {
        this.periodType = val
        await this.setPredefinedDates()
        if (emit) {
          this.emitPeriod()
        }
      } else {
        throw new Error('Unknown period type')
      }
    },
    selectResolution (val, emit = true) {
      this.isBodyShown = false
      if (this.allowedResolutionTypes.indexOf(val) !== -1) {
        this.resolution = val
        if (emit) {
          this.emitPeriod()
        }
      } else {
        if (this.allowedResolutionTypes.length) {
          this.resolution = this.allowedResolutionTypes[0]
          if (emit) {
            this.emitPeriod()
          }
        }
        throw new Error('Unknown resolution type: ' + val)
      }
    },
    selectPredefinedMonth (date) {
      // Subtract 1 month because months are indexed from 0
      const month = parseInt(moment(date).format('MM')) - 1
      this.selectedMonth = moment(date).format('YYYY') + month

      this.selectPredefinedPeriod('selected-month')
    },
    selectPredefinedYear (year) {
      this.selectedYear = year
      this.selectPredefinedPeriod('selected-year')
    },

    /**
     * Set dates according to selected predefined period.
     * It is async because type 'full-period' needs to call api to get data
     * and we need to await until request will be finished.
     */
    async setPredefinedDates () {
      this.dateStart = new Date()
      this.dateEnd = new Date()
      this.comparisonDateStart = new Date()
      this.comparisonDateEnd = new Date()

      switch (this.periodType) {
        case 'this-week':
          this.dateStart = moment().startOf('W').toDate()
          this.dateEnd = moment().endOf('W').toDate()
          this.comparisonDateStart = moment().subtract(1, 'W').startOf('W').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'W').endOf('W').toDate()
          break
        case 'last-week':
          this.dateStart = moment().subtract(1, 'W').startOf('W').toDate()
          this.dateEnd = moment().subtract(1, 'W').endOf('W').toDate()
          this.comparisonDateStart = moment().subtract(2, 'W').startOf('W').toDate()
          this.comparisonDateEnd = moment().subtract(2, 'W').endOf('W').toDate()
          break
        case 'this-month':
          this.dateStart = moment().startOf('M').toDate()
          this.dateEnd = moment().endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(1, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'M').endOf('M').toDate()
          break
        case 'last-month':
          this.dateStart = moment().subtract(1, 'M').startOf('M').toDate()
          this.dateEnd = moment().subtract(1, 'M').endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(2, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(2, 'M').endOf('M').toDate()
          break
        case 'this-quarter':
          this.dateStart = moment().startOf('Q').toDate()
          this.dateEnd = moment().endOf('Q').toDate()
          this.comparisonDateStart = moment().subtract(1, 'Q').startOf('Q').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Q').endOf('Q').toDate()
          break
        case 'last-quarter':
          this.dateStart = moment().subtract(1, 'Q').startOf('Q').toDate()
          this.dateEnd = moment().subtract(1, 'Q').endOf('Q').toDate()
          this.comparisonDateStart = moment().subtract(2, 'Q').startOf('Q').toDate()
          this.comparisonDateEnd = moment().subtract(2, 'Q').endOf('Q').toDate()
          break
        case 'year-to-date':
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().toDate()
          this.comparisonDateStart = moment().subtract(1, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').toDate()
          break
        case 'last-year':
          this.dateStart = moment().subtract(1, 'Y').startOf('Y').toDate()
          this.dateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(2, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(2, 'Y').endOf('Y').toDate()
          break
        case 'this-full-year':
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(1, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        case 'rolling-3-months':
          this.dateStart = moment().subtract(2, 'M').startOf('M').toDate()
          this.dateEnd = moment().endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(5, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(3, 'M').endOf('M').toDate()
          break
        case 'rolling-3-months-excl':
          this.dateStart = moment().subtract(3, 'M').startOf('M').toDate()
          this.dateEnd = moment().subtract(1, 'M').endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(6, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(4, 'M').endOf('M').toDate()
          break
        case 'rolling-6-months':
          this.dateStart = moment().subtract(5, 'M').startOf('M').toDate()
          this.dateEnd = moment().endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(11, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(6, 'M').endOf('M').toDate()
          break
        case 'rolling-6-months-excl':
          this.dateStart = moment().subtract(6, 'M').startOf('M').toDate()
          this.dateEnd = moment().subtract(1, 'M').endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(12, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(7, 'M').endOf('M').toDate()
          break
        case 'rolling-12-months':
          this.dateStart = moment().subtract(11, 'M').startOf('M').toDate()
          this.dateEnd = moment().endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(23, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(12, 'M').endOf('M').toDate()
          break
        case 'rolling-12-months-excl':
          this.dateStart = moment().subtract(12, 'M').startOf('M').toDate()
          this.dateEnd = moment().subtract(1, 'M').endOf('M').toDate()
          this.comparisonDateStart = moment().subtract(24, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment().subtract(13, 'M').endOf('M').toDate()
          break
        case 'selected-month': {
          if (!this.selectedMonth) {
            this.selectedMonth = moment().format('YYYYMM')
          }

          const year = this.selectedMonth.substr(0, 4)
          const month = parseInt(this.selectedMonth.substr(4))

          const dateStr = moment().month(month).year(year).startOf('month').format('YYYY-MM-DD')

          this.dateStart = moment(dateStr).startOf('M').toDate()
          this.dateEnd = moment(dateStr).endOf('M').toDate()
          this.comparisonDateStart = moment(dateStr).subtract(1, 'M').startOf('M').toDate()
          this.comparisonDateEnd = moment(dateStr).subtract(1, 'M').endOf('M').toDate()
          break
        }
        case 'selected-year': {
          if (!this.selectedYear) {
            this.selectedYear = this.dateStart.getFullYear()
          }

          this.dateStart = moment(this.selectedYear + '-01-01').startOf('Y').toDate()
          this.dateEnd = moment(this.selectedYear + '-01-01').endOf('Y').toDate()
          this.comparisonDateStart = moment(this.selectedYear + '-01-01').subtract(1, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment(this.selectedYear + '-01-01').subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'next-year': {
          this.dateStart = moment().add(1, 'years').startOf('Y').toDate()
          this.dateEnd = moment().add(1, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().startOf('Y').toDate()
          this.comparisonDateEnd = moment().endOf('Y').toDate()
          break
        }
        case 'next-5-years': {
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().add(4, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(5, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'next-10-years': {
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().add(9, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(10, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'next-20-years': {
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().add(19, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(20, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'next-30-years': {
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().add(29, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(30, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'next-40-years': {
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().add(39, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(40, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'next-50-years': {
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().add(49, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(50, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'next-60-years': {
          this.dateStart = moment().startOf('Y').toDate()
          this.dateEnd = moment().add(59, 'years').endOf('Y').toDate()
          this.comparisonDateStart = moment().subtract(60, 'Y').startOf('Y').toDate()
          this.comparisonDateEnd = moment().subtract(1, 'Y').endOf('Y').toDate()
          break
        }
        case 'full-period': {
          let data = null
          this.dateStart = null
          this.dateEnd = null
          this.comparisonDateStart = null
          this.comparisonDateEnd = null

          await axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/firstAndLastTransaction`)
            .then(response => {
              data = response.data.data[0]
            })

          let firstTransactionDate = data ? data.first_transaction_date : null
          let lastTransactionDate = data ? data.last_transaction_date : null
          if (!firstTransactionDate) {
            firstTransactionDate = moment().subtract(2, 'days').format('YYYY-MM-DD')
          }
          if (!lastTransactionDate) {
            lastTransactionDate = moment().format('YYYY-MM-DD')
          }

          this.dateStart = moment(firstTransactionDate).startOf('D').toDate()
          this.dateEnd = moment(lastTransactionDate).endOf('D').toDate()
          this.comparisonDateStart = moment(firstTransactionDate).startOf('D').toDate()
          this.comparisonDateEnd = moment(lastTransactionDate).endOf('D').toDate()
          break
        }
        case 'custom': {
          let startDate = this.defaultStartDate

          // Check default start date
          if (startDate && !isNaN(new Date(startDate))) {
            startDate = startDate >= '1980-01-01' ? startDate : moment().format('YYYY') + '-01-01'
            this.dateStart = moment(startDate).startOf('D').toDate()
          } else {
            this.dateStart = moment().subtract(30, 'D').startOf('D').toDate()
          }

          // Check default end date
          if (this.defaultEndDate && !isNaN(new Date(this.defaultEndDate))) {
            let endDate = this.defaultEndDate > startDate ? this.defaultEndDate : moment().format('YYYY-MM-DD')
            endDate = endDate > startDate ? endDate : moment(startDate).add(30, 'days').format('YYYY-MM-DD')
            this.dateEnd = moment(endDate).endOf('D').toDate()
          } else {
            this.dateEnd = moment().endOf('D').toDate()
          }

          const range = Math.abs(moment(this.dateStart).diff(moment(this.dateEnd), 'days'))
          this.comparisonDateStart = moment(this.dateStart).subtract(range + 1, 'days').startOf('D').toDate()
          this.comparisonDateEnd = moment(this.dateStart).subtract(1, 'days').endOf('D').toDate()

          this.identifyPredefinedPeriodFromCustomPeriod()

          if (this.periodType !== 'custom') {
            this.setPredefinedDates()

            return 0
          }
          break
        }
        default:
          this.dateStart = null
          this.dateEnd = null
          this.comparisonDateStart = null
          this.comparisonDateEnd = null
      }

      if (this.dateStart && this.dateEnd) {
        this.dateStart.setHours(0, 0, 0, 0)
        this.dateEnd.setHours(23, 59, 59, 59)
        this.comparisonDateStart.setHours(0, 0, 0, 0)
        this.comparisonDateEnd.setHours(23, 59, 59, 59)

        const tzOffsetMs = this.dateStart.getTimezoneOffset() * 60000
        this.dateStart.setTime(this.dateStart.getTime() - tzOffsetMs)
        // this.dateEnd.setTime(this.dateEnd.getTime() - tzOffsetMs)
        this.comparisonDateStart.setTime(this.comparisonDateStart.getTime() - tzOffsetMs)
        // this.comparisonDateEnd.setTime(this.comparisonDateEnd.getTime() - tzOffsetMs)
      }
    },

    /**
     * Makes stringified date from date object.
     * ToDo: I do not remember why we use this instead of oDate.toISOString().substr(0, 10)...
     */
    dateString (oDate) {
      if (!oDate) {
        return null
      }

      let month = oDate.getMonth() + 1
      let day = oDate.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return oDate.getFullYear() + '-' + month + '-' + day
    },
    onHeaderClick () {
      if (!this.isRangeOk) {
        this.onCancelPeriod()
        return false
      }

      this.isBodyShown = !this.isBodyShown
      if (!this.isBodyShown) {
        this.emitPeriod()
      }
    },
    onSubmitPeriod () {
      if (this.isRangeOk) {
        this.emitPeriod(true)
        this.isBodyShown = false
      }
    },
    onCancelPeriod () {
      this.isBodyShown = false
      this.restoreState()
    },
    emitPeriod (bForce = false) {
      if (!this.dateStart) {
        return false
      }

      if (
        !bForce &&
        this.dateString(this.dateStart) === this.dateString(this.previousState.dateStart) &&
        this.dateString(this.dateEnd) === this.dateString(this.previousState.dateEnd) &&
        this.dateString(this.comparisonDateStart) === this.dateString(this.previousState.comparisonDateStart) &&
        this.dateString(this.comparisonDateEnd) === this.dateString(this.previousState.comparisonDateEnd) &&
        this.resolution === this.previousState.resolution
      ) {
        return false
      }

      this.saveState()
      const data = {
        oDateStart: this.dateStart,
        oDateEnd: this.dateEnd,
        oComparisonDateStart: this.comparisonDateStart,
        oComparisonDateEnd: this.comparisonDateEnd,
        sDateStart: this.dateString(this.dateStart),
        sDateEnd: this.dateString(this.dateEnd),
        sComparisonDateStart: this.dateString(this.comparisonDateStart),
        sComparisonDateEnd: this.dateString(this.comparisonDateEnd),
        periodType: this.periodType,
        resolution: this.resolution,
        isError: !this.isRangeOk
      }

      this.$emit('period-selected', data)
    },

    /**
     * Saves current component state. State is used when user wants to cancel his changes.
     */
    saveState () {
      this.previousState = {
        periodType: this.periodType,
        selectedMonth: this.selectedMonth,
        selectedYear: this.selectedYear,
        resolution: this.resolution,
        dateStart: new Date(this.dateStart ? this.dateStart.getTime() : moment().startOf('Y').format('YYYY-MM-DD')),
        dateEnd: new Date(this.dateEnd ? this.dateEnd.getTime() : (new Date()).getTime()),
        comparisonDateStart: this.comparisonDateStart ? new Date(this.comparisonDateStart.getTime()) : new Date(moment().subtract(1, 'years').startOf('Y').format('YYYY-MM-DD')),
        comparisonDateEnd: this.comparisonDateEnd ? new Date(this.comparisonDateEnd.getTime()) : new Date(moment().subtract(1, 'years').format('YYYY-MM-DD'))
      }
      if (this.loadDefaultStateFromStorage) {
        localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(this.previousState))
      }
    },

    /**
     * Restores previous component state.
     */
    restoreState () {
      this.periodType = this.previousState.periodType
      this.resolution = this.previousState.resolution
      this.selectedMonth = this.previousState.selectedMonth
      this.selectedYear = this.previousState.selectedYear
      this.dateStart = this.previousState.dateStart ? new Date(this.previousState.dateStart.getTime()) : (new Date(moment().startOf('Y').format('YYYY-MM-DD')))
      this.dateEnd = this.previousState.dateEnd ? new Date(this.previousState.dateEnd.getTime()) : new Date()
      this.comparisonDateStart = this.previousState.comparisonDateStart ? new Date(this.previousState.comparisonDateStart.getTime()) : new Date(moment().subtract(1, 'years').startOf('Y').format('YYYY-MM-DD'))
      this.comparisonDateEnd = this.previousState.comparisonDateEnd ? new Date(this.previousState.comparisonDateEnd.getTime()) : new Date(moment().subtract(1, 'years').format('YYYY-MM-DD'))
    },

    /**
     * Restores component state from saved state in localStorage.
     */
    restoreStateFromStorage () {
      const savedState = JSON.parse(localStorage.getItem(this.getLocalStorageKey()))
      if (savedState) {
        this.selectedMonth = savedState.selectedMonth
        this.selectedYear = savedState.selectedYear
        this.periodType = savedState.periodType
        this.dateStart = new Date(savedState.dateStart)
        this.comparisonDateStart = new Date(savedState.comparisonDateStart)
        this.comparisonDateEnd = new Date(savedState.comparisonDateEnd)

        if (this.allowedResolutionTypes.indexOf(savedState.resolution) !== -1) {
          this.resolution = savedState.resolution
        } else {
          this.resolution = this.allowedResolutionTypes.length ? this.allowedResolutionTypes[0] : null
        }

        if (this.periodType === 'full-period') {
          this.dateEnd = new Date()
        } else {
          this.dateEnd = new Date(savedState.dateEnd)
        }
      }
    },

    /**
     * Sets dates from values object. It can be used in parent component to
     * programatically set dates.
     * Example: this.$refs.PeriodSelector.$setValuse(values)
     */
    $setValues (values) {
      if (Object.prototype.hasOwnProperty.call(values, 'sDateStart')) {
        this.dateStart = new Date(values.sDateStart)
        this.periodType = 'custom'
      }
      if (Object.prototype.hasOwnProperty.call(values, 'sDateEnd')) {
        this.dateEnd = new Date(values.sDateEnd)
        this.periodType = 'custom'
      }
      if (Object.prototype.hasOwnProperty.call(values, 'sComparisonDateStart')) {
        this.comparisonDateStart = new Date(values.sComparisonDateStart)
        this.periodType = 'custom'
      }
      if (Object.prototype.hasOwnProperty.call(values, 'sComparisonDateEnd')) {
        this.comparisonDateEnd = new Date(values.sComparisonDateEnd)
        this.periodType = 'custom'
      }
      if (Object.prototype.hasOwnProperty.call(values, 'resolution') && this.allowedResolutionTypes.indexOf(values.resolution) !== -1) {
        this.resolution = values.resolution
      }
      this.emitPeriod()
      this.identifyPredefinedPeriodFromCustomPeriod()
    },

    /**
     * Loads years which includes transactions from api.
     */
    loadYears () {
      if (this.showLastYears) {
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/years-for-component`)
          .then(response => {
            this.apiYears = response.data.data
          })
          .catch(error => {
            console.error(error)
          })
      }
    },

    /**
     * Match custom date range to one of predefined periods.
     * If matched it changes period type from custom to matched type.
     */
    identifyPredefinedPeriodFromCustomPeriod () {
      const start = this.dateString(this.dateStart)
      const end = this.dateString(this.dateEnd)

      /**
       * For types selected-year and selected-month (and maybe few other in future) we need to identify
       * not only predefined type but also a year and month. Template object should include such date
       * strings in sub property of these types.
       */
      let isIdentified = false
      for (const periodType in this.periodTemplates) {
        if (Object.prototype.hasOwnProperty.call(this.periodTemplates[periodType], 'sub')) {
          for (const index in this.periodTemplates[periodType].sub) {
            if (this.periodTemplates[periodType].sub[index].start === start && this.periodTemplates[periodType].sub[index].end === end) {
              this.periodType = periodType

              if (Object.prototype.hasOwnProperty.call(this.periodTemplates[periodType].sub[index], 'year')) {
                this.selectedYear = this.periodTemplates[periodType].sub[index].year
              }

              if (Object.prototype.hasOwnProperty.call(this.periodTemplates[periodType].sub[index], 'month')) {
                this.selectedMonth = this.periodTemplates[periodType].sub[index].month
              }

              isIdentified = true
              break
            }
          }
        }
        if (isIdentified) {
          return 1
        }

        /**
         * For periods when only type name is needed - we just compare start and end date.
         */
        if (this.periodTemplates[periodType].start === start && this.periodTemplates[periodType].end === end) {
          this.periodType = periodType
          break
        }
      }
    },

    /**
     * Map date strings which fit predefined period to object.
     */
    definePeriodTemplates () {
      this.periodTemplates = {}

      for (const i in this.allowedPeriodTypes) {
        const periodType = this.allowedPeriodTypes[i]
        if (periodType.indexOf(['custom', 'full-period']) !== -1) {
          continue
        }

        const data = { start: new Date(), end: new Date() }

        switch (periodType) {
          case 'this-week':
            data.start = moment().startOf('W').toDate()
            data.end = moment().endOf('W').toDate()
            break
          case 'last-week':
            data.start = moment().subtract(1, 'W').startOf('W').toDate()
            data.end = moment().subtract(1, 'W').endOf('W').toDate()
            break
          case 'this-month':
            data.start = moment().startOf('month').toDate()
            data.end = moment().endOf('month').toDate()
            break
          case 'last-month':
            data.start = moment().subtract(1, 'months').startOf('month').toDate()
            data.end = moment().subtract(1, 'months').endOf('month').toDate()
            break
          case 'this-quarter':
            data.start = moment().startOf('Q').toDate()
            data.end = moment().endOf('Q').toDate()
            break
          case 'last-quarter':
            data.tart = moment().subtract(1, 'Q').startOf('Q').toDate()
            data.end = moment().subtract(1, 'Q').endOf('Q').toDate()
            break
          case 'year-to-date':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().toDate()
            break
          case 'last-year':
            data.start = moment().subtract(1, 'Y').startOf('Y').toDate()
            data.end = moment().subtract(1, 'Y').endOf('Y').toDate()
            break
          case 'this-full-year':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().endOf('Y').toDate()
            break
          case 'rolling-3-months':
            data.start = moment().subtract(2, 'months').startOf('month').toDate()
            data.end = moment().endOf('month').toDate()
            break
          case 'rolling-3-months-excl':
            data.start = moment().subtract(3, 'months').startOf('month').toDate()
            data.end = moment().subtract(1, 'months').endOf('month').toDate()
            break
          case 'rolling-6-months':
            data.start = moment().subtract(5, 'months').startOf('month').toDate()
            data.end = moment().endOf('month').toDate()
            break
          case 'rolling-6-months-excl':
            data.start = moment().subtract(6, 'months').startOf('month').toDate()
            data.end = moment().subtract(1, 'months').endOf('month').toDate()
            break
          case 'rolling-12-months':
            data.start = moment().subtract(11, 'M').startOf('M').toDate()
            data.end = moment().endOf('M').toDate()
            break
          case 'rolling-12-months-excl':
            data.start = moment().subtract(12, 'M').startOf('M').toDate()
            data.end = moment().subtract(1, 'M').endOf('M').toDate()
            break
          case 'next-year':
            data.start = moment().add(1, 'years').startOf('Y').toDate()
            data.end = moment().add(1, 'years').endOf('Y').toDate()
            break
          case 'next-5-years':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().add(4, 'years').endOf('Y').toDate()
            break
          case 'next-10-years':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().add(9, 'years').endOf('Y').toDate()
            break
          case 'next-20-years':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().add(19, 'years').endOf('Y').toDate()
            break
          case 'next-30-years':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().add(29, 'years').endOf('Y').toDate()
            break
          case 'next-40-years':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().add(39, 'years').endOf('Y').toDate()
            break
          case 'next-50-years':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().add(49, 'years').endOf('Y').toDate()
            break
          case 'next-60-years':
            data.start = moment().startOf('Y').toDate()
            data.end = moment().add(59, 'years').endOf('Y').toDate()
            break
          case 'selected-month': {
            data.sub = []

            for (let n = 0; n < 12; n++) {
              const year = moment().subtract(n, 'M').startOf('month').format('YYYY')
              // n + 1 since we subtracted and months are indexed at 0if ( n + iMonthModifier)
              let month = moment().subtract(n + 1, 'M').startOf('month').format('M')

              if (parseInt(month) === 12) {
                month = 0
              }

              data.sub.push({
                start: moment().subtract(n, 'M').startOf('month').format('YYYY-MM-DD'),
                end: moment().subtract(n, 'M').endOf('month').format('YYYY-MM-DD'),
                year: parseInt(year),
                month: year + month,
                month_original: parseInt(month)
              })
            }
            break
          }
          case 'selected-year': {
            data.sub = []
            this.lastYears.map(year => {
              data.sub.push({
                start: year + '-01-01',
                end: year + '-12-31',
                year: year
              })
            })
            break
          }
        }

        if (data.start && data.end) {
          data.start.setHours(0, 0, 0, 0)
          data.end.setHours(23, 59, 59, 59)
          const tzOffsetMs = data.start.getTimezoneOffset() * 60000
          data.start.setTime(data.start.getTime() - tzOffsetMs)
        }

        data.start = this.dateString(data.start)
        data.end = this.dateString(data.end)
        this.periodTemplates[periodType] = data
      }
    },
    onClickOutside (e) {
      if (this.isBodyShown && this.isDateRangeOk) {
        this.emitPeriod()
        this.isBodyShown = false
      } else if (this.isBodyShown && !this.isDateRangeOk) {
        this.onCancelPeriod()
      }
    },
    getLocalStorageKey () {
      let storageKey = 'periodSelectorState' + this.currentCOA.id
      if (this.scope) {
        storageKey += '_' + this.scope
      }
      return storageKey
    },
    /**
     * Start here
     */
    async init () {
      if (!this.currentCOA.id) {
        return false
      }

      // Loads years which includes transactions from api.
      this.loadYears()

      // Define period templates
      this.definePeriodTemplates()
      this.allowedResolutionTypes = this.allowedResolutions

      if (this.dateForSelectedType) {
        this.selectedYear = Number(this.dateForSelectedType.substr(0, 4))
        this.selectedMonth = String(this.selectedYear) + (new Date(this.dateForSelectedType)).getMonth()
      }

      if (this.defaultStartDate || this.defaultEndDate) {
        this.selectResolution(this.defaultResolution, false)

        if ((this.defaultStartDate && isNaN(new Date(this.defaultStartDate))) || (this.defaultEndDate && isNaN(new Date(this.defaultEndDate)))) {
          console.warn('Invalid default start or end date! Changing to "year-to-date" template!')
          this.selectPredefinedPeriod('year-to-date')
        } else {
          this.selectPredefinedPeriod('custom', false)
        }
      } else if (this.loadDefaultStateFromStorage && JSON.parse(localStorage.getItem(this.getLocalStorageKey()))) {
        this.restoreStateFromStorage()
      } else {
        await this.selectPredefinedPeriod(this.defaultPeriodType, false)
        this.selectResolution(this.defaultResolution, false)
      }

      this.emitPeriod()
      return true
    }
  },
  watch: {
    'currentCOA.id' () {
      this.init()
    },
    defaultPeriodType (newVal) {
      this.selectPredefinedPeriod(newVal)
    },
    apiYears (newVal) {
      this.definePeriodTemplates()
      if (this.periodType === 'custom') {
        this.identifyPredefinedPeriodFromCustomPeriod()
      }
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/period-selector.scss'
</style>
