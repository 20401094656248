<template>
  <b-modal
    id="deleteModal"
    centered
    ref="deleteModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ heading ? heading : $t('common.action_required') }}
    </template>
    <h6 class="mb-0 font-weight-lighter">{{ message }}</h6>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t('common.no_cancel') }}</b-button>
      <b-button variant="danger"  @click="onYes">{{ $t('common.yes_delete') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    message: {
      type: String,
      default: 'Are you sure you want to delete?'
    },
    heading: {
      type: String,
      default: null
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.deleteModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.deleteModal.hide()
      this.$emit('hide')
    },
    onCancel () {
      this.$refs.deleteModal.hide()
      this.$emit('on-delete-cancel')
    },
    onYes () {
      this.$refs.deleteModal.hide()
      this.$emit('on-delete-confirm')
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
