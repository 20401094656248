<template>
  <b-container fluid class="component-reports-performance-accountv2-active-passive-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-accountv2-active-passive-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'AccountReportPeriodActivePassiveBarChart',
  components: { BarChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.account.charts.active_passive.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []
      const aIncomeColors = this.getGreenTwoColors()
      const aExpenseColors = this.getRedTwoColors()
      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))
      })

      const suffix = ' ' + this.currencySymbol
      const self = this

      this.chartOptions.legend.display = false
      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.xAxes[0].stacked = true
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        const sLabel = tooltipItem.datasetIndex === 0 ? self.$t(self.translationPath + 'active_amount') : self.$t(self.translationPath + 'passive_amount')
        return sLabel + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const datasetActive = { backgroundColor: this.account.type === 'income' ? aIncomeColors[0] : aExpenseColors[0], data: [], stacked: true }
      const datasetPassive = { backgroundColor: this.account.type === 'income' ? aIncomeColors[1] : aExpenseColors[1], data: [], stacked: true }

      for (const period in this.apiData.periods.amounts) {
        datasetActive.data.push(this.apiData.periods.active_amounts[period].toFixed(0))
        datasetPassive.data.push(this.apiData.periods.passive_amounts[period].toFixed(0))
      }

      this.chartData = {
        labels: labels,
        datasets: [datasetActive, datasetPassive]
      }
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
