<template>
  <div class="component-balance-account-report-about-view">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="8" v-if="startDate !== null">
                  <h2>{{ $t(translationPath + 'title_asset_liability',{title: account.title }) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <b-link :to="'/assets-liabilities/accounts/' + account.type + '/' + account.id + '/edit'" class="btn btn-sm btn-outline-secondary mr-3">
                    <i class="flaticon solid edit-3 text-secondary"></i> {{ $t('common.edit')}}
                  </b-link>
                </b-col>
              </b-row>
            </template>

            <!-- Account details -->
            <b-row no-gutters>
              <b-col class="col-md-3 meta-title pr-1">{{$t(translationPath + 'account_type')}}: </b-col>
              <b-col class="col-md-8">{{$t('common.'+ account.type)}}</b-col>
            </b-row>
            <b-row no-gutters>
              <b-col class="col-md-3 meta-title pr-1">{{$t(translationPath + 'account_code')}}: </b-col>
              <b-col class="col-md-8">{{account.code}}</b-col>
            </b-row>
            <b-row no-gutters v-if="account.description !== null">
              <b-col class="col-md-3 meta-title pr-1">{{$t(translationPath + 'description')}}: </b-col>
              <b-col class="col-md-8">{{account.description}}</b-col>
            </b-row>

            <b-row no-gutters class="mt-3">
              <b-col class="col-md-3 meta-title pr-1">{{$t(translationPath + 'group')}}: </b-col>
              <b-col class="col-md-8">{{account.group_title}}</b-col>
            </b-row>
            <b-row no-gutters class="mt-0">
              <b-col class="col-md-3 meta-title pr-1">{{$t(translationPath + 'group_code')}}: </b-col>
              <b-col class="col-md-8">{{account.group_code}}</b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>

import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'BalanceAccountReportAboutView',
  mixins: [],
  components: {
  },
  filters: {
    formatAmount
  },
  props: {
    activeTab: { type: String, default: '' },
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    account: {
      type: Object,
      default: () => {
        return {
          title: ''
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.account.about.',
      tabIndex: 0
    }
  },
  created () {

  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {

  }
}
</script>

<style lang="scss">
.component-performance-account-report-about-view{
  .meta-title{font-weight: bold}
}
</style>
