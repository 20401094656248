<template>
  <div class="component-balance-account-report">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ oAccount.label }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="isPeriodSelectorShown"
                default-period-type="rolling-3-months"
                :default-start-date="startDate ? startDate : null"
                :default-end-date="endDate ? endDate : null"
                :show-resolution="true"
                :default-resolution="resolution"
                :show-last-months="true"
                :allowed-resolutions="['week','month','year']"
                :show-period-option-full-period="true"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <balance-account-report-submenu :active="activeTab" @tab-switched="onTabSwitched" />
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper">
        <div class="pb-5" style="width:100%;height:500px;" v-if="activeTab !== 'about' && activeTab !== 'filter'">
          <balance-account-report-closing-balance-full-bar-chart
             v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'closing-balance-full-bar-chart'"
             :currency="currency"
             :currencySymbol="currencySymbol"
             :account="oAccount"
             :apiData="apiData"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <balance-account-report-closing-balance-vs-lifeplan-chart
             v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'closing-balance-vs-lifeplan-full-bar-chart'"
             :currency="currency"
             :currencySymbol="currencySymbol"
             :account="oAccount"
             :apiData="apiData"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <balance-account-report-closing-balance-value-change-offset-bar-chart
             v-if="( activeTab === 'summary' || activeTab === 'transactions') &&  chartToDisplay === 'closing-balance-value-change-offset-bar-chart'"
             :currency="currency"
             :currencySymbol="currencySymbol"
             :account="oAccount"
             :apiData="apiData"
             :isLoaded="!busyState"
             :resolution="resolution"
          />

          <balance-account-report-change-distribution-bar-chart
            v-if="activeTab === 'distribution'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :account="oAccount"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <balance-account-report-time-weighted-return-chart
            v-if="activeTab === 'twr'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :account="oAccount"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <balance-account-report-stats-half-pie-chart
            v-if="activeTab === 'stats' && statsChartToDisplay === 'common_transactions'"
            chartType="common_transactions"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :account="oAccount"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <balance-account-report-stats-half-pie-chart
            v-if="activeTab === 'stats' && statsChartToDisplay === 'offset_accounts'"
            chartType="offset_accounts"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :account="oAccount"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

          <budget-simulation-chart
            v-if="activeTab === 'budget_simulation'"
            :chartType="simulationChartToDisplay"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :account="oAccount"
            :simulationData="simulationData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->
      <balance-account-report-period-table v-if="activeTab === 'summary'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :account="oAccount"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />

      <balance-account-report-transactions-table v-if="activeTab === 'transactions'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :account="oAccount"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
        @account-changed="onAccountChanged"
      />

      <balance-account-report-distribution-table v-if="activeTab === 'distribution'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :account="oAccount"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
      />

      <balance-account-report-twr-table v-if="activeTab === 'twr'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :account="oAccount"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
      />

      <balance-account-report-stats-view
        v-if="activeTab === 'stats'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :account="oAccount"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
        :currency-symbol="currentCOA.currency ? currentCOA.currency : ''"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => statsChartToDisplay = data.chart"
      />

      <balance-account-report-about-view
        v-if="activeTab === 'about'"
        :isLoaded="!busyState"
        :api-data="apiData"
        :account="oAccount"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
      />

      <balance-account-report-budget-simulation-asset-view
        v-if="activeTab === 'budget_simulation' && bHasAPIDataBeenFetchedOnce && oAccount.type === 'asset'"
        ref="BudgetSimulationAssetView"
        :isLoaded="!busyState"
        :incomingBalance="apiData.periods.amounts.totals.closing_balance"
        :account="oAccount"
        @simulation-data="(simulation) => this.simulationData = simulation.data"
        @simulation-chart-to-display="(data) => this.simulationChartToDisplay = data.chart"
        @delete-all-amounts="deleteAllAmounts"
      />

      <balance-account-report-budget-simulation-liability-view
        v-if="activeTab === 'budget_simulation' && bHasAPIDataBeenFetchedOnce && oAccount.type === 'liability'"
        ref="BudgetSimulationLiabilityView"
        :isLoaded="!busyState"
        :incomingBalance="apiData.periods.amounts.totals.closing_balance"
        :account="oAccount"
        @simulation-data="(simulation) => this.simulationData = simulation.data"
        @simulation-chart-to-display="(data) => this.simulationChartToDisplay = data.chart"
        @delete-all-amounts="deleteAllAmounts"
      />

      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        :disabled="['asset', 'liability']"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />

    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import SpirectaReportMixin from '@/views/reports/_inc/SpirectaReportMixin'
import { mapState } from 'vuex'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import BalanceAccountReportSubmenu from './inc/BalanceAccountReportSubmenu'
import BalanceAccountReportPeriodTable from './tables/BalanceAccountReportPeriodTable'
import BalanceAccountReportClosingBalanceFullBarChart from './charts/BalanceAccountReportClosingBalanceFullBarChart'
import BalanceAccountReportClosingBalanceValueChangeOffsetBarChart from './charts/BalanceAccountReportClosingBalanceValueChangeOffsetBarChart'
import BalanceAccountReportTransactionsTable from './tables/BalanceAccountReportTransactionsTable'
import BalanceAccountReportChangeDistributionBarChart from './charts/BalanceAccountReportChangeDistributionBarChart'
import ReportFilter from '@/components/common/ReportFilter.vue'
import BalanceAccountReportDistributionTable from './tables/BalanceAccountReportDistributionTable'
import BalanceAccountReportTimeWeightedReturnChart from './charts/BalanceAccountReportTimeWeightedReturnChart'
import BalanceAccountReportTwrTable from './tables/BalanceAccountReportTwrTable'
import BalanceAccountReportStatsView from './views/BalanceAccountReportStatsView'
import BalanceAccountReportStatsHalfPieChart from './charts/BalanceAccountReportStatsHalfPieChart'
import BalanceAccountReportAboutView from './views/BalanceAccountReportAboutView'
import BalanceAccountReportBudgetSimulationAssetView from './views/BalanceAccountReportBudgetSimulationAssetView'
import BalanceAccountReportBudgetSimulationLiabilityView from './views/BalanceAccountReportBudgetSimulationLiabilityView'
import BalanceAccountReportClosingBalanceVsLifeplanChart from './charts/BalanceAccountReportClosingBalanceVsLifeplanChart'
import BudgetSimulationChart from '@/components/charts/BudgetSimulationChart'
import _ from 'lodash'

export default {
  name: 'BalanceAccountReport',
  mixins: [titleMixins, SpirectaReportMixin],
  components: {
    BalanceAccountReportStatsHalfPieChart,
    PeriodSelector,
    BalanceAccountReportSubmenu,
    BalanceAccountReportPeriodTable,
    BalanceAccountReportTransactionsTable,
    BalanceAccountReportClosingBalanceFullBarChart,
    BalanceAccountReportClosingBalanceValueChangeOffsetBarChart,
    BalanceAccountReportChangeDistributionBarChart,
    BalanceAccountReportDistributionTable,
    BalanceAccountReportTimeWeightedReturnChart,
    BalanceAccountReportClosingBalanceVsLifeplanChart,
    BalanceAccountReportTwrTable,
    BalanceAccountReportStatsView,
    BalanceAccountReportAboutView,
    BalanceAccountReportBudgetSimulationAssetView,
    BalanceAccountReportBudgetSimulationLiabilityView,
    ReportFilter,
    BudgetSimulationChart
  },
  data () {
    return {
      isPeriodSelectorShown: false,
      validRouteParams: ['summary', 'transactions', 'stats', 'distribution', 'twr', 'budget_simulation', 'about', 'filter'],
      activeTab: 'summary',
      apiData: null,
      simulationData: null,
      bHasAPIDataBeenFetchedOnce: false,
      translationPath: 'reports.balance.account.',
      busyState: true,
      startDate: null,
      endDate: null,
      resolution: 'month',
      chartToDisplay: 'closing-balance-full-bar-chart',
      statsChartToDisplay: 'common_transactions',
      simulationChartToDisplay: 'simulation-outgoing-balance',
      oAccount: {
        type: 'asset',
        label: '',
        group_label: ''
      },
      reportFilterData: null,
      bIsFilterApplied: false
    }
  },
  created () {
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      const aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/balance' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance' },
        { text: this.$t('assets_liabilities.submenu.' + this.oAccount.type + 's'), to: this.oAccount.type === 'liability' ? '/reports/other/account-plan-v2/liabilities' : '/reports/other/account-plan-v2/assets' },
        { text: this.oAccount.group_label, to: '/reports/performance/account-groups/' + this.oAccount.group_id },
        { text: '', active: true }
      ]
      return aBreadcrumb
    },
    titleMeta () {
      return this.oAccount.label + ' | ' + this.startDate + ' - ' + this.endDate
    }
  },
  methods: {
    /**
     * Handle emitted data from period_selector
     */
    onPeriodChange (data) {
      this.startDate = data.sDateStart
      this.endDate = data.sDateEnd
      this.resolution = data.resolution
      if (this.startDate !== this.$route.query.start_date || this.endDate !== this.$route.query.end_date) {
        this.$router.push({ query: this.buildRouteQueryFromFilterParams() })
      }
      this.loadData()
    },
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balance/accounts/${this.$route.params.account_id}/view?start_date=${this.startDate}&end_date=${this.endDate}&include_transactions=1&resolution=${this.resolution}`

        sApiEndpoint += this.buildFilterQueryStringForApiEndpoint(this.reportFilterData)

        axios.get(sApiEndpoint)
          .then((response) => {
            this.apiData = response.data.data
            this.oAccount = this.apiData.account

            this.busyState = false
            this.bHasAPIDataBeenFetchedOnce = true

            resolve(response.data.data)
          })
          .catch((e) => {
            console.log(e)
            reject(e)
          })
      })
    },
    buildRouteQueryFromFilterParams () {
      const oQuery = _.cloneDeep(this.$route.query)
      oQuery.start_date = this.startDate
      oQuery.end_date = this.endDate
      return oQuery
    },
    onAccountChanged (accountData) {
      if (accountData.account_id.toString() === this.$route.params.account_id) {
        return false
      }

      const route = {
        params: _.cloneDeep(this.$route.params),
        query: _.cloneDeep(this.$route.query)
      }
      route.params.account_id = accountData.account_id
      route.query.account_type = accountData.account_type

      this.$router.push(route)
      this.$router.go()
      console.log('GO')

      return true
    },
    deleteAllAmounts (data) {
      const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/delete-for-account/${data.budget_id}/${data.account_id}`
      axios.delete(apiEndpoint)
        .then(response => {
          if (this.$refs.BudgetSimulationAssetView) {
            this.$refs.BudgetSimulationAssetView.clearTable()
          }
          if (this.$refs.BudgetSimulationLiabilityView) {
            this.$refs.BudgetSimulationLiabilityView.clearTable()
          }

          this.$bvToast.toast(this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_success', { account_title: this.oAccount.title }).toString(), {
            title: this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_title').toString(),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_fail', { account_title: this.oAccount.title }).toString(), {
            title: this.$t('reports.budget.lifeplan.create_values_modal.delete_amounts_toast_title').toString(),
            variant: 'danger',
            solid: true
          })
        })
    }
  },
  mounted () {
    if (this.$route.query.start_date) {
      this.startDate = this.$route.query.start_date
    }
    if (this.$route.query.end_date) {
      this.endDate = this.$route.query.end_date
    }
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }
    this.isPeriodSelectorShown = true
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style>
.component-balance-account-report{
  width: 100%;
}
</style>
